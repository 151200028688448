export const environment = {
  production: true,
  environment: 'dev',
  version: require('../../package.json').version,
  serverBaseUrl: 'https://dev.api.squirrlysocial.com',
  tiktok: {
    authUrl: 'https://www.tiktok.com/v2/auth/authorize/',
    authRedirectUrl: `https://dev.api.squirrlysocial.com/webhook/oauth2/tiktok`,
    clientId: 'awe33r72bqsow3ov',
  },
  linkedIn: {
    authUrl: 'https://www.linkedin.com/oauth/v2/authorization',
    authRedirectUrl: 'https://dev.api.squirrlysocial.com/webhook/oauth2/linkedin',
    clientId: '779qiwuxi5ymsp',
  },
};
