<section class="sqs-page" [appLoading]="isLoading">
  <h1>Your social profiles</h1>

  <div class="content">
    <div class="header">
      <button
        class="menuTriggerBtn sqs-button black"
        [matMenuTriggerFor]="socialProfilesDropdown"
      >
        <i class="icon">add</i>
        <span>Add a Social Media profile</span>
      </button>

      <!-- Dropdown menu -->
      <mat-menu xPosition="before" #socialProfilesDropdown="matMenu">
        @for (entry of availableSocialProfiles; track entry.id) {
          <button mat-menu-item (click)="onAddProfile(entry.id)">
            <img
              [src]="'/assets/icons/' + entry.icon"
              [alt]="entry.name + ' Logo'"
              width="20"
              height="20"
            />
            <span class="text-small">{{ entry.label }}</span>
          </button>
        }
      </mat-menu>
    </div>

    <ul class="profile-list">
      <!-- Empty state -->
      @if (!socialProfiles.length) {
        <li class="no-data">
          <h3>Add your first social media profile</h3>
          <span>Click the button on the top right of this section.</span>
        </li>
      } @else {
        <!-- Table "header" -->
        <li class="list-header">
          <b>&nbsp;</b>
          <b>Profile Name</b>
          <b>Profile Type</b>
          <b>Added by</b>
          <b>Our access expires in</b>
          <b>&nbsp;</b>
        </li>

        <!-- Table "content" -->
        @for (profile of socialProfiles; track profile.id) {
          <li class="list-entry">
            <div class="profile-img">
              @if (profile.data.profilePictureUrl) {
                <a href="#" target="_blank">
                  <img
                    [src]="profile.data.profilePictureUrl"
                    [alt]="profile.data.username"
                  />
                </a>
              } @else {
                <a href="#" target="_blank">
                  <img
                    src="/assets/img/user-placeholder.png"
                    [alt]="profile.data.username"
                  />
                </a>
              }
            </div>

            <div class="profile-name">
              @switch (profile.type) {
                @case (Type.twitter) {
                  <a
                    href="https://x.com/{{ profile.data.username }}"
                    target="_blank"
                    >{{ profile.data.name }}</a
                  >
                  <label>{{ "@" + profile.data.username }}</label>
                }
                @case (Type.tiktok) {
                  <a
                    href="https://www.tiktok.com/@{{ profile.data.username }}"
                    target="_blank"
                    >{{ profile.data.name }}</a
                  >
                  <label>{{ "@" + profile.data.username }}</label>
                }
                @case (Type.linkedinPerson) {
                  <a
                    href="https://www.linkedin.com/in/{{
                      profile.data.username
                    }}"
                    target="_blank"
                    >{{ profile.data.name }}</a
                  >
                  <!--                  <label>{{ '@' + profile.data.username }}</label>-->
                }
                @case (Type.linkedinPage) {
                  <a
                    href="https://www.linkedin.com/company/{{
                      profile.externalId
                    }}"
                    target="_blank"
                    >{{ profile.data.name }}</a
                  >
                  <!--                  <label>{{ '@' + profile.data.username }}</label>-->
                }
                @case (Type.facebookPage) {
                  <a
                    href="https://facebook.com/{{ profile.externalId }}"
                    target="_blank"
                    >{{ profile.data.name }}</a
                  >
                  @if (profile.data.username) {
                    <label>{{ "@" + profile.data.name }}</label>
                  }
                }
                @case (Type.instagramBusinessPage) {
                  <a
                    href="https://instagram.com/{{ profile.data.username }}"
                    target="_blank"
                    >{{ profile.data.name }}</a
                  >
                  <label>{{ "@" + profile.data.username }}</label>
                }
                @case (Type.youtube) {
                  <a
                    href="https://www.youtube.com/{{ profile.data.username }}"
                    target="_blank"
                    >{{ profile.data.name }}</a
                  >
                  <label>{{ profile.data.username }}</label>
                }

                @default {
                  &nbsp;
                }
              }
            </div>
            <div [innerHTML]="profileTypeHtml(profile.type)"></div>
            <div>{{ profile.nameOfUserWhoAdded }}</div>
            <div>
              {{
                !profile.expires
                  ? "N/A"
                  : (profile.expires | date: "yyyy-MM-dd HH:mm")
              }}
            </div>
            <div class="actions">
              <button
                class="sqs-button transparent"
                (click)="onAddProfile(profile.type)"
              >
                Reconnect
              </button>
              <button
                class="sqs-button danger"
                matTooltip="Delete"
                matTooltipPosition="right"
                (click)="onDeleteProfile(profile)"
              >
                <i class="icon outlined">delete</i>
              </button>
            </div>
          </li>
        }
      }
    </ul>
  </div>
</section>
