import { BaseModel } from './base-model';
import { User } from './user';

// Where will the files be uploaded
export enum UploadScope {
  // Public
  profilePicture = 'profilePicture',

  // Private
  media = 'My Media',
}

// Type of uploaded file
export enum UploadType {
  image = 'image',
  video = 'video',
  csv = 'csv',
}

export type UploadResult = {
  url: string;
  key: string;
  upload: Upload;
};

export interface Upload extends BaseModel {
  // Key used to identify the file inside the Bucket
  key?: string;

  // Constant for public uploads, tmp for private (generated/signed)
  url?: string;

  // Original name of uploaded file
  filename: string;
  mimetype: string;
  sizeBytes: number;
  widthPx: number;
  heightPx: number;

  // Post-related text fields
  postSuggestedText?: string;
  notes?: string;

  // Owner of uploaded file (upload is done per brand, so the brand will own this file)
  owner: string | User;

  scope: UploadScope;
  type: UploadType;
}

export const ALLOWED_FILE_MIMETYPES = [
  // Images
  { mimetype: 'image/png', label: 'PNG', pictureUrl: '/assets/icons/pictures.png' },
  { mimetype: 'image/jpg', label: 'JPG', pictureUrl: '/assets/icons/pictures.png' },
  { mimetype: 'image/jpeg', label: 'JPEG', pictureUrl: '/assets/icons/pictures.png' },
  { mimetype: 'image/bmp', label: 'BMP', pictureUrl: '/assets/icons/pictures.png' },

  // Videos
  { mimetype: 'video/mpeg', label: 'MPEG', pictureUrl: '/assets/icons/video.png' },
  { mimetype: 'video/vnd.vivo', label: 'VIV', pictureUrl: '/assets/icons/video.png' },
  { mimetype: 'video/quicktime', label: 'QT', pictureUrl: '/assets/icons/video.png' },
  { mimetype: 'video/x-msvideo', label: 'AVI', pictureUrl: '/assets/icons/video.png' },
  { mimetype: 'video/mp4', label: 'MP4', pictureUrl: '/assets/icons/video.png' },

  // PDFs
  { mimetype: 'application/pdf', label: 'PDF', pictureUrl: '/assets/icons/pdf.svg' },
];
