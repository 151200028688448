import { Component, Inject, OnInit } from '@angular/core';
import { LoadingDirective } from '../../../shared/directives/loading.directive';
import { MatTooltip } from '@angular/material/tooltip';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../../../shared/models/misc';
import { ToastService } from '../../../shared/services/toast.service';
import {
  PostGroup,
  PostRepetition,
  PostStatus,
  ScheduleTimeEntry,
  ScheduleWeekDay,
} from '../../../shared/models/post-group';
import { PostGroupService } from '../../../shared/services/post-group.service';
import { AuthService } from '../../../shared/services/auth.service';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { MatOption, MatSelect } from '@angular/material/select';
import { ConfirmationService } from '../../../shared/services/confirmation.service';
import {
  PostGroupSocialProfile,
  SocialProfile,
  SocialProfileType,
} from '../../../shared/models/social-profile';
import { SocialProfileService } from '../../../shared/services/social-profile.service';
import { SocialProfileItemComponent } from './components';
import { BrandService } from '../../../shared/services/brand.service';
import { TikTokPolicyComponent } from "../../../shared/components/tiktok-policy/tiktok-policy.component";

@Component({
  selector: 'app-post-group-form-dialog',
  standalone: true,
  imports: [
    LoadingDirective,
    MatTooltip,
    FormsModule,
    NgSelectModule,
    MatRadioGroup,
    MatRadioButton,
    MatSelect,
    MatOption,
    SocialProfileItemComponent,
    TikTokPolicyComponent
],
  templateUrl: './post-group-form-dialog.component.html',
  styleUrl: './post-group-form-dialog.component.scss',
})
export class PostGroupFormDialogComponent implements OnInit {
  isLoading = false;

  action: 'add' | 'edit';

  groupId: null | string = null;
  _originalName: string = '';
  group: Omit<PostGroup, 'id' | 'createdAt' | 'updatedAt'> = {
    name: '',
    brand: '',
    repetition: PostRepetition.once,
    defaultStatus: PostStatus.draft,
    postCount: 0,
    scheduleDays: [],
    scheduleTimes: [],
    approvedPostOrder: [],
    selectedSocialProfileIds: [],
  };

  readonly Options = {
    repetition: [{ value: PostRepetition.once, label: 'Send once' }],
    status: [
      { value: PostStatus.draft, label: 'Draft' },
      { value: PostStatus.approved, label: 'Approved' },
    ],
    socialProfiles: <SocialProfile[]>[],
  };
  readonly Type = SocialProfileType;

  selectedSocialProfileIds: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<PostGroupFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData.PostGroupForm,
    protected toast: ToastService,
    protected postGroupService: PostGroupService,
    protected auth: AuthService,
    protected confirmation: ConfirmationService,
    protected socialProfileService: SocialProfileService,
    protected brandService: BrandService
  ) {
    this.action = data.action;
    if (this.data.action === 'edit' && !!data.data) {
      this.groupId = data.data.id;
      this._originalName = this.group.name = data.data.name;
      this.group.repetition = data.data.repetition;
      this.group.defaultStatus = data.data.defaultStatus;
      this.group.postCount = data.data.postCount;
      this.group.scheduleDays = data.data.scheduleDays;
      this.group.scheduleTimes = data.data.scheduleTimes;

      this.selectedSocialProfileIds = data.data.selectedSocialProfileIds || [];
    }
  }

  public async ngOnInit() {
    await this.loadSocialProfiles();
  }

  public checkIfTikTokProfileIsSelected() {
    return this.Options.socialProfiles.some((profile) => profile.type === SocialProfileType.tiktok && this.selectedSocialProfileIds.includes(profile.id));
  }

  public get title() {
    return this.data.action === 'add' ? 'Add new group' : 'Edit post group';
  }

  public async onSubmit() {
    if (this.isLoading) return;

    try {
      this.isLoading = true;

      const { action, data: payload } = this.validateData();

      const postGroup =
        action === 'add'
          ? await this.postGroupService.addOne(
            this.auth.lastSelectedBrandId(),
            <_AddPayload>payload
          )
          : await this.postGroupService.updateOne(
            this.auth.lastSelectedBrandId(),
            this.groupId!,
            <_UpdatePayload>payload
          );

      const msg = {
        add: 'Successfully created post group.',
        edit: 'Successfully edited post group.',
      }[action];

      this.toast.success(msg);

      return this.dialogRef.close(postGroup);
    } catch (err) {
      console.error(err);
      this.toast.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  public onDelete() {
    if (this.isLoading) return;
    if (this.action === 'add') return;

    this.confirmation.confirm({
      title: 'Delete Post Group',
      message: `Are you sure you want to permanently delete post group <b>"${this._originalName}"</b>. By deleting the group, you will also delete all associated posts. This action cannot be undone. Are you sure ?`,
      isRawMessage: true,
      noBtnText: 'Cancel',
      yesBtnText: 'Yes, Delete',
      onSuccess: async () => {
        try {
          this.isLoading = true;

          await this.postGroupService.deleteOne(
            this.auth.lastSelectedBrandId(),
            this.groupId!
          );
          this.toast.success(`Post group successfully deleted.`);

          return this.dialogRef.close();
        } catch (err) {
          console.error(err);
          this.toast.error(err);
        } finally {
          this.isLoading = false;
        }
      },
    });
  }

  private validateData(): ValidateDataRet {
    [
      {
        condition: !!this.group.name.length,
        err: 'Group name cannot be empty.',
      },
      {
        condition: !!this.group.repetition.toString().length,
        err: 'Post repetition cannot be empty.',
      },
      {
        condition: Object.values(PostRepetition).includes(
          this.group.repetition
        ),
        err: 'Invalid post repetition.',
      },
      {
        condition: !!this.group.defaultStatus.toString().length,
        err: 'Default status cannot be empty.',
      },
      {
        condition: Object.values(PostStatus).includes(this.group.defaultStatus),
        err: 'Invalid status.',
      },
    ].forEach(({ condition, err }) => {
      if (!condition) throw new Error(err);
    });

    const basePayload: _AddPayload = {
      name: this.group.name,
      repetition: this.group.repetition,
      defaultStatus: this.group.defaultStatus,
      selectedSocialProfileIds: this.selectedSocialProfileIds,
    };

    if (this.action === 'add') {
      return { action: 'add', data: basePayload };
    }

    return {
      action: 'edit',
      data: {
        ...basePayload,
        scheduleDays: this.group.scheduleDays,
        scheduleTimes: this.group.scheduleTimes,
      },
    };
  }

  private async loadSocialProfiles() {
    try {
      this.isLoading = true;

      this.Options.socialProfiles = await this.socialProfileService.findAll(
        this.auth.lastSelectedBrandId()
      );

      // Only now set assigned social profiles, if any, so they can be displayed correctly
      if (this.data.action === 'edit' && !!this.data.data) {
        this.selectedSocialProfileIds =
          this.data.data.selectedSocialProfileIds || [];
      }
    } catch (err) {
      console.error(err);
      this.toast.error(err);
    } finally {
      this.isLoading = false;
    }
  }
}

type _AddPayload = {
  name: string;
  repetition: PostRepetition;
  defaultStatus: PostStatus;
  selectedSocialProfileIds: string[];
};
type _UpdatePayload = {
  name: string;
  repetition: PostRepetition;
  defaultStatus: PostStatus;
  scheduleDays: ScheduleWeekDay[];
  scheduleTimes: ScheduleTimeEntry[];
};
type ValidateDataRet =
  | { action: 'add'; data: _AddPayload }
  | { action: 'edit'; data: _UpdatePayload };
