import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { injectTrpcClient } from './trpc/client';
import { BrandInvitationDTO, BrandTeamMemberDTO } from '@squirrly-social/api-models';

@Injectable({
    providedIn: 'root'
})
export class BrandInvitationService {
    constructor(
        protected http: HttpClient,
    ) { }

    protected trpcClient = injectTrpcClient();

    public async inviteNewBrandMember(brandId: string, email: string): Promise<BrandInvitationDTO> {
        return this.trpcClient.brandInvitation.inviteNewMember.mutate({ brandId, email });
    }

    public async getAllUserBrandInvitations(userEmail: string): Promise<BrandInvitationDTO[]> {
        return this.trpcClient.brandInvitation.findAllOfUser.query({ email: userEmail });
    }

    public async getAllInvitationsForBrand(brandId: string): Promise<BrandInvitationDTO[]> {
        return this.trpcClient.brandInvitation.findAllOfBrand.query({ brandId });
    }

    public async rejectInvitation(brandId: string, invitationId: string): Promise<BrandInvitationDTO> {
        return this.trpcClient.brandInvitation.rejectInvitation.mutate({ brandId, invitationId });
    }

    public async acceptInvitation(brandId: string, invitationId: string): Promise<BrandTeamMemberDTO> {
        return this.trpcClient.brandInvitation.acceptInvitation.mutate({ brandId, invitationId });
    }

    public async deleteInvitation(brandId: string, invitationId: string): Promise<any> {
        return this.trpcClient.brandInvitation.deleteInvitation.mutate({ brandId, invitationId });
    }
}