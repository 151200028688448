<section class="post-card" [appLoading]="isLoading">
  <div class="media-container">
    @if (post.uploadFile !== null) {
      @if (post.uploadFile.type === UploadType.image) {
        <img [src]="post.uploadUrl" [alt]="post.uploadFile.filename" />
      }
      @if (post.uploadFile.type === UploadType.video) {
        <img
          class="vid-icon"
          src="/assets/icons/video.png"
          alt="Video Logo overlay"
        />
        <video preload="metadata">
          <source [src]="post.uploadUrl + '#t=0.1'" type="video/mp4" />
        </video>
      }
    }
  </div>

  <div class="data">
    @if (position) {
      <div class="row">
        <span>Position</span>
        <span>{{ position }}</span>
      </div>
    }
    @if (name) {
      <div class="row">
        <i class="icon">person</i>
        <span>{{ name }}</span>
      </div>
    }
    <div class="row">
      <i class="icon">repeat</i>
      <span>{{ repeat }}</span>
    </div>
    <div class="row" [class.hidden]="!url?.link?.length">
      <i class="icon">open_in_new</i>
      <a [href]="url?.link">{{ url?.label }}</a>
    </div>

    <div class="preview">{{ post.textContent }}</div>
  </div>

  <div class="actions">
    @if (post.status === PostStatus.draft) {
      <div
        class="btn"
        matTooltip="Approve & Schedule"
        matTooltipPosition="above"
        (click)="onMovePostToApprove()"
      >
        <i class="icon outlined">check</i>
      </div>
    }
    @if (post.status === PostStatus.approved) {
      <div
        class="btn"
        matTooltip="Unschedule"
        matTooltipPosition="above"
        (click)="onMovePostToDraft()"
      >
        <i class="icon outlined">cancel</i>
      </div>
    }

    @if (
      post.status === PostStatus.draft || post.status === PostStatus.approved
    ) {
      <div
        class="btn"
        matTooltip="Edit"
        matTooltipPosition="above"
        (click)="onEdit()"
      >
        <i class="icon outlined">edit_note</i>
      </div>
    }

    @if (post.status === PostStatus.finishedSending) {
      <div
        class="mr-2 flex h-[34px] w-[34px] cursor-pointer items-center justify-center rounded-full border border-[#c7cdd1] shadow-sm transition-all hover:bg-[#f3f5f8]"
        matTooltip="Copy"
        matTooltipPosition="above"
        (click)="onCopy()"
      >
        <i class="icon outlined">content_copy</i>
      </div>
      <div
        class="mr-auto flex h-[34px] w-[34px] cursor-pointer items-center justify-center rounded-full border border-[#c7cdd1] shadow-sm transition-all hover:bg-[#f3f5f8]"
        matTooltip="Reschedule using AI"
        matTooltipPosition="above"
        (click)="onRescheduleUsingAI()"
      >
        <i class="icon outlined">smart_toy</i>
      </div>
    } @else {
      <div
        class="btn"
        matTooltip="Copy"
        matTooltipPosition="above"
        (click)="onCopy()"
      >
        <i class="icon outlined">content_copy</i>
      </div>
    }

    @if (
      post.status === PostStatus.draft || post.status === PostStatus.approved
    ) {
      <div
        class="btn"
        matTooltip="Delete"
        matTooltipPosition="above"
        (click)="onDelete()"
      >
        <i class="icon outlined">delete</i>
      </div>
    }
  </div>
</section>
