import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Post, PostSendingStatus } from '../../../shared/models/post';
import { LoadingDirective } from '../../../shared/directives/loading.directive';
import { DatePipe } from '@angular/common';
import { SocialProfileType } from '../../../shared/models/social-profile';
import { MatTooltip } from '@angular/material/tooltip';
import { UploadType } from '../../../shared/models/upload';
import { PostStatus } from '../../../shared/models/post-group';
import { ToastService } from '../../../shared/services/toast.service';
import { ConfirmationService } from '../../../shared/services/confirmation.service';
import { PostService } from '../../../shared/services/post.service';


@Component({
    selector: 'app-post-card',
    standalone: true,
    imports: [LoadingDirective, DatePipe, MatTooltip],
    templateUrl: './post-card.component.html',
    styleUrl: './post-card.component.scss',
})
export class PostCardComponent {

    @Input({ required: true })
    post: Post;

    @Output() postDeleted = new EventEmitter<void>();
    @Output() postEdited = new EventEmitter<void>();
    @Output() postCopied = new EventEmitter<void>();

    constructor(
        private toast: ToastService,
        private confirm: ConfirmationService,
        private postService: PostService,
    ) { }

    showAdditionalInfo: boolean = false;
    isLoading: boolean = false;
    readonly UploadType = UploadType;
    readonly PostStatus = PostStatus;
    readonly PostSendingStatus = PostSendingStatus;


    public onEdit() {
        if (this.isLoading) return;
        this.postEdited.emit();
    }

    public onCopy() {
        if (this.isLoading) return;
        this.postCopied.emit();
    }

    public onDelete() {
        if (this.isLoading) return;
        this.isLoading = true;
        this.confirm.confirm({
            title: 'Delete Post',
            message:
                'You are about to delete this post. This action cannot be undone. Are you sure?',
            noBtnText: 'Cancel',
            yesBtnText: 'Yes, Delete',
            onSuccess: async () => {
                try {
                    await this.postService.deleteOne(this.post.id);
                    this.postDeleted.emit();
                    this.toast.success('Post deleted successfully');
                } catch (e) {
                    this.toast.error('Failed to delete post');
                } finally {
                    this.isLoading = false;
                }
            },
            onDismiss: () => {
                this.isLoading = false;
            }
        });
    }

    public icon(type: SocialProfileType) {
        switch (type) {
            case SocialProfileType.facebookPage:
                return 'facebook.svg';
            case SocialProfileType.facebookGroup:
                return 'facebook.svg';
            case SocialProfileType.googleMyBusiness:
                return 'google.svg';
            case SocialProfileType.instagramBusinessPage:
                return 'instagram.svg';
            case SocialProfileType.linkedinPage:
                return 'linkedin.svg';
            case SocialProfileType.linkedinPerson:
                return 'linkedin.svg';
            case SocialProfileType.tiktok:
                return 'tiktok.svg';
            case SocialProfileType.twitter:
                return 'twitter.svg';
            case SocialProfileType.youtube:
                return 'youtube.svg';
            default:
                return 'UNDEF';
        }
    }
}