<section class="hint-form" [appLoading]="isLoading">
  <div class="header">
    <span>Create new content hint</span>
  </div>

  <div class="form">
    <div class="row">
      <label>Content Hint</label>
      <input type="text" [(ngModel)]="text">
    </div>

    <div class="row">
      <label>Start Date</label>
      <input type="date"
             min="2024-04-01"
             max="2100-01-01"
             pattern="Month dd, yyyy"
             [value]="startDate" [(ngModel)]="startDate">
    </div>

    <div class="row">
      <label>End Date</label>
      <input type="date"
             min="2024-04-01"
             max="2100-01-01"
             pattern="Month dd, yyyy"
             [value]="endDate" [(ngModel)]="endDate">
    </div>

    <div class="row color-row">
      <label>Color</label>

      <input type="date" class="invisible">

      <div class="color-picker-container">
        <input class="color-picker"
               [(colorPicker)]="color"
               [style.background]="color"
               [cpOutputFormat]="'hex'"
               [cpPosition]="'right'"
               [cpPositionOffset]="'32px'"
        >
      </div>
    </div>
  </div>

  <div class="actions">
    @if (pageAction === 'edit') {
      <button class="sqs-button danger" matTooltip="Delete hint" (click)="onDeleteHint()">
        <i class="icon outlined">delete</i>
      </button>
    }
    <button class="sqs-button transparent" (click)="dialogRef.close(null)">Close</button>
    <button class="sqs-button black" (click)="onSubmit()">{{ pageAction === 'add' ? 'Create' : 'Update' }}</button>
  </div>
</section>
