<div
  class="flex flex-col gap-8 rounded-lg bg-white p-8"
  [appLoading]="isLoading"
>
  <div class="flex items-center justify-between">
    <h2 class="text-xl">ChatGPT Settings</h2>
  </div>
  <div class="flex flex-col gap-6">
    <span class="text-lg"
      >Application is
      <span
        class="text-bold rounded-xl p-2 text-base"
        [ngClass]="{
          'bg-green-300': chatGPTConnectionStatus() == ChatGPTStatus.CONNECTED,
          'bg-red-200': chatGPTConnectionStatus() == ChatGPTStatus.DISCONNECTED,
          'bg-gray-200': chatGPTConnectionStatus() === ChatGPTStatus.DONTEXIST,
        }"
        >{{ getStatusText(chatGPTConnectionStatus()) }}</span
      ></span
    >
    <form
      [formGroup]="form"
      (ngSubmit)="onSubmit()"
      class="grid grid-cols-3 items-center gap-4"
    >
      <div class="col-span-2 flex items-center gap-2">
        <label class="text-lg font-bold text-gray-700">ChatGPT Key</label>
        <input
          type="text"
          formControlName="connectionKey"
          class="flex-1 rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          required
        />
      </div>
      <div class="flex">
        <button
          type="submit"
          [disabled]="form.invalid"
          class="rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
        >
          Connect
        </button>
      </div>
    </form>
    <div class="col-span-2 flex items-center gap-2">
      <label class="text-lg font-bold text-gray-700">ChatGPT Model</label>
      <ng-select
        [items]="chatGPTModels"
        [searchable]="false"
        [clearable]="false"
        [multiple]="false"
        [(ngModel)]="selectedChatGPTModel"
        class="min-w-[150px]"
      >
      </ng-select>
    </div>
  </div>
</div>
