import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appDragDrop]',
  standalone: true
})
export class DragDropDirective {

  @Output()
  fileDropped = new EventEmitter<FileList>();

  @HostBinding('style.background-color')
  // background = '#e2e2e2';
  background = 'transparent';

  @HostBinding('style.transition')
  transition = 'background 200ms ease';

  @HostBinding('style.opacity')
  opacity = '1';

  @HostBinding('style.border-radius')
  borderRadius = '12px';

  @HostListener('dragover', ['$event'])
  public onDragOver(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    this.background = 'white';
    this.opacity = '0.8';
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    this.background = '#e2e2e2';
    this.opacity = '1';
  }

  @HostListener('drop', ['$event'])
  public onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.background = '#e2e2e2';
    this.opacity = '1';

    const files = event.dataTransfer?.files;
    if (files?.length) {
      this.fileDropped.emit(files);
    }
  }
}
