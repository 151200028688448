<section class="login-page">
  <!-- Left side -- Form -->
  <div class="left" [appLoading]="isLoading">
    <a class="logo" href="https://social.squirrly.co">
      <img src="/assets/img/logo-login.png" alt="Squirrly Social Logo" />
    </a>

    <h1>Reset Password</h1>

    <div class="form">
      <input type="email" autocomplete="email" placeholder="E-mail" />

      <button class="btn-primary">Send Password Reset Link</button>
    </div>
  </div>

  <!-- Right side -- Banner -->
  <div class="right">
    <div class="links">
      <a [href]="serviceTermsURL" target="_blank">Terms of Service</a>
      <a [href]="privacyPolicyURL" target="_blank">Privacy Policy</a>
    </div>
  </div>
</section>
