<section class="app-container">
  <nav class="topnav" [class.hidden]="!isUserAuthenticated">
    <div class="logo">
      <img src="/assets/img/sq-social-logo.png" alt="Squirrly Social Logo" />
    </div>

    <div class="user-brand" (click)="onToggleMenu('brand')">
      <span>{{ this.auth.currentBrand()?.name || "" }}</span>

      <button
        class="menuTriggerBtn"
        [matMenuTriggerFor]="brandMenu"
        (click)="$event.stopPropagation()"
      >
        <i class="icon">expand_more</i>
      </button>

      <!-- Dropdown menu -->
      <mat-menu xPosition="before" #brandMenu="matMenu">
        @for (brand of this.brandService.brandsOfCurrentUser; track brand.id) {
          <button
            class="topNavMenuOptionBtn brand"
            mat-menu-item
            (click)="onChangeBrand(brand.id)"
          >
            @if (brand.id === this.auth.lastSelectedBrandId()) {
              <i class="icon" style="color: #409e5d">check</i>
            }
            <span class="text" style="font-weight: 600">{{ brand.name }}</span>
          </button>
        }

        <button
          class="topNavMenuOptionBtn brand"
          mat-menu-item
          (click)="handleAddNewBrand()"
        >
          <i class="icon outlined">add_circle_outline</i>
          <span class="text">Create brand</span>
        </button>
      </mat-menu>
    </div>

    @if (showCreatePostButton()) {
      <div>
        <button class="btn-primary" mat-flat-button (click)="onNewPost()">
          <div>
            <i class="icon">add</i>
            <span>Create a new post</span>
          </div>
        </button>
      </div>
    }

    <div class="notification">
      <i class="icon">notifications</i>
      <span>0</span>
    </div>

    <div class="user-avatar" (click)="onToggleMenu('user')">
      <img
        src="/assets/img/user-placeholder.png"
        alt="User Placeholder image"
      />
      <span>{{ auth.user?.name || "" }}</span>

      <button
        class="menuTriggerBtn"
        [matMenuTriggerFor]="userOptionsMenu"
        (click)="$event.stopPropagation()"
      >
        <i class="icon">expand_more</i>
      </button>

      <!-- Dropdown menu -->
      <mat-menu #userOptionsMenu="matMenu">
        <button
          class="topNavMenuOptionBtn"
          mat-menu-item
          (click)="onAccountSettings()"
        >
          <i class="icon">settings</i>
          <span class="text">Account Settings</span>
        </button>

        <button class="topNavMenuOptionBtn" mat-menu-item (click)="onLogout()">
          <i class="icon" style="transform: rotate(180deg)">logout</i>
          <span class="text">Logout</span>
        </button>
      </mat-menu>
    </div>
  </nav>

  <div class="app-content" [class.full]="!isUserAuthenticated">
    <app-sidenav [class.hidden]="!isUserAuthenticated"></app-sidenav>

    <div class="content" [class.full]="!isUserAuthenticated">
      <router-outlet />
    </div>
  </div>
</section>
