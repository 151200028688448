<div class="card" [appLoading]="isLoading">
  <h2 class="text-xl">Contact Information</h2>
  <div class="form">
    <div class="form-group">
      <label>Name</label>
      <input type="text" [(ngModel)]="user.name" placeholder="Your name">
    </div>

    <div class="form-group">
      <label>Email Address</label>
      <input type="text" [(ngModel)]="user.email" placeholder="Your email address">
    </div>

    <div class="form-group">
      <label>&nbsp;</label>
      <div>
        <button class="sqs-button dark" (click)="onUpdateContactInformation()">Update</button>
      </div>
    </div>
  </div>
</div>