import { Component, effect, OnInit } from '@angular/core';
import { LoadingDirective } from '../../shared/directives/loading.directive';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { AuthService } from '../../shared/services/auth.service';
import { ToastService } from '../../shared/services/toast.service';
import { ConfirmationService } from '../../shared/services/confirmation.service';
import { environment } from '../../../environments/environment';
import { GeneralUtils } from '../../shared/utils/general.utils';
import {
  SocialProfile,
  SocialProfileType,
} from '../../shared/models/social-profile';
import { ActivatedRoute, Router } from '@angular/router';
import { SocialProfileEntry, SocialProfileService } from '../../shared/services/social-profile.service';
import { MatTooltip } from '@angular/material/tooltip';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, take } from 'rxjs';
import { BrandService } from '../../shared/services/brand.service';
import { MatDialog } from '@angular/material/dialog';
import { SocialProfilesFilterLinkedinPagesDialogComponent } from './social-profiles-filter-linkedin-pages-dialog/social-profiles-filter-linkedin-pages-dialog.component';
import { SocialProfileDialogElementDTO } from '@squirrly-social/api-models';

// Object that might be encoded in url as "state" query param, which contains
// whether the page should display a success/warn/error toast upon loading
// (after being redirected from a Social Profile connection)
type InitState = {
  success?: string;
  error?: string;
};

@Component({
  selector: 'app-social-profiles',
  standalone: true,
  imports: [
    LoadingDirective,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
    MatTooltip,
    DatePipe,
  ],
  templateUrl: './social-profiles.component.html',
  styleUrl: './social-profiles.component.scss',
})
export class SocialProfilesComponent implements OnInit {
  readonly Type = SocialProfileType;
  isLoading = false;
  PROFILES = this.socialProfileService.PROFILES;
  PROFILE_MAP: Record<SocialProfileType, SocialProfileEntry> = this.socialProfileService.PROFILE_MAP;

  availableSocialProfiles: SocialProfileEntry[] = [
    this.PROFILE_MAP[SocialProfileType.twitter],
    this.PROFILE_MAP[SocialProfileType.tiktok],
    this.PROFILE_MAP[SocialProfileType.linkedinPerson],
    this.PROFILE_MAP[SocialProfileType.linkedinPage],
    this.PROFILE_MAP[SocialProfileType.facebookPage],
    this.PROFILE_MAP[SocialProfileType.instagramBusinessPage],
    // PROFILE_MAP[SocialProfileType.googleMyBusiness]
    this.PROFILE_MAP[SocialProfileType.youtube],
  ];

  socialProfiles: SocialProfile[] = [];
  includeNameOfUserWhoAdded = true;
  showLinkedinPageDialog = false;

  constructor(
    protected auth: AuthService,
    protected toast: ToastService,
    protected confirm: ConfirmationService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected socialProfileService: SocialProfileService,
    protected http: HttpClient,
    protected brandService: BrandService,
    protected dialog: MatDialog,
  ) {
    effect(async () => {
      if (this.auth.lastSelectedBrandId()) {
        // Check if linkedin page is added
        await this.checkIfLinkedinPageIsAdded();
        // Load data
        await this.loadData();
      }
    });
  }

  public async ngOnInit() {
    // Extract the init state message (after a redirect) and display if any was provided
    await this.displayInitStateMessage();
    // Clear query params
    await this.clearQueryParams();
  }

  public onAddProfile(type: SocialProfileType) {
    if (this.isLoading) return;

    switch (type) {
      case SocialProfileType.twitter:
        return this.twitterHandling();
      case SocialProfileType.tiktok:
        return this.tiktokHandling();
      case SocialProfileType.linkedinPerson:
        return this.linkedInPersonHandling();
      case SocialProfileType.linkedinPage:
        return this.linkedInPageHandling();
      case SocialProfileType.googleMyBusiness:
        return this.googleMyBusinessHandling();
      case SocialProfileType.youtube:
        return this.youtubeHandling();
      case SocialProfileType.facebookPage:
      case SocialProfileType.instagramBusinessPage:
        return this.facebookHandling();
      default:
        return this.toast.warning(`"${type}" not yet handled.`);
    }
  }

  public profileTypeHtml(type: SocialProfileType) {
    const profile = this.PROFILES.find((entry) => entry.id === type);
    if (!profile) return '';
    return `<img src="/assets/icons/${profile!.icon}" alt="${profile!.name
      } Logo" width="20" height="20">
<span class="text-small">${profile!.label}</span>`;
  }

  public onDeleteProfile(profile: SocialProfile) {
    if (this.isLoading) return;

    this.confirm.confirm({
      title: 'Delete Social Profile',
      message:
        'This cannot be undone and all posts scheduled to go to this account will be removed.',
      noBtnText: 'Cancel',
      yesBtnText: 'Yes, Delete',
      onSuccess: async () => {
        try {
          this.isLoading = true;

          const socialProfile = await this.socialProfileService.deleteOne(
            this.auth.lastSelectedBrandId()!,
            profile.id
          );

          this.toast.success('Profile successfully deleted.');

          const idx = this.socialProfiles.findIndex(
            (p) => p.id === socialProfile.id
          );
          if (idx !== -1) this.socialProfiles.splice(idx, 1);
        } catch (err) {
          console.error(err);
          this.toast.error(err);
        } finally {
          this.isLoading = false;
        }
      },
    });
  }

  private async twitterHandling() {
    try {
      this.isLoading = true;
      const endpoint = `${environment.serverBaseUrl
        }/webhook/oauth2/twitter/redirect?brandId=${this.auth.lastSelectedBrandId()}`;
      const response = await lastValueFrom(
        this.http.get<{ url: string }>(endpoint)
      );
      // Redirect to Twitter OAuth2 page
      window.location.href = response.url;
    } catch (err) {
      this.toast.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  private async tiktokHandling() {
    try {
      this.isLoading = true;

      const {
        authRedirectUrl,
        clientId: client_key,
        authUrl,
      } = environment.tiktok;

      const state = GeneralUtils.encodeContent(
        [
          'squirrly-social-secret-key',
          this.auth.user!._id,
          this.auth.lastSelectedBrandId()!,
          +new Date(),
        ].join('_')
      );

      const qs = new URLSearchParams({
        client_key,
        scope:
          'user.info.basic,user.info.profile,user.info.stats,video.list,video.upload,video.publish',
        redirect_uri: authRedirectUrl,
        state,
        response_type: 'code',
      }).toString();

      // Redirect to TikTok OAuth2 page
      window.location.href = `${authUrl}?${qs}`;
    } catch (err) {
      console.error(err);
      this.toast.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  private async facebookHandling() {
    try {
      this.isLoading = true;
      // Get redirect URL
      const loginUrl = `${environment.serverBaseUrl
        }/webhook/oauth2/facebook-page/redirect?brandId=${this.auth.lastSelectedBrandId()}`;
      const response = await lastValueFrom(
        this.http.get<{ url: string }>(loginUrl)
      );
      // Redirect to Facebook OAuth2 page
      window.location.href = response.url;
    } catch (err) {
      console.error(err);
      this.toast.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  private async googleMyBusinessHandling() {
    try {
      this.isLoading = true;
      // Get redirect URL
      const loginUrl = `${environment.serverBaseUrl
        }/webhook/oauth2/googlemybusiness/redirect?brandId=${this.auth.lastSelectedBrandId()}`;
      const response = await lastValueFrom(
        this.http.get<{ url: string }>(loginUrl)
      );
      // Redirect to Google OAuth2 page
      window.location.href = response.url;
    } catch (err) {
      console.error(err);
      this.toast.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  private async youtubeHandling() {
    try {
      this.isLoading = true;
      // Get redirect URL
      const loginUrl = `${environment.serverBaseUrl
        }/webhook/oauth2/youtube/redirect?brandId=${this.auth.lastSelectedBrandId()}`;
      const response = await lastValueFrom(
        this.http.get<{ url: string }>(loginUrl)
      );
      // Redirect to Google OAuth2 page
      window.location.href = response.url;
    } catch (err) {
      console.error(err);
      this.toast.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  private async linkedInPersonHandling() {
    try {
      this.isLoading = true;

      const {
        authUrl,
        clientId: client_id,
        authRedirectUrl: redirect_uri,
      } = environment.linkedIn;

      const state = GeneralUtils.encodeContent(
        [
          'squirrly-social-secret-key',
          this.auth.user!._id,
          this.auth.lastSelectedBrandId()!,
          +new Date(),
        ].join('_')
      );

      const qs = new URLSearchParams({
        response_type: 'code',
        client_id,
        redirect_uri,
        state,
        scope: ['profile', 'email', 'openid', 'w_member_social'].join(' '),
      }).toString();

      // Redirect to TikTok OAuth2 page
      window.location.href = `${authUrl}?${qs}`;
    } catch (err) {
      console.error(err);
      this.toast.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  private async linkedInPageHandling() {
    try {
      this.isLoading = true;
      // Get redirect URL
      const loginUrl = `${environment.serverBaseUrl
        }/webhook/oauth2/linkedin-page/redirect?brandId=${this.auth.lastSelectedBrandId()}`;
      const response = await lastValueFrom(
        this.http.get<{ url: string }>(loginUrl)
      );
      // Redirect
      window.location.href = response.url;
    } catch (err) {
      console.error(err);
      this.toast.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  private async displayInitStateMessage() {
    let encodedState = this.route.snapshot.queryParamMap.get('state');
    if (!encodedState) return;

    const state = GeneralUtils.parseJson<InitState>(
      GeneralUtils.decodeContent(encodedState)
    );
    console.log(state);
    if (!state) return;

    // If any "init state" was provided, create appropriate toast
    if (state.success) this.toast.success(state.success);
    if (state.error) this.toast.error(state.error);
  }

  private async checkIfLinkedinPageIsAdded() {
    const linkedinPageShowDialog = this.route.snapshot.queryParamMap.get("linkedinPageShowDialog");
    if (!linkedinPageShowDialog) return;
    if (linkedinPageShowDialog === "true") {
      this.showLinkedinPageDialog = true;
      await this.onNewLinkedinPagesAdded();
    }
  }

  private async clearQueryParams() {
    await this.router.navigate([], {
      queryParams: {
        state: null,
        linkedinPageShowDialog: null,
      },
      queryParamsHandling: 'merge',
    });
  }


  private async loadData() {
    try {
      this.isLoading = true;

      if (this.showLinkedinPageDialog) {
        return;
      }

      this.socialProfiles = await this.socialProfileService.findAll(
        this.auth.lastSelectedBrandId()!,
        this.includeNameOfUserWhoAdded
      );
    } catch (err) {
      console.error(err);
      this.toast.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  private async onNewLinkedinPagesAdded() {
    this.dialog
      .open<SocialProfilesFilterLinkedinPagesDialogComponent>(SocialProfilesFilterLinkedinPagesDialogComponent, {
        autoFocus: false,
        disableClose: true,
        maxHeight: '90vh',
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(async () => {
        this.showLinkedinPageDialog = false;
        return this.loadData();
      });
  }
}
