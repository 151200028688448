import { BaseModel } from './base-model';
import { User } from './user';

export const SocialProfileType = {
  facebookPage: 'facebookPage',
  facebookGroup: 'facebookGroup',
  googleMyBusiness: 'googleMyBusiness',
  instagramBusinessPage: 'instagramBusinessPage',
  linkedinPage: 'linkedinPage',
  linkedinPerson: 'linkedinPerson',
  tiktok: 'tiktok',
  twitter: 'twitter',
  youtube: 'youtube',
} as const;
export type SocialProfileType = (typeof SocialProfileType)[keyof typeof SocialProfileType];

// Data related to the Twitter Profile
export type SocialProfileData = {
  name: string;
  username?: string | null;
  profilePictureUrl?: string | null;
};;

export interface SocialProfile {
  id: string;
  brand: string;
  type: SocialProfileType;
  data: SocialProfileData;
  externalId: string;
  expires: Date | null;
  addedBy: string;
  createdAt: Date;
  updatedAt: Date;
  nameOfUserWhoAdded?: string;
}

export type SocialProfileDto = Omit<SocialProfile, '_id'> & { id: string };

export type PostGroupSocialProfile = {
  socialProfileId: string;
  type: SocialProfileType;
};
