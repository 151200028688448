<section class="post-group-form-dialog" [appLoading]="isLoading">
  <div class="header">
    <h4>{{ title }}</h4>
    <i class="icon outlined" matTooltip="Close" (click)="dialogRef.close(null)"
      >close</i
    >
  </div>

  <hr />

  <div class="content">
    <div class="form-group">
      <label>Group name</label>
      <input
        type="text"
        placeholder="Name of post group"
        [(ngModel)]="group.name"
      />
    </div>

    <div class="form-group">
      <label>Post to</label>
      <ng-select
        class="form-select extra"
        placeholder="Social profiles"
        [searchable]="false"
        [multiple]="true"
        [(ngModel)]="selectedSocialProfileIds"
        [clearable]="false"
      >
        @if (Options.socialProfiles.length) {
          @for (profile of Options.socialProfiles; track profile.id) {
            <ng-option [value]="profile.id">
              <social-profile-item [profile]="profile" />
            </ng-option>
          }
        } @else {
          <ng-option [value]="null" disabled
            >No social profiles available.</ng-option
          >
        }
      </ng-select>
      @if (checkIfTikTokProfileIsSelected()) {
        <app-tiktok-policy></app-tiktok-policy>
      }
    </div>

    <div class="form-group">
      <label>Default post repetition</label>

      <span class="note"
        >Posts saved in this category can be sent more than once. Set the
        default number of times a post should be repeated (you can override this
        on each post).</span
      >
      <ng-select
        class="form-select"
        placeholder="Social profiles"
        [searchable]="false"
        [clearable]="false"
        [multiple]="false"
        [(ngModel)]="group.repetition"
      >
        @for (option of Options.repetition; track option.value) {
          <ng-option [value]="option.value">{{ option.label }}</ng-option>
        }
      </ng-select>
    </div>

    <div class="form-group">
      <label>Default status:</label>

      <mat-radio-group
        class="form-radio"
        aria-label="Select a default status"
        [(ngModel)]="group.defaultStatus"
      >
        @for (option of Options.status; track option.value) {
          <mat-radio-button [value]="option.value"
            >as
            <b style="font-weight: 600; margin-left: 4px">{{
              option.label
            }}</b></mat-radio-button
          >
        }
      </mat-radio-group>
    </div>
  </div>

  <div class="actions">
    <button class="sqs-button transparent" (click)="dialogRef.close(null)">
      Cancel
    </button>

    @if (action === "edit") {
      <button class="sqs-button danger" (click)="onDelete()">Delete</button>
    }

    <button class="sqs-button primary" (click)="onSubmit()">Save</button>
  </div>
</section>
