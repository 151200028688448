<div role="status" class="w-full space-y-4">
  @for (item of loadingItems; track item) {
    <div
      class="flex animate-pulse flex-col gap-2 rounded bg-white p-4 shadow-md"
    >
      <div class="grid grid-cols-2 gap-2">
        <div class="flex flex-col gap-2">
          <div class="h-6 w-3/4 rounded bg-gray-300"></div>
          <div class="h-4 w-1/2 rounded bg-gray-200"></div>
          <div class="h-4 w-1/3 rounded bg-gray-200"></div>
          <hr class="my-2" />
          <div class="ml-2 flex items-center gap-2">
            <div class="h-8 w-8 rounded-full bg-gray-300"></div>
            <div class="h-4 w-1/4 rounded bg-gray-200"></div>
          </div>
          <hr class="my-2" />
        </div>
        <div
          class="h-48 w-1/2 justify-self-end rounded bg-gray-300 md:h-full"
        ></div>
      </div>
      <div class="flex items-center gap-2">
        <div class="h-10 w-20 rounded bg-gray-300"></div>
        <div class="h-10 w-48 rounded bg-gray-300"></div>
      </div>
    </div>
  }
</div>
