<section class="post-form-dialog-container" [appLoading]="isLoading">
  <div class="header">
    <h4>Edit selected post</h4>
    <i class="icon outlined" matTooltip="Close" (click)="dialogRef.close(null)"
      >close</i
    >
  </div>

  <hr />

  @if (this.post.calendarPost) {
    <div class="social-profiles">
      <div>
        Send to:
        <input
          type="checkbox"
          [ngModel]="socialProfileCheckbox.get"
          (ngModelChange)="socialProfileCheckbox.set($event)"
        />
        All
      </div>

      <div class="profiles flex flex-row flex-wrap gap-2">
        @for (socialProfile of allSocialProfiles; track socialProfile.id) {
          <div
            class="social-profile-label"
            [class.selected]="isSelected(socialProfile)"
            (click)="onSocialProfileClick(socialProfile)"
          >
            <img [src]="icon(socialProfile.type)" [alt]="socialProfile.type" />
            <span>{{ socialProfile.data.name || "" }}</span>
          </div>
        }
      </div>
    </div>

    <hr />
  }

  <div class="editor">
    @if (selectedUpload) {
      <div
        class="file"
        [class.active]="!!selectedUpload"
        (click)="onSelectMedia()"
        matTooltip="Update selected media"
      >
        @if (selectedUpload.type === UploadType.image) {
          <i class="icon">edit</i>
          <img [src]="selectedUpload.url" [alt]="selectedUpload.filename" />
        }

        @if (selectedUpload.type === UploadType.video) {
          <img
            class="vid-icon"
            src="/assets/icons/video.png"
            alt="Video Logo overlay"
          />
          <video preload="metadata">
            <source [src]="selectedUpload.url + '#t=0.1'" type="video/mp4" />
          </video>
        }
      </div>
    } @else {
      <div
        class="file"
        (click)="onSelectMedia()"
        matTooltip="Select media for post"
      >
        <i class="icon">add_circle</i>
      </div>
    }

    <div class="text">
      <div class="row">
        <div
          class="text-btn"
          matTooltip="Add an Emoji"
          (click)="isEmojiPickerVisible = !isEmojiPickerVisible"
        >
          <i class="icon outlined">emoji_emotions</i>

          @if (isEmojiPickerVisible) {
            <emoji-mart
              class="emoji-mart"
              set="google"
              (emojiSelect)="onAddEmoji($event)"
              title="Pick an emoji"
            ></emoji-mart>
          }
        </div>

        <div
          class="text-btn"
          matTooltip="Shorten link"
          (click)="onShortenLinks()"
        >
          <i class="icon outlined">link</i>
        </div>
      </div>

      <textarea
        placeholder="Post text content..."
        [(ngModel)]="post.textContent"
        (focus)="isEmojiPickerVisible = false"
      ></textarea>

      <span class="text-counter">
        <i [class.alert]="exceedsLimit('twitter')">Twitter 280</i>
        &nbsp;&VerticalLine;&nbsp;
        <i [class.alert-any]="exceedsLimit()">{{ post.textContent.length }}</i>
      </span>
    </div>
  </div>

  <hr />

  @if (this.post.calendarPost) {
    <div class="schedule">
      <span class="label" [class.faded]="sendLater">Send now</span>
      <mat-slide-toggle color="primary" [(ngModel)]="sendLater">
        <span class="label" [class.faded]="!sendLater">Schedule</span>
      </mat-slide-toggle>

      <input
        type="datetime-local"
        [class.faded]="!sendLater"
        min="2024-04-01"
        max="2100-01-01"
        [(ngModel)]="scheduledAt"
      />

      <label class="tz-label" [class.faded]="!sendLater">{{
        this.auth.currentBrand()?.timezone || ""
      }}</label>
    </div>
  } @else {
    <div class="forms">
      <div class="form-group">
        <label>Save in Post Group</label>

        <div class="row">
          <ng-select
            [searchable]="false"
            [clearable]="false"
            [multiple]="false"
            placeholder="Post Group..."
            [(ngModel)]="post.postGroup"
          >
            @for (group of allPostGroups; track group.id) {
              <ng-option [value]="group.id">{{ group.name }}</ng-option>
            }
          </ng-select>

          <ng-select
            [searchable]="false"
            [clearable]="false"
            [multiple]="false"
            placeholder="with Status..."
            [(ngModel)]="post.status"
          >
            <ng-option [value]="Status.draft">as Drafted</ng-option>
            <ng-option [value]="Status.approved">as Approved</ng-option>
          </ng-select>
        </div>
      </div>

      <div class="form-group">
        <label>Repeat this post</label>

        <ng-select
          [searchable]="false"
          [clearable]="false"
          [multiple]="false"
          placeholder="with Status..."
          [(ngModel)]="post.repetition"
        >
          <ng-option [value]="Repetition.once"
            >Dont repeat &dash; send only once</ng-option
          >
        </ng-select>
      </div>
    </div>
  }

  <hr />

  <div class="post-form-actions">
    <button class="sqs-button success" (click)="handleUpdate()">Update</button>
  </div>
</section>
