import { Component, effect } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { LoadingDirective } from "../../shared/directives/loading.directive";
import { BrandService } from "../../shared/services/brand.service";
import { ToastService } from "../../shared/services/toast.service";
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ConfirmationService } from "../../shared/services/confirmation.service";
import { MatDialog } from "@angular/material/dialog";
import { take } from "rxjs";
import { BrandAddFormDialogComponent } from "./brand-add-form-dialog/brand-add-form-dialog.component";
import { BrandEditFormDialogComponent } from "./brand-edit-form-dialog/brand-edit-form-dialog.component";
import { AuthService } from "../../shared/services/auth.service";
import { BrandDTO } from "@squirrly-social/api-models";

@Component({
    selector: 'app-brands',
    standalone: true,
    imports: [
        FormsModule,
        NgSelectModule,
        LoadingDirective,
        MatIconModule,
        MatButtonModule
    ],
    templateUrl: './brands.component.html',
    styleUrl: './brands.component.scss'
})
export class BrandsComponent {
    isLoading: boolean = false;

    constructor(
        protected brandService: BrandService,
        protected auth: AuthService,
        private toast: ToastService,
        private confirmation: ConfirmationService,
        private dialog: MatDialog,

    ) {
        effect(async () => {
            if (this.brandService.addNewDialogOpen() === true) {
                await this.handleAddNewBrand();
            }
        })

    }

    public async handleEditBrand(brandId: string) {
        if (this.isLoading) return;

        this.dialog
            .open<any, string>(BrandEditFormDialogComponent, {
                autoFocus: false,
                disableClose: true,
                panelClass: 'post-form-overlay-dialog',
                data: brandId
            })
            .afterClosed()
            .pipe(take(1))
            .subscribe(async (result: null | BrandDTO) => {
                if (!result) return;
                await this.brandService.loadBrandsOfCurrentUser();

            });

    }

    public async handleAddNewBrand() {
        if (this.isLoading) return;

        this.dialog
            .open<any>(BrandAddFormDialogComponent, {
                autoFocus: false,
                disableClose: true,
                panelClass: 'post-form-overlay-dialog',
            })
            .afterClosed()
            .pipe(take(1))
            .subscribe(async (result: null | BrandDTO) => {
                this.brandService.addNewDialogOpen.set(false);
                if (!result) return;
                await this.brandService.loadBrandsOfCurrentUser();
            });
    }

    public onDelete(brandId: string, brandName: string) {
        if (this.isLoading) return;

        this.confirmation.confirm({
            title: 'Delete Brand',
            message: `Are you sure you want to permanently delete brand <b>"${brandName}"</b>. This action cannot be undone. Are you sure ?`,
            isRawMessage: true,
            noBtnText: 'Cancel',
            yesBtnText: 'Yes, Delete',
            onSuccess: async () => {
                try {
                    this.isLoading = true;

                    await this.brandService.deleteOne(brandId);
                    if (this.auth.lastSelectedBrandId() === brandId) {
                        await this.auth.refetchCurrentUser();
                    }
                    await this.brandService.loadBrandsOfCurrentUser();
                    this.toast.success(`Brand successfully deleted.`);
                } catch (err) {
                    console.error(err);
                    this.toast.error(err);
                } finally {
                    this.isLoading = false;
                }
            },
        });
    }

}