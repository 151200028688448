<section class="login-page">
  <!-- Left side -- Form -->
  <div class="left" [appLoading]="isLoading">
    <a class="logo" href="https://social.squirrly.co">
      <img src="/assets/img/logo-login.png" alt="Squirrly Social Logo" />
    </a>

    <h1>Login</h1>

    <div class="form">
      <input type="email" placeholder="E-mail" [(ngModel)]="email" />
      <input type="password" placeholder="Password" [(ngModel)]="password" />

      <!-- <span class="checkbox">
        <input
          type="checkbox"
          id="rememberMe"
          [(ngModel)]="persistentSession"
        />
        <label for="rememberMe">Remember Me</label>
      </span> -->

      <button class="btn-primary" (click)="onLogin()">Login</button>
    </div>

    <div class="extra">
      <span
        >Not registered? <a href="/register">Click here</a> to sign up.</span
      >

      <a class="forgot-passwd" href="/reset-password">Forgot Your Password ?</a>
    </div>
  </div>

  <!-- Right side -- Banner -->
  <div class="right">
    <div class="links">
      <a [href]="serviceTermsURL" target="_blank">Terms of Service</a>
      <a [href]="privacyPolicyURL" target="_blank">Privacy Policy</a>
    </div>
  </div>
</section>
