<div class="node" [class.collapsed]="!isExpanded">

  <div class="header" [class.selected]="isSelected">
    <div class="click">
      <i class="icon arrow" (click)="onSelectDirectory()">arrow_drop_down</i>

      <i class="icon outlined" (click)="onSelectDirectory()">{{ folderIcon }}</i>

      <span class="name" (click)="onSelectDirectory()">{{ name }}</span>
    </div>

    @if (isSelected) {
      <i matTooltip="Folder settings"
         class="icon filled settings"
         (click)="onFolderSettings.emit()"
      >settings</i>
    }
  </div>

  @if (node.children != null) {
    <div class="children">

      <!-- Create children only for directories -->
      @for (child of directoryChildren; track child.name) {
        <div class="child">
          <app-tree-node [node]="child"
                         [activeNode]="activeNode"
                         (activeNodeChange)="onActiveNodeChanged($event)"
                         (onFolderSettings)="onFolderSettings.emit()"
          ></app-tree-node>
        </div>
      }
    </div>
  }
</div>
