<div class="card" [appLoading]="isLoading">
  <div class="mb-6 flex items-center justify-between">
    <h2 class="text-xl">Your Brands</h2>

    <div class="flex items-center gap-1">
      <button class="btn-primary" mat-flat-button (click)="handleAddNewBrand()">
        <mat-icon
          aria-label="add brand icon"
          fontIcon="add_circle_outline"
        ></mat-icon>
        <span>Create brand</span>
      </button>
    </div>
  </div>

  <div class="card-content">
    <hr />
    <ul>
      <li class="header">
        <span></span>
        <span><b>Name</b></span>
        <span><b>Owner</b></span>
        <span>&nbsp;</span>
      </li>
      @for (brand of this.brandService.brandsOfCurrentUser; track brand.id) {
        <hr />
        <li class="item">
          <span>&nbsp;</span>
          <span>{{ brand.name }}</span>
          <span>{{ brand.userName }}</span>
          <span class="actions">
            <button
              class="sqs-button dark disabled:cursor-not-allowed disabled:opacity-50"
              [disabled]="brand.userId !== auth.user?._id"
              (click)="handleEditBrand(brand.id)"
            >
              <i class="icon outlined">settings</i>
            </button>
            <button
              class="sqs-button danger disabled:cursor-not-allowed disabled:opacity-50"
              [disabled]="brand.userId !== auth.user?._id"
              (click)="onDelete(brand.id, brand.name)"
            >
              <i class="icon outlined">delete</i>
            </button>
          </span>
        </li>
      }
    </ul>
  </div>
</div>
