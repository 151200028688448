import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zeroPad',
  standalone: true
})
export class ZeroPadPipe implements PipeTransform {

  public static transform(value: number): string {
    if (value < 10) return `0${value}`;
    return `${value}`;
  }

  transform(value: number): string {
    if (value < 10) return `0${value}`;
    return `${value}`;
  }

}
