import { Component, effect } from "@angular/core";
import { LoadingDirective } from "../../../shared/directives/loading.directive";
import { AuthService } from "../../../shared/services/auth.service";
import { BrandInvitationDTO, BrandInvitationStatus, BrandTeamMemberDTO } from "@squirrly-social/api-models";
import { MatTooltip } from "@angular/material/tooltip";
import { ToastService } from "../../../shared/services/toast.service";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { MatTableModule } from "@angular/material/table";
import { MatIconModule } from "@angular/material/icon";
import { BrandMemberService } from "../../../shared/services/brand-member.service";
import { BrandInvitationService } from "../../../shared/services/brand-invitation.service";


@Component({
    selector: 'app-brand-members',
    standalone: true,
    imports: [LoadingDirective, MatTooltip, FormsModule, CommonModule, ReactiveFormsModule, MatTableModule, MatIconModule],
    templateUrl: './brand-members.component.html',
    styleUrl: './brand-members.component.scss'
})
export class BrandMembersComponent {
    constructor(
        protected auth: AuthService,
        private toast: ToastService,
        private fb: FormBuilder,
        private brandMemberService: BrandMemberService,
        private brandInvitationService: BrandInvitationService,
    ) {
        this.form = this.fb.group({
            memberEmail: ['', [Validators.required, Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/)]],
        });
        effect(async () => {
            if (this.auth.lastSelectedBrandId()) {
                await this.loadData();
            }
        });
    }

    isLoading: boolean = false;
    form: FormGroup;
    brandMemberList: BrandTeamMemberDTO[] = [];
    brandInvitedMemberList: BrandInvitationDTO[] = [];
    displayedBrandMemberList: BrandTeamMember[] = [];
    displayedColumns: string[] = ['email', 'status', 'invitedAt', 'actions'];
    BrandTeamMemberStatus = BrandTeamMemberStatus;

    async loadData() {
        this.isLoading = true;
        try {
            this.brandMemberList = await this.brandMemberService.getMembersOfBrand(this.auth.lastSelectedBrandId());
            this.brandInvitedMemberList = await this.brandInvitationService.getAllInvitationsForBrand(this.auth.lastSelectedBrandId());
            this.displayedBrandMemberList = [
                ...this.brandMemberList.map(member => ({
                    brandId: member.brandId,
                    email: member.email,
                    status: BrandTeamMemberStatus.MEMBER,
                    joinedAt: member.joinedAt,
                    userId: member.userId,
                })),
                ...this.brandInvitedMemberList.map(invitation => ({
                    brandId: invitation.brandId,
                    email: invitation.emailOfInvitedUser,
                    status: mapBrandInvitationStatusToBrandTeamMemberStatus[invitation.status],
                    invitationId: invitation.invitationId,
                }))
            ];
        } catch (error) {
            console.error(error);
            this.toast.error(error);
        } finally {
            this.isLoading = false;
        }
    }

    async onSubmit() {
        if (!this.form.valid || this.isLoading) {
            return;
        }
        const memberEmail = this.form.value.memberEmail;
        try {
            this.isLoading = true;
            await this.brandInvitationService.inviteNewBrandMember(this.auth.lastSelectedBrandId(), memberEmail);
            await this.loadData();
            this.toast.success('Invitation sent');
        } catch (error) {
            console.error(error);
            this.toast.error(error);
        }
        finally {
            this.isLoading = false;
            this.form.reset();
        }
    }

    async handleRemoveMember(member: BrandTeamMember) {
        try {
            this.isLoading = true;
            if (member.status === BrandTeamMemberStatus.MEMBER) {
                await this.brandMemberService.removeMember(member.brandId, member.userId!);
            } else {
                await this.brandInvitationService.deleteInvitation(member.brandId, member.invitationId!);
            }
            await this.loadData();
            this.toast.success('Member removed successfully');
        } catch (error) {
            console.error(error);
            this.toast.error(error);
        } finally {
            this.isLoading = false;
        }
    }
}

const BrandTeamMemberStatus = {
    PENDING: 'PENDING',
    REJECTED: 'REJECTED',
    MEMBER: 'MEMBER',
} as const;
type BrandTeamMemberStatus = typeof BrandTeamMemberStatus[keyof typeof BrandTeamMemberStatus];

type BrandTeamMember = {
    email: string;
    status: BrandTeamMemberStatus;
    brandId: string;
    joinedAt?: Date;
    invitationId?: string;
    userId?: string;
}

const mapBrandInvitationStatusToBrandTeamMemberStatus = {
    [BrandInvitationStatus.PENDING]: BrandTeamMemberStatus.PENDING,
    [BrandInvitationStatus.REJECTED]: BrandTeamMemberStatus.REJECTED,
    [BrandInvitationStatus.ACCEPTED]: BrandTeamMemberStatus.MEMBER,
}