import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { DialogData } from '../../models/misc';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-confirmation-dialog',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    SafeHtmlPipe,
    MatButton,
  ],
  templateUrl: './confirmation-dialog.component.html',
  styleUrl: './confirmation-dialog.component.scss'
})
export class ConfirmationDialogComponent {
  title: string;
  message: string;
  yesBtnText: string;
  noBtnText: string;
  isRawMessage: boolean;
  yesBtnColor: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData.Confirmation
  ) {
    this.title = data?.title || 'Attention';
    this.message = data?.message || 'Are you sure you want to perform this action?';
    this.yesBtnText = data?.yesBtnText || 'Yes';
    this.noBtnText = data?.noBtnText || 'Cancel';
    this.isRawMessage = data?.isRawMessage || false;
    this.yesBtnColor = data?.yesBtnColor || 'danger'
  }
}
