import { Injectable } from "@angular/core";
import { injectTrpcClient } from "./trpc/client";
@Injectable({
    providedIn: 'root'
})
export class ChatGPTService {
    protected trpcClient = injectTrpcClient();

    public async checkUserChatGPTConnection(brandId: string) {
        const result = await this.trpcClient.chatGPTSettings.getConnectionForCurrentUser.query({ brandId });
        return result.status === CHATGPT_CONNECTION_STATUS.CONNECTED ? true : false;
    }

    public async reschedulePost(postId: string) {
        return await this.trpcClient.post.rescheduleUsingAI.mutate({ id: postId });
    }
}

const CHATGPT_CONNECTION_STATUS = {
    CONNECTED: 'CONNECTED',
    DISCONNECTED: 'DISCONNECTED',
} as const;

type EnumValues<T> = T[keyof T];
type CHATGPT_CONNECTION_STATUS = EnumValues<typeof CHATGPT_CONNECTION_STATUS>;