import { Component, OnInit } from "@angular/core";
import { LoadingDirective } from "../../../shared/directives/loading.directive";
import { MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { SocialProfileType } from "../../../shared/models/social-profile";
import { SelectionModel } from "@angular/cdk/collections";
import { SocialProfileService } from "../../../shared/services/social-profile.service";
import { MatButtonModule } from "@angular/material/button";
import { SocialProfileDialogElementDTO } from "@squirrly-social/api-models";
import { AuthService } from "../../../shared/services/auth.service";
import { ToastService } from "../../../shared/services/toast.service";

@Component({
    selector: 'app-social-profiles-filter-linkedin-pages-dialog',
    standalone: true,
    imports: [
        LoadingDirective,
        MatTableModule,
        MatCheckboxModule,
        MatButtonModule
    ],
    templateUrl: './social-profiles-filter-linkedin-pages-dialog.component.html',
    styleUrl: './social-profiles-filter-linkedin-pages-dialog.component.scss',
})
export class SocialProfilesFilterLinkedinPagesDialogComponent implements OnInit {
    constructor(
        protected dialogRef: MatDialogRef<SocialProfilesFilterLinkedinPagesDialogComponent>,
        private auth: AuthService,
        private socialProfileService: SocialProfileService,
        private toast: ToastService
    ) { }
    async ngOnInit() {
        await this.loadData();
    }
    isLoading: boolean = false;
    displayedColumns: string[] = ['select', 'name', 'type'];
    dataSource = new MatTableDataSource<SocialProfileDialogElementDTO>();
    selection = new SelectionModel<SocialProfileDialogElementDTO>(true, []);
    linkedinProfile = this.socialProfileService.PROFILE_MAP[SocialProfileType.linkedinPage];

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAllRows() {
        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }
        this.selection.select(...this.dataSource.data);
    }
    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: SocialProfileDialogElementDTO): string {
        if (!row) {
            return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row with id ${row.id}`;
    }

    async handleSaveButton() {
        if (this.isLoading) {
            return;
        }
        try {
            this.isLoading = true;
            await this.deleteNonSelectedLinkedinPages();
            this.dialogRef.close();
        } catch (err) {
            console.error(err);
            this.toast.error('Failed to save linkedin pages');
            this.dialogRef.close();
        } finally {
            this.isLoading = false;
        }
    }

    async deleteNonSelectedLinkedinPages() {
        const newLinkedinPagesAdded = this.dataSource.data;
        const selectedLinkedinPagesIds = new Set(this.selection.selected.map((page) => page.id));
        const linkedinPagesToDelete = newLinkedinPagesAdded.filter((page) => !selectedLinkedinPagesIds.has(page.id) && page.type === SocialProfileType.linkedinPage);
        await this.socialProfileService.deleteMany(this.auth.lastSelectedBrandId(), linkedinPagesToDelete.map((page) => page.id));
    }

    async loadData() {
        if (this.isLoading) {
            return;
        }
        try {
            this.isLoading = true;
            const linkedinPages = await this.socialProfileService.getLastLinkedinPagesAdded(this.auth.lastSelectedBrandId());
            // if no pages are found close the dialog
            if (linkedinPages.length === 0) {
                this.dialogRef.close();
            }
            this.dataSource.data = linkedinPages;
        } catch (error) {
            console.error(error);
            this.toast.error('Failed to load linkedin pages');
            this.dialogRef.close();
        } finally {
            this.isLoading = false;
        }
    }
}