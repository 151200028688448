import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../../../shared/models/misc';
import { ConfirmationService } from '../../../shared/services/confirmation.service';
import { LoadingDirective } from '../../../shared/directives/loading.directive';
import { ToastService } from '../../../shared/services/toast.service';
import { UploadService } from '../../../shared/services/upload.service';
import { AuthService } from '../../../shared/services/auth.service';
import { MatTooltip } from '@angular/material/tooltip';
import { FormsModule } from '@angular/forms';
import { BrandService } from '../../../shared/services/brand.service';

@Component({
  selector: 'app-folder-form-dialog',
  standalone: true,
  imports: [
    LoadingDirective,
    MatTooltip,
    FormsModule,
  ],
  templateUrl: './folder-form-dialog.component.html',
  styleUrl: './folder-form-dialog.component.scss'
})
export class FolderFormDialogComponent {

  mode: null | 'add' | 'rename' = null;

  name = '';
  isLoading = false;

  currentDirName: string;

  constructor(
    public dialogRef: MatDialogRef<FolderFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData.FolderFormDialog,
    protected auth: AuthService,
    protected confirmation: ConfirmationService,
    protected toast: ToastService,
    protected uploadService: UploadService,
    protected brandService: BrandService,
  ) { this.extractData(); }

  private extractData() {
    // Extracting the current directory name (if not root)
    if (this.data.isRootDir) {
      this.currentDirName = '';
      this.data.currentDirPath = '/';
    } else {
      const parts = this.data.currentDirPath.slice().split('/');
      this.currentDirName = parts.pop()!;
    }

  }

  public onDelete() {
    if (this.data.isRootDir) {
      this.toast.error('You cannot delete the root directory.');
      return;
    }

    this.confirmation.confirm({
      title: 'Delete directory',
      message: 'It will delete all sub-folders and all media stored within them. If this media is attached to a post that is waiting to be sent, this media will be removed from the post. This action cannot be undone.',
      noBtnText: 'Cancel',
      yesBtnText: 'Yes, Delete',
      onSuccess: async () => {
        try {
          this.isLoading = true;

          // Extract current dir from path, let only the path of containing dir ==> /[dir1]/
          const parts = this.data.currentDirPath.split('/').filter((val) => !!val.length);
          parts.pop();

          const containingDirRelativePath = `/${parts.join('/')}/`.replace(/\/\//gmi, '/');
          const success = await this.uploadService.deleteDirectory(
            this.auth.lastSelectedBrandId(),
            containingDirRelativePath,
            this.currentDirName
          );
          if (success) {
            this.toast.success('Folder successfully deleted.');
            return this.dialogRef.close(true);
          } else {
            this.toast.warning('Folder was not deleted.');
          }
        } catch (err) {
          console.error(err);
          this.toast.error(err);
        } finally {
          this.isLoading = false;
        }
      }
    });
  }

  public async onAdd() {
    if (this.isLoading) return;

    if (this.name.length < 2) {
      this.toast.error('Directory name must have at least 2 characters.');
      return;
    }

    if (this.name.includes('/')) {
      this.toast.error('Directory name cannot contain "/".');
      return;
    }

    try {
      this.isLoading = true;

      let currentDir = this.data.currentDirPath.split('/').filter((val) => !!val.length).join('/');
      currentDir = `/${currentDir}/`.replace(/\/\//gmi, '/');

      const success = await this.uploadService.createDirectory(
        this.auth.lastSelectedBrandId(),
        currentDir,
        this.name
      );
      if (success) {
        this.toast.success('Sub-directory successfully created.');
        return this.dialogRef.close(true);
      } else {
        this.toast.warning('Folder was not created.');
      }
    } catch (err) {
      console.error(err);
      this.toast.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  public async onRename() {
    if (this.data.isRootDir) {
      this.toast.error('You cannot delete the root directory.');
      return;
    }

    if (this.isLoading) return;

    if (this.name.length < 2) {
      this.toast.error('Directory name must have at least 2 characters.');
      return;
    }

    if (this.name.includes('/')) {
      this.toast.error('Directory name cannot contain "/".');
      return;
    }

    try {
      this.isLoading = true;
      // nu e ok state management pentru tree-ul vietii plm
      // Extract current dir from path, let only the path of containing dir ==> /[dir1]/
      const parts = this.data.currentDirPath.split('/').filter((val) => !!val.length);
      const currentDirName = parts.pop()!;

      const containingDirRelativePath = `/${parts.join('/')}/`.replace(/\/\//gmi, '/');

      const success = await this.uploadService.renameDirectory(
        this.auth.lastSelectedBrandId(),
        containingDirRelativePath,
        currentDirName,
        this.name
      );
      if (success) {
        this.toast.success('Directory successfully renamed.');
        return this.dialogRef.close(true);
      } else {
        this.toast.warning('Folder was not renamed.');
      }
    } catch (err) {
      console.error(err);
      this.toast.error(err);
    } finally {
      this.isLoading = false;
    }
  }
}
