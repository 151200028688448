import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Post } from '../../../shared/models/post';
import { UploadType } from '../../../shared/models/upload';
import { MatTooltip } from '@angular/material/tooltip';
import { PostRepetition, PostStatus } from '../../../shared/models/post-group';
import { LoadingDirective } from '../../../shared/directives/loading.directive';
import { AuthService } from '../../../shared/services/auth.service';
import { ConfirmationService } from '../../../shared/services/confirmation.service';
import { ToastService } from '../../../shared/services/toast.service';
import { PostService } from '../../../shared/services/post.service';

@Component({
  selector: 'app-post-card',
  standalone: true,
  imports: [MatTooltip, LoadingDirective],
  templateUrl: './post-card.component.html',
  styleUrl: './post-card.component.scss',
})
export class PostCardComponent {
  readonly UploadType = UploadType;
  readonly PostRepetition = PostRepetition;
  readonly PostStatus = PostStatus;

  @Input({ required: true })
  post: Post;

  @Input()
  position?: number;

  @Output() postDeleted = new EventEmitter<void>();
  @Output() postUpdated = new EventEmitter<void>();
  @Output() postEdited = new EventEmitter<void>();
  @Output() postCopied = new EventEmitter<void>();
  @Output() postRescheduledUsingAI = new EventEmitter<void>();

  isLoading = false;

  constructor(
    protected auth: AuthService,
    protected confirm: ConfirmationService,
    protected toast: ToastService,
    protected postService: PostService
  ) { }

  public get name() {
    return this.post.createdUser?.name ?? null;
  }

  public get repeat() {
    return {
      [PostRepetition.once]: 'Send Once',
    }[this.post.repetition];
  }

  public get url(): null | { link: string; label: string } {
    // Extract (all) URLs
    const urls = this.post.textContent.match(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,63}\b([-a-zA-Z0-9()'@:%_\+.~#?!&//=]*)/gi
    );
    if (!urls?.length) return null;

    // Fetch first URL and strip prefix
    const fullUrl = urls[0].replace(/[()]|\.$/g, '');
    const url = fullUrl.replace('https://', '').replace('http://', '');

    // Find first slash's index, so we can strip anything after "X.com"
    const firstSlashIdx = url.indexOf('/');
    if (firstSlashIdx === -1) return { link: fullUrl, label: url };

    return { link: fullUrl, label: url.slice(0, firstSlashIdx) };
  }

  public onDelete() {
    if (this.isLoading) return;
    this.isLoading = true;
    this.confirm.confirm({
      title: 'Delete Post',
      message:
        'You are about to delete this post. This action cannot be undone. Are you sure?',
      noBtnText: 'Cancel',
      yesBtnText: 'Yes, Delete',
      onSuccess: async () => {
        try {
          await this.postService.deleteOne(this.post.id);
          this.postDeleted.emit();
          this.toast.success('Post deleted successfully');
        } catch (e) {
          this.toast.error('Failed to delete post');
        } finally {
          this.isLoading = false;
        }
      },
      onDismiss: () => {
        this.isLoading = false;
      }
    });
  }

  public onMovePostToApprove() {
    if (this.isLoading) return;
    this.isLoading = true;
    this.confirm.confirm({
      title: 'Move Post to Approve',
      message: 'Are you sure you want to move this post to approve?',
      noBtnText: 'Cancel',
      yesBtnText: 'Yes, Move',
      onSuccess: async () => {
        try {
          await this.postService.movePostToApprove(this.post.id);
          this.postUpdated.emit();
          this.toast.success('Post moved to approve successfully');
        } catch (e) {
          this.toast.error('Failed to move post to approve');
        } finally {
          this.isLoading = false;
        }
      },
      onDismiss: () => {
        this.isLoading = false;
      }
    });
  }
  public onMovePostToDraft() {
    if (this.isLoading) return;
    this.isLoading = true;
    this.confirm.confirm({
      title: 'Move Post to Draft',
      message: 'Are you sure you want to move this post to draft?',
      noBtnText: 'Cancel',
      yesBtnText: 'Yes, Move',
      onSuccess: async () => {
        try {
          await this.postService.movePostToDraft(this.post.id);
          this.postUpdated.emit();
          this.toast.success('Post moved to draft successfully');
        } catch (e) {
          this.toast.error('Failed to move post to draft');
        } finally {
          this.isLoading = false;
        }
      },
      onDismiss: () => {
        this.isLoading = false;
      }
    });
  }

  public onEdit() {
    if (this.isLoading) return;
    this.postEdited.emit();
  }

  public onCopy() {
    if (this.isLoading) return;
    this.postCopied.emit();
  }
  public onRescheduleUsingAI() {
    if (this.isLoading) return;
    this.postRescheduledUsingAI.emit();
  }
}
