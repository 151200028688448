import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toast: ToastrService) { }

  public success(message: string) {
    this.toast.success(message);
  }

  public show(message: string) {
    this.toast.show(message, 'Info');
  }

  public warning(message: string) {
    this.toast.warning(message, 'Warning');
  }

  public error(err: any) {
    const message = formatMessage(err);

    this.toast.error(message, 'Error');
  }
}

function formatMessage(error: any): string {
  if (error == null) return '';
  if (typeof error === 'string') {
    return error;
  }
  if (typeof error === 'object') {
    return (<Error>(error.error || { })).message || error.message;
  }
  return '';
}
