import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { authToken as authTokenService } from '../services/auth.service';


export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);

  const authToken = authTokenService();
  const isAuthenticated = !!authToken && authToken !== 'null';

  if (isAuthenticated) return true;

  console.error(`Access denied / Unauthenticated (${state.url})`);
  void router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
  return false;
};
