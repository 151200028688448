import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Location } from "@angular/common";
import { filter } from 'rxjs';
import { MatIconButton } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthService } from '../../services/auth.service';


type NavLink = {
    id: number;
    icon: string;
    title: string;
    page: string;
    target: '_blank' | '_self';
    isExternal: boolean;
};

@Component({
    selector: 'app-sidenav',
    standalone: true,
    imports: [MatTooltipModule, MatIconButton],
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

    constructor(
        private location: Location,
        private router: Router,
        private auth: AuthService
    ) { }


    isExpanded: boolean;
    currentUrl: string = '';
    links: NavLink[] = getNavLinks();

    ngOnInit() {
        this.isExpanded = this.auth.storage.getItem('sidenav-expanded') ? JSON.parse(this.auth.storage.getItem('sidenav-expanded')!) : true;
        this.currentUrl = this.location.path();
        this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event: NavigationStart | any) => {
            if (!event.url) return;
            this.currentUrl = event.url;
        });
    }

    public onToggleSidenav() {
        this.isExpanded = !this.isExpanded;
        this.auth.storage.setItem('sidenav-expanded', JSON.stringify(this.isExpanded));
    }

    public isCurrentPage(link?: string) {
        const currentLinkWithoutQueryParams = this.currentUrl.split('?')[0];
        return currentLinkWithoutQueryParams === link;
    }

    public onNavigateTo(link: NavLink) {
        if (link.isExternal) {
            window.open(link.page, link.target);
        } else {
            this.router.navigateByUrl(link.page);
        }
    }
}

function getNavLinks(): NavLink[] {
    return [
        // {
        //     id: 1,
        //     icon: 'home',
        //     title: 'Home',
        //     page: '',
        //     target: '_self',
        //     isExternal: false,
        // },
        {
            id: 2,
            icon: 'calendar_month',
            title: 'Calendar',
            page: '/calendar',
            target: '_self',
            isExternal: false,
        },
        {
            id: 3,
            icon: 'apps',
            title: 'Post Groups',
            page: '/post-groups',
            target: '_self',
            isExternal: false,
        },
        {
            id: 4,
            icon: 'perm_media',
            title: 'Media',
            page: '/media-library',
            target: '_self',
            isExternal: false,
        },
        {
            id: 5,
            icon: 'assistant',
            title: 'Social Media Assistant',
            page: '/social-media-assistant',
            target: '_self',
            isExternal: false,
        },

        {
            id: 6,
            icon: 'alternate_email',
            title: 'Manage Social Profiles',
            page: '/social-profiles',
            target: '_self',
            isExternal: false,
        },
        {
            id: 7,
            icon: 'credit_card',
            title: 'Billing',
            page: 'https://customer.digitalpackglobal.com/',
            target: '_blank',
            isExternal: true,
        },
        {
            id: 8,
            icon: 'help',
            title: 'Support',
            page: 'https://www.squirrly.co/social-media/app-guidance-for-squirrly-social',
            target: '_self',
            isExternal: true,
        },
    ];
}