import { Component, Inject, OnInit, } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { DateUtils } from '../../../shared/utils/date.utils';
import { FormsModule } from '@angular/forms';
import { LoadingDirective } from '../../../shared/directives/loading.directive';
import { ToastService } from '../../../shared/services/toast.service';
import { BrandService } from '../../../shared/services/brand.service';



@Component({
    selector: 'app-brand-edit-form-dialog',
    standalone: true,
    imports: [
        MatIconModule,
        MatTooltip,
        FormsModule,
        LoadingDirective,
    ],
    templateUrl: './brand-edit-form-dialog.component.html',
    styleUrl: './brand-edit-form-dialog.component.scss'
})
export class BrandEditFormDialogComponent implements OnInit {
    constructor(
        protected dialogRef: MatDialogRef<BrandEditFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public brandId: string,
        private toast: ToastService,
        private brandService: BrandService,
    ) { }

    timezones: string[] = [];
    brandName: string = '';
    brandTimezone: string = '';
    isLoading = false;

    async ngOnInit() {
        this.timezones = DateUtils.AllTimezones;
        const selectedBrand = await this.brandService.findOne(this.brandId);
        if (!selectedBrand) {
            this.toast.error('Brand not found');
            this.dialogRef.close();
            return;
        }
        this.brandName = selectedBrand.name;
        this.brandTimezone = selectedBrand.timezone;
    }

    public async onUpdateBrand() {
        if (this.isLoading) return;

        if (this.brandName.length < 2) {
            this.toast.error('Brand name must be at least 2 characters long');
            return;
        }

        if (!this.brandTimezone) {
            this.toast.error('Brand timezone is required');
            return;
        }

        if (!DateUtils.validateTimezoneString(this.brandTimezone)) {
            this.toast.error('Invalid timezone');
            return;
        }

        this.isLoading = true;

        try {

            const payload = { name: this.brandName, timezone: this.brandTimezone };
            const updatedBrand = await this.brandService.updateOne(this.brandId, payload);
            this.toast.success('Brand updated successfully');
            this.dialogRef.close(updatedBrand);

        } catch (err) {
            console.error(err);
            this.toast.error(err);
        }
        finally {
            this.isLoading = false

        }

    }

}