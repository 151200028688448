import { Component, Inject, OnInit } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { NgSelectModule } from '@ng-select/ng-select';
import { PickerComponent } from '@ctrl/ngx-emoji-mart';
import { LoadingDirective } from "../../../../shared/directives/loading.directive";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Upload, UploadScope, UploadType } from "../../../../shared/models/upload";
import { PostGroup, PostRepetition, PostStatus } from "../../../../shared/models/post-group";
import { DialogData } from "../../../../shared/models/misc";
import { GalleryDialogComponent } from "../gallery-dialog/gallery-dialog.component";
import { Post } from "../../../../shared/models/post";
import { EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji/emoji.component';
import { PostService } from "../../../../shared/services/post.service";
import { AuthService } from "../../../../shared/services/auth.service";
import { ToastService } from "../../../../shared/services/toast.service";
import { SocialProfileService } from "../../../../shared/services/social-profile.service";
import { PostGroupService } from "../../../../shared/services/post-group.service";
import { MatDialog } from "@angular/material/dialog";
import { take } from 'rxjs';
import { SocialProfile, SocialProfileType } from "../../../../shared/models/social-profile";
import { GeneralUtils } from "../../../../shared/utils/general.utils";
import { DateUtils } from "../../../../shared/utils/date.utils";
import { BrandService } from "../../../../shared/services/brand.service";


@Component({
    selector: 'app-update-post-form-dialog',
    standalone: true,
    templateUrl: './update-post-form-dialog.component.html',
    styleUrl: './update-post-form-dialog.component.scss',
    imports: [FormsModule,
        MatTooltip,
        NgSelectModule,
        PickerComponent,
        LoadingDirective,
        MatSlideToggle]

})
export class UpdatePostFormDialogComponent implements OnInit {

    constructor(
        protected dialogRef: MatDialogRef<UpdatePostFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public postId: string,
        protected postService: PostService,
        protected auth: AuthService,
        protected toast: ToastService,
        protected postGroupService: PostGroupService,
        protected socialProfileService: SocialProfileService,
        protected dialog: MatDialog,
        protected brandService: BrandService
    ) { }

    ngOnInit() {
        this.loadData();
    }

    public readonly Status = PostStatus;
    public readonly Repetition = PostRepetition;
    public readonly UploadType = UploadType;

    isLoading = false;

    isEmojiPickerVisible = false;

    post: Post = EmptyPost();
    selectedUpload: null | Upload = null;

    allPostGroups: PostGroup[] = [];
    selectedSocialProfiles: SocialProfile[] = [];
    allSocialProfiles: SocialProfile[] = [];

    sendLater = false; // "Send (post) now" vs "Send (post) later (on schedule)"
    scheduledAt: string;

    public onSocialProfileClick(socialProfile: SocialProfile) {
        const idx = this.selectedSocialProfiles.findIndex(({ id }) => id === socialProfile.id);
        if (idx === -1) {
            this.selectedSocialProfiles.push(socialProfile)
        } else {
            this.selectedSocialProfiles.splice(idx, 1);
        }
    }

    public isSelected(socialProfile: SocialProfile) {
        return !!this.selectedSocialProfiles.find(({ id }) => id === socialProfile.id);
    }

    public get socialProfileCheckbox() {
        return {
            get: (() => {
                for (const socialProfile of this.allSocialProfiles) {
                    const isSelected = !!this.selectedSocialProfiles.find(({ id }) => id === socialProfile.id);
                    if (!isSelected) return false;
                }
                return true;
            })(),

            set: async (selectAll: boolean) => {
                this.isLoading = true;

                if (selectAll) {
                    for (const socialProfile of this.allSocialProfiles) {
                        const isSelected = this.selectedSocialProfiles.find(({ id }) => id === socialProfile.id);
                        if (!isSelected) {
                            this.selectedSocialProfiles.push(socialProfile);
                            await new Promise<void>((r) => setTimeout(r, 90));
                        }
                    }
                } else {
                    while (this.selectedSocialProfiles.length) {
                        this.selectedSocialProfiles.splice(0, 1);
                        await new Promise<void>((r) => setTimeout(r, 90));
                    }
                }

                this.isLoading = false;
            }
        }
    }


    public exceedsLimit(app: 'twitter' | '' = '') {
        switch (app) {
            case 'twitter': return this.post.textContent.length > 280;
            default: return this.post.textContent.length > 280; // Min length of all required max lengths
        }
    }

    public onAddEmoji({ emoji }: EmojiEvent) {
        this.post.textContent += emoji.native;
    }

    textContentUrls: { url: string, tinyUrl: string }[] = [];
    public async onShortenLinks() {
        if (this.isLoading) return;

        try {
            this.isLoading = true;

            const urls = this.post.textContent.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,63}\b([-a-zA-Z0-9()'@:%_\+.~#?!&//=]*)/gi)

            const items = !urls?.length
                ? []
                : urls.map((item) => item.replace(/[()]|\.$/g, ''));

            this.textContentUrls = items.map(
                // If the url is already shortened, set the url as "tinyUrl" as well
                (url) => url.startsWith('https://tinyurl.com')
                    ? ({ url, tinyUrl: url })
                    : ({ url, tinyUrl: '' })
            );

            // Replace URLs with shortened ones
            let newTextContent = this.post.textContent;
            for (const entry of this.textContentUrls) {
                if (!entry.tinyUrl.length) {
                    entry.tinyUrl = await this.postService.createTinyUrl(this.auth.lastSelectedBrandId(), entry.url);
                }

                newTextContent = newTextContent.replaceAll(entry.url, entry.tinyUrl);
            }
            this.post.textContent = newTextContent;
        } catch (err) {
            console.error(err);
            this.toast.error(err);
        } finally {
            this.isLoading = false;
        }
    }

    public icon(type: SocialProfileType) {
        return '/assets/icons/fa-' +
            {
                [SocialProfileType.twitter]: 'twitter.svg',
                [SocialProfileType.tiktok]: 'tiktok.svg',
                [SocialProfileType.linkedinPerson]: 'linkedin.svg',
                [SocialProfileType.linkedinPage]: 'linkedin.svg',
                [SocialProfileType.instagramBusinessPage]: 'instagram.svg',
                [SocialProfileType.googleMyBusiness]: 'google.svg',
                [SocialProfileType.facebookGroup]: 'facebook.svg',
                [SocialProfileType.facebookPage]: 'facebook.svg',
                [SocialProfileType.youtube]: 'youtube.svg',
            }[type];
    }

    public onSelectMedia() {
        if (this.isLoading) return;

        this.dialog.open<any, DialogData.SelectMedia>(GalleryDialogComponent, {
            autoFocus: false,
            disableClose: true,
            data: {},
            panelClass: 'gallery-dialog-panel',
        })
            .afterClosed()
            .pipe(take(1))
            .subscribe(async (result?: null | Upload) => {
                if (!result) return;
                if (result._id === this.selectedUpload?._id) return;

                try {
                    this.isLoading = true;

                    this.selectedUpload = result;
                    this.post.upload = this.selectedUpload._id;
                } catch (err) {
                    console.error(err);
                    this.toast.error(err)
                } finally {
                    this.isLoading = false;
                }
            })
    }

    private async loadData() {
        try {
            this.isLoading = true;
            this.allSocialProfiles = await this.socialProfileService.findAll(this.auth.lastSelectedBrandId());
            this.allPostGroups = await this.postGroupService.findAll(this.auth.lastSelectedBrandId());
            if (this.postId) {
                const result = await this.postService.findOne(this.postId);
                this.post = result;
                if (result.uploadFile) {
                    this.selectedUpload = MapToUploadType(result);
                    this.selectedUpload.url = this.post.uploadUrl!;
                }
                if (result.calendarPost) {
                    this.selectedSocialProfiles = this.allSocialProfiles.filter(({ id }) => result.calendarPost!.socialProfiles.map(({ id }: { id: string }) => id).includes(id));
                    this.sendLater = !result.calendarPost.sendNow;
                    this.scheduledAt = DateUtils.convertFromLocalToGivenTimezone(result.calendarPost.scheduledAt, DateUtils.timezone);
                }
            }
        } catch (err) {
            console.error(err);
            this.toast.error(err);
        } finally {
            this.isLoading = false;
        }
    }

    async handleUpdate() {
        try {
            this.isLoading = true;
            let payload: any;
            if (this.post.calendarPost) {
                payload = this.validateCalendarPostEditedData();
            }
            else {
                payload = this.validatePostGroupEditedData();
            }
            const updatedPost = await this.postService.updateOne(this.post.id, payload);
            this.toast.success('Post updated successfully.');
            this.dialogRef.close(updatedPost);

        } catch (err) {
            console.error(err);
            this.toast.error(err);
        } finally {
            this.isLoading = false;
        }
    }

    private validatePostGroupEditedData() {
        [
            { condition: !!this.post.postGroup?.toString().length, err: 'The post must be assigned to a post group.' },
            { condition: !!this.post.status?.toString().length, err: 'The status must be selected.' },
            { condition: !!this.post.repetition?.toString().length, err: 'The repetition must be selected.' },
        ].forEach(({ condition, err }) => { if (!condition) throw new Error(err); })

        // Text content must be encoded
        const textContent = GeneralUtils.encodeContent(this.post.textContent || '')

        return {
            repetition: this.post.repetition!,
            status: this.post.status!,
            uploadId: this.selectedUpload?._id || null,
            textContent,
            postGroupId: this.post.postGroup!,

            socialProfiles: null,
            scheduledAt: null,
            sendNow: null,

        };
    }

    private validateCalendarPostEditedData() {


        [
            { condition: !!this.selectedSocialProfiles.length, err: 'At least one social profile must be selected.' },

            // Validate "scheduledAt" only if set (in the future). For "Send now" it will be provided as "null"
            ...(!this.sendLater ? [] : [
                { condition: !isNaN(new Date(this.scheduledAt).getTime()), err: 'Invalid date' },
                { condition: !DateUtils.isAfter(new Date(), new Date(this.scheduledAt)), err: 'Cannot schedule a post in the past' },
            ]),
        ].forEach((({ condition, err }) => { if (!condition) throw new Error(err); }));

        // Text content must be encoded
        const textContent = GeneralUtils.encodeContent(this.post.textContent || '')

        return {
            socialProfiles: this.selectedSocialProfiles.map(({ id }) => id),
            uploadId: this.selectedUpload?._id || null,
            textContent,
            sendNow: !this.sendLater,
            scheduledAt: (
                (this.sendLater
                    ? new Date(this.scheduledAt).toISOString()
                    : null)
            ),

            status: null,
            postGroupId: null,
            repetition: null,

        };
    }
}

export function EmptyPost(): Post {
    return JSON.parse(JSON.stringify({
        id: '',
        brand: '',
        postGroup: null,
        calendarPost: null,
        status: PostStatus.draft,
        repetition: PostRepetition.once,
        upload: null,
        uploadFile: null,
        textContent: '',
        uploadUrl: '',
        createdUserId: '',
        createdUser: null,
    }));
}

export function MapToUploadType(data: any): Upload {
    return { ...data.uploadFile, _id: data.uploadFile.id, owner: data.brand, scope: data.uploadFile.scope as UploadScope, type: data.uploadFile.type as UploadType, createdAt: new Date(data.uploadFile.createdAt), updatedAt: new Date(data.uploadFile.updatedAt) };
}