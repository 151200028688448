import { Component, OnInit, } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { DateUtils } from '../../../shared/utils/date.utils';
import { FormsModule } from '@angular/forms';
import { LoadingDirective } from '../../../shared/directives/loading.directive';
import { ToastService } from '../../../shared/services/toast.service';
import { BrandService } from '../../../shared/services/brand.service';



@Component({
    selector: 'app-brand-add-form-dialog',
    standalone: true,
    imports: [
        MatIconModule,
        MatTooltip,
        FormsModule,
        LoadingDirective,
    ],
    templateUrl: './brand-add-form-dialog.component.html',
    styleUrl: './brand-add-form-dialog.component.scss'
})
export class BrandAddFormDialogComponent implements OnInit {
    constructor(
        protected dialogRef: MatDialogRef<BrandAddFormDialogComponent>,
        private toast: ToastService,
        private brandService: BrandService,
    ) { }

    timezones: string[] = [];
    brandName: string = '';
    brandTimezone: string = '';
    isLoading = false;

    ngOnInit() {
        this.timezones = DateUtils.AllTimezones;
    }

    public async onAddNewBrand() {
        if (this.isLoading) return;

        if (this.brandName.length < 2) {
            this.toast.error('Brand name must be at least 2 characters long');
            return;
        }

        if (!this.brandTimezone) {
            this.toast.error('Brand timezone is required');
            return;
        }

        if (!DateUtils.validateTimezoneString(this.brandTimezone)) {
            this.toast.error('Invalid timezone');
            return;
        }

        this.isLoading = true;

        try {

            const payload = { name: this.brandName, timezone: this.brandTimezone };
            const newBrand = await this.brandService.createOne(payload);
            this.toast.success('Brand created successfully');
            this.dialogRef.close(newBrand);

        } catch (err) {
            console.error(err);
            this.toast.error(err);
        }
        finally {
            this.isLoading = false

        }

    }

}