<div class="card" [appLoading]="isLoading">
  <div class="mb-6 flex items-center justify-between">
    <h2 class="text-xl">{{ auth.currentBrand()?.name }}'s Team Members</h2>

    @if (auth.isOwnerOfCurrentBrand()) {
      <form
        [formGroup]="form"
        (ngSubmit)="onSubmit()"
        class="flex items-center justify-center gap-3"
      >
        <div
          matTooltip="Enter the email of the person you want to invite to your brand"
          matTooltipPosition="above"
        >
          <input
            formControlName="memberEmail"
            type="email"
            placeholder="Email..."
            required
          />
        </div>
        <button
          type="submit"
          [disabled]="form.invalid"
          class="btn btn-primary disabled:cursor-not-allowed disabled:opacity-50"
        >
          Send invite
        </button>
      </form>
    }
  </div>
  @if (this.displayedBrandMemberList.length === 0) {
    <div class="text-center">
      <p>You have no brand members.</p>
    </div>
  } @else {
    <table
      mat-table
      [dataSource]="displayedBrandMemberList"
      class="mat-elevation-z0"
    >
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">
          <span
            [ngClass]="{
              'text-green-500': element.status === BrandTeamMemberStatus.MEMBER,
              'text-red-500': element.status === BrandTeamMemberStatus.REJECTED,
              'text-yellow-500':
                element.status === BrandTeamMemberStatus.PENDING,
            }"
          >
            {{ element.status }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="invitedAt">
        <th mat-header-cell *matHeaderCellDef>Joined At</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.joinedAt | date: "mediumDate") ?? "N/A" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          @if (auth.isOwnerOfCurrentBrand()) {
            <button
              mat-button
              matTooltip="Remove member"
              matTooltipPosition="above"
              (click)="handleRemoveMember(element)"
            >
              <mat-icon
                aria-label="reject invitation"
                class="text-red-500 hover:text-red-700"
                >delete</mat-icon
              >
            </button>
          }
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns"
        class="!text-lg"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  }
</div>
