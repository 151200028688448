import { Component, effect } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { LoadingDirective } from "../../../shared/directives/loading.directive";
import { NgSelectModule } from "@ng-select/ng-select";
import { CommonModule } from "@angular/common";
import { ToastService } from "../../../shared/services/toast.service";
import { MatDialog } from "@angular/material/dialog";
import { AddKeyDialogComponent } from "./add-key-dialog/add-key-dialog.component";
import { injectTrpcClient } from "../../../shared/services/trpc/client";
import { AuthService } from "../../../shared/services/auth.service";
import { BrandService } from "../../../shared/services/brand.service";


export const ApiKeyStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
} as const;

export type ApiKeyStatus = (typeof ApiKeyStatus)[keyof typeof ApiKeyStatus]

type ApiKey = {
  id: string;
  name: string;
  status: ApiKeyStatus
  key: string | null;
  visible?: boolean;
}
@Component({
  selector: 'app-account-settings-api-keys',
  standalone: true,
  imports: [
    FormsModule,
    NgSelectModule,
    LoadingDirective,
    CommonModule,
  ],
  templateUrl: './account-settings-api-keys.component.html',
  styleUrl: './account-settings-api-keys.component.scss'
})
export class AccountSettingsApiKeysComponent {

  protected trpcClient = injectTrpcClient();
  isLoading: boolean = false;

  constructor(private toast: ToastService, private dialog: MatDialog, private auth: AuthService, private brandService: BrandService) {
    effect(async () => {
      if (this.auth.user) {
        await this.loadApiKeys();
      }
    });
  }

  async loadApiKeys() {
    const brandId = this.auth.lastSelectedBrandId()
    const apiKeys = await this.trpcClient.apiKeys.list.query({ brandId: brandId });

    this.apiKeys = [...apiKeys as ApiKey[]]
  }

  apiKeys: ApiKey[] = [];

  copyToClipboard(key: string | null) {
    if (key) {
      navigator.clipboard.writeText(key).then(() => {
        this.toast.success('Secret key copied to clipboard');
      }, (err) => {
        console.error('Failed to copy the key: ', err);
      });
    } else {
      this.toast.warning('No key available to copy');
    }
  }

  async deleteKey(id: string) {
    try {
      this.isLoading = true;
      await this.trpcClient.apiKeys.delete.mutate({ id });
      await this.loadApiKeys();

    } catch (err) {
      console.error(err);
      this.toast.error('Failed to delete API key');
    } finally {
      this.isLoading = false;
    }

  }


  getIcon(status: ApiKeyStatus): string {
    switch (status) {
      case ApiKeyStatus.ACTIVE:
        return 'public';
      case ApiKeyStatus.INACTIVE:
        return 'lock';
      default:
        return '';
    }
  }
  toggleVisibility(key: ApiKey) {
    key.visible = !key.visible;
  }

  maskKey(key: string): string {
    return key.replace(/.(?=.{4})/g, '*');
  }

  onOpenDialogCreateNewApiKey(): void {
    const dialogRef = this.dialog.open(AddKeyDialogComponent, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (!result || !result.name) {
        this.toast.warning('Name is required');
        return;
      }
      await this.createApiKeys({ name: result.name });
      await this.loadApiKeys();

    });
  }

  async createApiKeys({ name }: { name: string }) {
    this.isLoading = true;
    try {
      await this.trpcClient.apiKeys.create.mutate({
        name: name,
        brandId: this.auth.lastSelectedBrandId()
      })
    } catch (err) {
      console.error(err);
      this.toast.error('Failed to create API key');
    } finally {
      this.isLoading = false;
    }

  }

}