import { Component, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { LoadingDirective } from "../../../shared/directives/loading.directive";
import { NgSelectModule } from "@ng-select/ng-select";
import { UserService } from "../../../shared/services/user.service";
import { ToastService } from "../../../shared/services/toast.service";
import { AuthService } from "../../../shared/services/auth.service";

@Component({
  selector: 'app-account-settings-profile',
  standalone: true,
  imports: [
    FormsModule,
    NgSelectModule,
    LoadingDirective,
  ],
  templateUrl: './account-settings-profile.component.html',
  styleUrl: './account-settings-profile.component.css'
})
export class AccountSettingsProfileComponent implements OnInit {
  isLoading: boolean = false;
  user = { name: '', email: '' };

  constructor(private userService: UserService, private toast: ToastService, private auth: AuthService) {
  
  }
  ngOnInit(): void {
    this.initUser();
  }

  initUser() {
    this.user.name = this.auth.user!.name;
    this.user.email = this.auth.user!.email;
  }

  public async onUpdateContactInformation() {
    if (this.isLoading) return;

    try {
      this.isLoading = true;

      const user = await this.userService.updateOne(this.user);
      this.toast.success('Successfully updated user data.');

      this.auth.user = user;
      this.initUser();
    } catch (err) {
      console.error(err);
      this.toast.error(err);
    } finally {
      this.isLoading = false;
    }
  }

}