<section class="sqs-page" [appLoading]="isLoading">
  <div class="page-header">
    <!-- Left side -->
    <div class="title-group">
      <h1>Post Calendar</h1>
      <h6>Timezone: {{ this.auth.currentBrand()?.timezone }}</h6>
    </div>

    <!-- Right side -- week selection -->
    <div class="week-selection-container">
      <button class="sqs-button transparent" (click)="onChangeWeek(-1)">
        <i class="icon">keyboard_arrow_left</i>
      </button>
      <div class="current-date">
        <span>{{ currentlyTrackedDate }}</span>
      </div>
      <button class="sqs-button transparent" (click)="onChangeWeek(1)">
        <i class="icon">keyboard_arrow_right</i>
      </button>
    </div>
  </div>

  <div class="card">
    <div class="card-header-row">
      <!-- Left -- Select viewed period -->
      <div class="period-select">
        <div (click)="onSwitchTabs(TabType.day)" class="tab inactive">
          <span>Day</span>
        </div>
        <div (click)="onSwitchTabs(TabType.week)" class="tab">
          <span>Week</span>
        </div>
      </div>

      <!-- Right -- Filters -->
      <div class="filters">
        <ng-select
          [items]="Options.socialProfiles"
          [multiple]="true"
          [searchable]="false"
          [clearable]="false"
          [clearSearchOnAdd]="false"
          [closeOnSelect]="false"
          placeholder="No account selected"
          class="min-w-[250px] leading-loose"
          [(ngModel)]="selectedSocialProfiles"
          (close)="updateDisplayedData()"
        >
          <ng-template ng-header-tmp>
            <div class="flex items-center gap-2">
              <input
                type="checkbox"
                class="mr-[8px]"
                (change)="toggleSelectAll($event)"
                [checked]="
                  selectedSocialProfiles.length ===
                  Options.socialProfiles.length
                "
              />
              <span>Select all</span>
            </div>
          </ng-template>

          <ng-template ng-multi-label-tmp>
            <span class="block px-2 font-normal">
              @if (
                selectedSocialProfiles.length === Options.socialProfiles.length
              ) {
                All accounts selected
              } @else {
                Selected accounts:
              }
            </span>
          </ng-template>

          <ng-template
            ng-option-tmp
            let-item="item"
            let-item$="item$"
            let-index="index"
          >
            <div class="flex items-center gap-2">
              <input
                id="item-{{ index }}"
                type="checkbox"
                [ngModel]="item$.selected"
                [ngModelOptions]="{ standalone: true }"
                class="mr-[8px]"
              />
              <img
                [src]="'/assets/icons/fa-' + icon(item.type)"
                alt="social profile icon"
                class="h-4 w-4"
              />
              <span>{{ item.data.name }}</span>
            </div>
          </ng-template>
        </ng-select>
      </div>
    </div>

    <div class="calendar">
      <div class="row">
        @for (entry of entries; track entry.date) {
          <div class="entry" [class.today]="today === entry.dateString">
            <span>{{ entry.date | date: "EEE dd MMM" }}</span>
          </div>
        }
      </div>

      <div class="row subheader">
        @for (entry of entries; track entry.date) {
          <div class="entry">
            <button
              class="sqs-button disabled:cursor-not-allowed disabled:opacity-50"
              (click)="onAddNewPost()"
              [disabled]="disabledButton(entry.date)"
            >
              <i class="icon">add</i>
              <span>post</span>
            </button>
            <button
              class="sqs-button disabled:cursor-not-allowed disabled:opacity-50"
              (click)="onAddNewHint(entry)"
              [disabled]="disabledButton(entry.date)"
            >
              <i class="icon">add</i>
              <span>hint</span>
            </button>
          </div>
        }
      </div>

      <div class="row hints">
        @for (entry of entries; track entry.date) {
          <div class="entry">
            @for (hint of entry.hints; track hint.idx) {
              <span
                class="hint"
                (mouseover)="hint.hover = true"
                (mouseout)="hint.hover = false"
                [style.background-color]="hint.data.color"
                (click)="onEditCalendarHint(hint)"
              >
                @if (hint.hover) {
                  <i class="icon outlined" style="font-size: 12px">edit</i> Edit
                } @else {
                  {{ hint.data.text }}
                }
              </span>
            } @empty {
              <span class="hint invalid">No hints</span>
            }
          </div>
        }
      </div>

      <div class="row posts">
        @for (entry of entries; track entry.date) {
          <div class="entry">
            @for (post of entry.data; track post.idx) {
              <app-post-card
                class="w-full"
                [post]="post"
                (postDeleted)="updateDisplayedData()"
                (postEdited)="onEditPost(post.id)"
                (postCopied)="onCopyPost(post.id)"
              >
              </app-post-card>
            } @empty {
              <div class="post-card empty">No posts</div>
            }
          </div>
        } @empty {
          <div class="entry faded">No posts</div>
        }
      </div>
    </div>
  </div>
</section>
