<section class="media-library-page" [appLoading]="isLoading">
  <div class="left">
    <h1>Media Library</h1>

    @if (tree) {
      <div class="tree">
        <app-tree-node [node]="tree"
                       [activeNode]="activeNode"
                       (activeNodeChange)="onActiveNodeChanged($event)"
                       (onFolderSettings)="onFolderActions()"
        ></app-tree-node>
      </div>
    }
  </div>

  <div class="middle" [class.shrink]="selectedUpload">
    <app-search-bar [search]="search.value"
                    (searchChanged)="onSearch($event)"
    ></app-search-bar>

    <div class="upload-form">
      <app-upload [files]="uploadFormFiles" (fileInput)="onFileInput($event)"></app-upload>
    </div>

    <div class="upload-submit-row" [class.hidden]="!files.length">
      <button class="btn-primary"
              (click)="onSubmitUpload()"
      >Upload</button>
    </div>

    <div class="path">
      <i class="icon filled">home</i>
      <i class="icon filled">chevron_right</i>

      @for (entry of currentPathParts; track entry.idx) {
        <span>{{ entry.value }}</span>
        <i class="icon filled">chevron_right</i>
      }
    </div>

    @if (search.type === 'crt') {
      <div class="folders">

        @if (activeNode?.children?.length) {

          @for (child of activeNode!.children; track child.name) {

            @if (child.children != null) {
              <div class="folder" (click)="onActiveNodeChanged(child)">
                <i class="icon">folder</i>
                <span>{{ child.name }}</span>
              </div>
            }
          }
        }
      </div>
    }

    <div class="folder-content">
      @for (upload of uploads; track upload._id) {
        <app-media-tile [upload]="upload"
                        [selected]="selectedUpload?._id === upload._id"
                        (click)="onSelectedTile(upload)"
        ></app-media-tile>
      }
    </div>
  </div>

  <div class="right" [class.expanded]="selectedUpload" [appLoading]="isFileUpdating">
    @if (selectedUpload) {
      <button class="btn-primary main" (click)="onUseInPost()">Use in post</button>

      <div class="preview">
        @if (selectedUpload.type === UploadType.image) {
          <img [src]="selectedUpload.url" [alt]="selectedUpload.filename" />
        }

        @if (selectedUpload.type === UploadType.video) {
          <video controls preload="metadata">
            <source [src]="selectedUpload.url" [type]="selectedUpload.mimetype">
          </video>
        }
      </div>

      <div class="actions">
        <button class="btn-primary danger" matTooltip="Delete file" (click)="onDeleteFile()">
          <i class="icon outlined">delete</i>
        </button>

        <div class="group">
          <ng-select [multiple]="false"
                     placeholder="Move to folder"
                     [searchable]="false"
                     [clearable]="false"
                     [(ngModel)]="newFolderPath"
          >
            @for (path of allFolderPaths; track path) {
              <ng-option [value]="path">{{ path }}</ng-option>
            }
          </ng-select>

          <button class="btn-primary" [class.faded]="!newFolderPath?.length"
                  matTooltip="Move file"
                  (click)="onMoveFile()"
          >
            <i class="icon outlined">exit_to_app</i>
          </button>
        </div>
      </div>

      <div class="form-group">
        <label>Suggested Post Text</label>
        <textarea [(ngModel)]="selectedUpload.postSuggestedText"></textarea>
        <button class="btn-primary empty" (click)="onUpdateFileData()">
          <i class="icon outlined">save</i>
          <span>Save suggested text</span>
        </button>
      </div>


      <div class="form-group">
        <label>File notes</label>
        <textarea [(ngModel)]="selectedUpload.notes"></textarea>
        <button class="btn-primary empty" (click)="onUpdateFileData()">
          <i class="icon outlined">save</i>
          <span>Save notes</span>
        </button>
      </div>

      <div class="info">
        <b>File name</b>
        <span><u>{{ selectedUpload!.filename }}</u></span>
      </div>

      <div class="info">
        <b>Saved in</b>
        <span><u>{{ savedIn }}</u></span>
      </div>

      <div class="info">
        <b>Uploaded</b>
        <span>{{ selectedUpload.createdAt | date: 'dd/MM/yyyy' }}</span>
      </div>

      <div class="info">
        <b>Size</b>
        <span>{{ size(selectedUpload.sizeBytes) }}</span>
      </div>

      <div class="info">
        <b>Width</b>
        <span>{{ selectedUpload.widthPx }}px</span>
      </div>

      <div class="info">
        <b>Height</b>
        <span>{{ selectedUpload.heightPx }}px</span>
      </div>

    }
  </div>
</section>
