import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appLoading]',
  standalone: true
})
export class LoadingDirective {

  htmlElement;

  constructor(private renderer: Renderer2, hostElement: ElementRef) {
    this.htmlElement = hostElement.nativeElement;
  }

  @Input()
  set appLoading(display: boolean) {
    if (display) {
      this.renderer.addClass(this.htmlElement, 'is-loading');
    } else {
      this.renderer.removeClass(this.htmlElement, 'is-loading');
    }
  }
}
