<div class="card" [appLoading]="isLoading">
  <div class="mb-6 flex items-center justify-between">
    <h2 class="text-xl">Brand invitations</h2>
  </div>

  @if (this.userBrandInvitations.length === 0) {
    <div class="text-center">
      <p>You have no brand invitations.</p>
    </div>
  } @else {
    <table
      mat-table
      [dataSource]="userBrandInvitations"
      class="mat-elevation-z0"
    >
      <ng-container matColumnDef="brandName">
        <th mat-header-cell *matHeaderCellDef>Brand</th>
        <td mat-cell *matCellDef="let element">{{ element.brandName }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">
          <span
            [ngClass]="{
              'text-green-500':
                element.status === BrandInvitationStatus.ACCEPTED,
              'text-red-500': element.status === BrandInvitationStatus.REJECTED,
              'text-yellow-500':
                element.status === BrandInvitationStatus.PENDING,
            }"
          >
            {{ element.status }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>From</th>
        <td mat-cell *matCellDef="let element">
          {{ element.createdAt | date: "mediumDate" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element">
          <div class="flex items-center gap-2">
            <button
              class="disabled:cursor-not-allowed disabled:opacity-50"
              mat-button
              matTooltip="Reject invitation"
              matTooltipPosition="above"
              [disabled]="element.status !== BrandInvitationStatus.PENDING"
              (click)="
                handleRejectInvitation(element.brandId, element.invitationId)
              "
            >
              <mat-icon
                aria-label="reject invitation"
                class="text-red-500 hover:text-red-700"
                >cancel</mat-icon
              >
            </button>
            <button
              class="disabled:cursor-not-allowed disabled:opacity-50"
              mat-button
              matTooltip="Accept invitation"
              matTooltipPosition="above"
              [disabled]="element.status !== BrandInvitationStatus.PENDING"
              (click)="
                handleAcceptInvitation(element.brandId, element.invitationId)
              "
            >
              <mat-icon
                aria-label="accept invitation"
                class="text-green-500 hover:text-green-700"
                >check_circle</mat-icon
              >
            </button>
          </div>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns"
        class="!text-lg"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  }
</div>
