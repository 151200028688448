import { Component, Input, OnInit, input } from '@angular/core';
@Component({
    selector: 'app-social-media-skeleton-loading',
    standalone: true,
    imports: [],
    templateUrl: './social-media-skeleton-loading.component.html',
    styleUrl: './social-media-skeleton-loading.component.scss',
})
export class SocialMediaSkeletonLoading implements OnInit {

    @Input() itemsCount: number;

    loadingItems: unknown[] = [];

    ngOnInit() {
        this.loadingItems = Array.from({ length: this.itemsCount }, (_, index) => index);
    }


}