<section class="gallery-dialog">
  <div class="header">
    <i class="icon outlined"
       matTooltip="Cancel"
       (click)="dialogRef.close(null)"
    >close</i>
  </div>

  <div class="content">
    <app-media-library
      [isUsedInDialog]="true"
      (uploadSelected)="dialogRef.close($event)"
    ></app-media-library>
  </div>
</section>
