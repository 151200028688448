<nav class="sidenav-component" [class.expanded]="isExpanded">
  <button
    class="expansion-toggle-btn"
    style="z-index: 150"
    mat-icon-button
    (click)="onToggleSidenav()"
  >
    <i class="icon outlined">navigate_{{ isExpanded ? "before" : "next" }}</i>
  </button>

  <div class="links">
    @for (link of links; track link.id) {
      <button
        class="link-btn"
        mat-flat-button
        [matTooltip]="isExpanded ? '' : link.title"
        matTooltipPosition="right"
        [class.current-page]="isCurrentPage(link.page)"
        (click)="onNavigateTo(link)"
      >
        <div class="link-container">
          <i class="icon outlined">{{ link.icon }}</i>

          @if (isExpanded) {
            <span class="name">{{ link.title }}</span>
          }
        </div>
      </button>
    }
  </div>
</nav>
