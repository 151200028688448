import { Component, Inject } from '@angular/core';
import { AuthService } from '../../../shared/services/auth.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../../../shared/models/misc';
import { ToastService } from '../../../shared/services/toast.service';
import { CalendarService } from '../../../shared/services/calendar.service';
import { LoadingDirective } from '../../../shared/directives/loading.directive';
import { FormsModule } from '@angular/forms';
import { ColorPickerModule, ColorPickerService } from 'ngx-color-picker';
import { DateUtils } from '../../../shared/utils/date.utils';
import { MatTooltip } from '@angular/material/tooltip';
import { ConfirmationService } from '../../../shared/services/confirmation.service';
import { BrandService } from '../../../shared/services/brand.service';

@Component({
  selector: 'app-hint-form',
  standalone: true,
  imports: [
    LoadingDirective,
    FormsModule,
    ColorPickerModule,
    MatTooltip,
  ],
  providers: [ColorPickerService],
  templateUrl: './hint-form.component.html',
  styleUrl: './hint-form.component.scss'
})
export class HintFormComponent {

  isLoading = false;
  pageAction: 'add' | 'edit';

  text: string = '';
  startDate: string = ''; // YYYY-MM-DD representation of start date
  endDate: string = ''; // YYYY-MM-DD representation of end date
  color: string = _generateRandomColor(); // HEX string of color
  calendarHintId?: string; // Set on edit mode

  constructor(
    public dialogRef: MatDialogRef<HintFormComponent>,
    @Inject(MAT_DIALOG_DATA) data: DialogData.CalendarHintForm,
    protected auth: AuthService,
    protected toast: ToastService,
    protected calendarService: CalendarService,
    protected confirm: ConfirmationService,
    protected brandService: BrandService,
  ) {
    this.pageAction = data.action;

    if (this.pageAction === 'add') {
      // Adding new calendar hint
      this.startDate = this.endDate = data.date;
    }

    else {
      // Editing existing calendar hint
      this.calendarHintId = data.hint!._id;
      this.text = data.hint!.text;
      this.startDate = DateUtils.formatDate(new Date(data.hint!.startDate));
      this.endDate = DateUtils.formatDate(new Date(data.hint!.endDate));
      this.color = data.hint!.color;
    }
  }

  public async onSubmit() {
    if (this.isLoading) return;

    try {
      this.isLoading = true;

      const payload = this.validateData();

      const result = (
        this.pageAction === 'add'
          ? await this.calendarService.createHint(this.auth.lastSelectedBrandId(), payload)
          : await this.calendarService.updateHint(this.auth.lastSelectedBrandId(), this.calendarHintId!, payload)
      );

      this.toast.success(`Successfully ${this.pageAction}ed the hint.`);

      return this.dialogRef.close(result);
    } catch (err) {
      console.error(err);
      this.toast.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  public async onDeleteHint() {
    if (this.isLoading) return;
    if (this.pageAction !== 'edit') return;

    this.confirm.confirm({
      title: 'Delete Hint',
      message: 'You are about to delete this calendar hint. It will be removed from all days between start and end. This action cannot be undone. Do you proceed?',
      noBtnText: 'Cancel',
      yesBtnText: 'Yes, Delete',
      onSuccess: async () => {
        try {
          this.isLoading = true;

          const result = await this.calendarService.deleteHint(this.auth.lastSelectedBrandId(), this.calendarHintId!);
          this.toast.success('Hint successfully deleted.');
          return this.dialogRef.close(result);
        } catch (err) {
          console.error(err);
          this.toast.error(err);
        } finally {
          this.isLoading = false;
        }
      }
    })
  }

  private validateData() {
    if (!this.text.length) throw new Error('Hint text cannot be empty.');

    console.log(this.startDate, this.endDate);
    [this.startDate, this.endDate].forEach((value, idx) => {
      const hasValidFormat = /\d{4}-\d{2}-\d{2}/.test(value);
      if (!hasValidFormat) throw new Error(`Invalid ${['start', 'end'][idx]} date (1)`);

      const parts = value.split('-');
      if (parts.length !== 3) throw new Error(`Invalid ${['start', 'end'][idx]} date (2)`);

      const [yyyy, mm, dd] = parts.map(Number);


      const validations = [
        !isNaN(yyyy), !isNaN(mm), !isNaN(dd),
        2024 <= yyyy, yyyy <= 2100,
        1 <= mm && mm <= 12,
        1 <= dd && dd <= 31,
      ];

      if (validations.some((val) => !val)) {
        throw new Error(`Invalid ${['start', 'end'][idx]} date (3)`);
      }
    })

    if (DateUtils.isAfter(this.startDate, this.endDate)) throw new Error(`Invalid dates`);

    return {
      text: this.text,
      color: this.color,
      startDate: new Date(this.startDate),
      endDate: new Date(this.endDate),
    };
  }
}

function _generateRandomColor() {
  const rand = () => {
    const value = Math.floor(Math.random() * 16);
    if (value < 10) return value;
    return { 10: 'A', 11: 'B', 12: 'C', 13: 'D', 14: 'E', 15: 'F' }[value];
  };
  const color = `#${rand()}${rand()}${rand()}${rand()}${rand()}${rand()}`

  console.log(color);
  return color;
}
