import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import { take } from 'rxjs';

interface ConfirmParams {
  onSuccess: () => void;
  onDismiss?: () => void;
  message?: string;
  isRawMessage?: boolean;
  title?: string;
  yesBtnText?: string;
  noBtnText?: string;
  yesBtnColor?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {

  constructor(protected dialog: MatDialog) { }

  confirm({ onSuccess, onDismiss, message, isRawMessage = false, title, yesBtnText, noBtnText, yesBtnColor }: ConfirmParams) {
    const dialogRef = this.dialog
      .open(ConfirmationDialogComponent, {
        autoFocus: false,
        panelClass: 'confirmation-dialog-component',
        data: {
          ...(message?.length && { message }),
          ...(title?.length && { title }),
          ...(yesBtnText?.length && { yesBtnText }),
          ...(noBtnText?.length && { noBtnText }),
          ...(yesBtnColor?.length && { yesBtnColor }),
          isRawMessage,
        }
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (resp) => {
        if (resp) onSuccess();
        else if (onDismiss) onDismiss();
      });
  }
}
