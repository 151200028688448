import { HttpClient } from '@angular/common/http';
import { Injectable, computed, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom, mapTo } from 'rxjs';
import { mapToUser, User, UserStatus } from '../models/user';
import { GeneralUtils } from '../utils/general.utils';
import { BrandService } from './brand.service';
import { LocalStorage, SessionStorage, Storage } from '../utils/storage.utils';
import { UserService } from './user.service';

export const injectAuthService = () => inject(AuthService);

export const authToken = signal<string | null>(null);
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private endpoint = 'auth';
  public storage: Storage;

  private _user = signal<User | null>(null);
  public get user() {
    return this._user();
  }
  public set user(user: User | null) {
    this._user.set(user);
  }

  lastSelectedBrandId = computed(() => {
    return this.user?.lastSelectedBrandId ?? '';
  })

  userEmail = computed(() => {
    return this.user?.email ?? '';
  })

  isOwnerOfCurrentBrand = computed(() => {
    if (!this.user) {
      return false;
    }
    const checkForOwner = this.user._id === this.brandService._brandsOfCurrentUser().find(brand => brand.id === this.user!.lastSelectedBrandId)?.userId;
    return checkForOwner;
  })

  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected brandService: BrandService,
    protected userService: UserService
  ) {
    this.initAuth();
  }

  async initAuth() {

    if (localStorage.getItem('persistentSession') === 'true') {
      this.storage = new LocalStorage();
    }
    else {
      this.storage = new SessionStorage();
    }

    await this.loadStorageData();
    if (authToken() !== null) {
      await this.brandService.loadBrandsOfCurrentUser();
    }
  }

  public async refetchCurrentUser() {
    const currentUser = await this.userService.getCurrentUser();
    this.user = mapToUser(currentUser);
  }

  public async updateUserSelectedBrand(brandId: string) {
    await this.userService.updateUserSelectedBrand(brandId);
    this.user = {
      ...this.user!,
      lastSelectedBrandId: brandId
    };
    this.storage.setItem(this.userKey, JSON.stringify(this.user));
  }

  public currentBrand = computed(() => {
    return this.brandService.brandsOfCurrentUser.find(
      (brand) => brand.id === this.user?.lastSelectedBrandId,
    );
  });

  public async login(
    email: string,
    password: string,
    isPersistentSession: boolean,
  ) {
    if (!email.length || !password.length)
      throw new Error('Invalid credentials');

    const { user, token } = await lastValueFrom(
      this.http.post<{ user: User; token: string }>(this.endpoint, {
        email,
        password,
      }),
    );

    if (isPersistentSession) {
      localStorage.setItem('persistentSession', 'true');
      this.storage = new LocalStorage();
    }

    this.handleAuthentication({ user, token });
    await this.brandService.loadBrandsOfCurrentUser();

    return user;
  }

  public async register(data: RegisterArg) {
    const { user, token } = await lastValueFrom(
      this.http.post<{ user: User; token: string }>(
        `${this.endpoint}/register`,
        data,
      ),
    );

    this.handleAuthentication({ user, token });
    await this.brandService.loadBrandsOfCurrentUser();

    return user;
  }

  public async logout() {
    await lastValueFrom(this.http.delete<{}>(this.endpoint));

    return this.clearAuthData();
  }

  public async clearAuthData() {
    this.storage.clear();

    authToken.set(null);
    this._user.set(null);

    // Redirect to login page
    await this.router.navigate(['/login']);
  }

  authTokenKey = "authToken"
  userKey = "user"

  private async loadStorageData() {

    const token: string | null = this.storage.getItem(this.authTokenKey);
    const jsonUser: string | null = this.storage.getItem(this.userKey);
    const user = GeneralUtils.parseJson<User>(jsonUser);

    this.handleAuthentication({ token, user });
  }

  private handleAuthentication(
    data: { token: string | null; user: User | null }
  ) {
    const { token, user } = data;

    if (token !== null) {
      this.storage.setItem(this.authTokenKey, token);
    }

    if (user !== null) {
      this.storage.setItem(this.userKey, JSON.stringify(user));
    }

    // Update signals
    authToken.set(token);
    this._user.set(user);
  }
}

type RegisterArg = {
  brandName: string;
  timezone: string;
  name: string;
  email: string;
  password: string;
};
