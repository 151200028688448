<section class="sqs-page" [appLoading]="isLoading">
  <h1>Post Groups</h1>

  <div class="content">
    <ul class="groups-list">
      @for (group of groups; track group.id) {
        <li
          class="group"
          [class.selected]="isSelected(group)"
          (click)="onSelectedPostGroup(group)"
        >
          <span>{{ group.name }}</span>

          @if (isSelected(group)) {
            <i class="label">
              {{ group.approvedPostOrder.length }} of
              {{ group.postCount }} approved
            </i>
          }
        </li>
      }

      <li class="add-btn" (click)="onAddNewGroup()">
        <i class="icon outlined">add</i>
        <span>Add Group</span>
      </li>
    </ul>

    <div class="group-panel">
      <div class="upper">
        <div class="actions">
          <button class="sqs-button success" (click)="onPostGroupEdit()">
            Profiles & Repetition
          </button>
          <button
            class="sqs-button success"
            (click)="onPostGroupScheduleEdit()"
          >
            @if (isPostGroupScheduled) {
              <i class="icon outlined">check</i>
            }
            <span>Schedule</span>
          </button>
        </div>
        <hr class="separator" />

        @if (!isPostGroupScheduled || !hasSocialAccounts) {
          <div class="note">
            <span
              >Posts won't send until you have selected a profile and a schedule
              has been set.</span
            >
          </div>
        } @else {
          <div class="schedules">
            On <span>{{ scheduleDays }}</span> at
            @for (entry of formattedScheduleTimes; track entry) {
              <span>{{ entry }}</span>
            }

            <div class="mt-4 flex flex-wrap items-center gap-2">
              <div>send to</div>
              @for (
                socialProfile of selectedSocialProfiles;
                track socialProfile.id
              ) {
                <div class="flex max-w-fit rounded bg-slate-300 px-2 py-1">
                  <social-profile-item
                    [profile]="socialProfile"
                  ></social-profile-item>
                </div>
              }
            </div>
          </div>
        }
      </div>

      <div class="lower">
        <div class="tabs">
          <div
            class="tab-label-container"
            (click)="changeStatusFilter(Status.draft)"
            [class.selected]="statusFilter === Status.draft"
          >
            <i class="label">{{ posts.draft.length }}</i>
            <span>Drafts</span>
          </div>

          <div
            class="tab-label-container"
            (click)="changeStatusFilter(Status.approved)"
            [class.selected]="statusFilter === Status.approved"
          >
            <i class="label">{{ posts.approved.length }}</i>
            <span>Approved</span>
          </div>

          <div
            class="tab-label-container"
            (click)="changeStatusFilter(Status.finishedSending)"
            [class.selected]="statusFilter === Status.finishedSending"
          >
            <i class="label">{{ posts.finishedSending.length }}</i>
            <span>Finished Sending</span>
          </div>
        </div>

        <div
          cdkDropList
          cdkDropListOrientation="mixed"
          class="tab-content flex flex-row flex-wrap gap-4"
          (cdkDropListDropped)="changeOrderOfPosts($event)"
        >
          @switch (statusFilter) {
            <!-- "Draft" tab content -->
            @case (Status.draft) {
              @for (post of currentPosts; track post.id) {
                <app-post-card
                  [post]="post"
                  (postDeleted)="onUpdatePosts()"
                  (postUpdated)="onUpdatePosts()"
                  (postEdited)="onEditPost(post.id)"
                  (postCopied)="onCopyPost(post.id)"
                ></app-post-card>
              } @empty {
                <button
                  class="sqs-button success new"
                  (click)="onOpenPostForm(Status.draft)"
                >
                  Create a new post
                </button>
              }
            }

            <!-- "Approved" tab content -->
            @case (Status.approved) {
              @if (selectedGroup) {
                @for (
                  postOrder of selectedGroup.approvedPostOrder;
                  track postOrder.postId;
                  let index = $index
                ) {
                  @if (currentPostsGroupedById[postOrder.postId]) {
                    <app-post-card
                      [position]="index + 1"
                      [post]="currentPostsGroupedById[postOrder.postId]"
                      (postDeleted)="onUpdatePosts()"
                      (postUpdated)="onUpdatePosts()"
                      (postEdited)="onEditPost(postOrder.postId)"
                      (postCopied)="onCopyPost(postOrder.postId)"
                      cdkDrag
                      [cdkDragData]="currentPostsGroupedById[postOrder.postId]"
                      class="cursor-grab"
                    ></app-post-card>
                  }
                }
              }
            }

            <!-- "Finished sending" tab content -->
            @case (Status.finishedSending) {
              @for (post of currentPosts; track post.id) {
                <app-post-card
                  [post]="post"
                  (postCopied)="onCopyPost(post.id)"
                  (postRescheduledUsingAI)="onReschedulePostUsingAI(post.id)"
                ></app-post-card>
              } @empty {
                <h3>No Posts from this group have been sent yet.</h3>
              }
            }
          }
        </div>
      </div>
    </div>
  </div>
</section>
