import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UploadFunctionOutputEvent, UploadFunctionOutputFileObject } from '../../models/misc';
import { ToastService } from '../../services/toast.service';
import { GeneralUtils } from '../../utils/general.utils';
import { MatRipple } from '@angular/material/core';
import { MatTooltip } from '@angular/material/tooltip';
import { LoadingDirective } from '../../directives/loading.directive';
import { DragDropDirective } from '../../directives/drag-drop.directive';
import { ALLOWED_FILE_MIMETYPES } from '../../models/upload';
import { resolve } from 'node:dns';

const MAX_FILESIZE_BYTES = 30_000_000; // 30 MB
const GALLERY_ICON_SRC = '/assets/icons/gallery.png';

@Component({
  selector: 'app-upload',
  standalone: true,
  imports: [
    MatRipple,
    MatTooltip,
    LoadingDirective,
    DragDropDirective,
  ],
  templateUrl: './upload.component.html',
  styleUrl: './upload.component.scss'
})
export class UploadComponent implements OnChanges {

  @Input()
  files: File[] = [];

  @Input()
  message?: string;

  @Input()
  accept = '*';

  @Input()
  allowUploadCancel = true;

  @Output()
  fileInput = new EventEmitter<UploadFunctionOutputEvent>();

  picture: string | null = null;

  // UI Utils
  isLoading = false;

  constructor(protected toast: ToastService) { }

  ngOnChanges(changes: SimpleChanges): void {
    const file = changes['file']?.currentValue || null;

    if (file == null) this.onCancel();
  }

  public get fileNames() {
    return (this.files || []).length + ' files';
  }

  public onFilesSelected(event: Event | FileList) {

    if (GeneralUtils.isFileList(event)) {
      return this._onFilesSelected([...event as any].filter((file) => file != null));
    }

    if (!GeneralUtils.isEvent(event)) {
      throw new Error('Invalid file event');
    }

    const files = (() => {
      if (!event.target) return [];

      const target = event.target as HTMLInputElement;
      if (!target?.files?.length) return [];

      return [...target.files as any];
    })();

    return this._onFilesSelected(files);
  }

  private async getFileDimensions(file: File): Promise<null | [number, number]> {
    // Validate and get dimensions if valid
    return await new Promise<null | [number, number]>((resolve, reject) => {

      const _cancel = (ev: any) => reject(null);

      const reader = new FileReader();
      reader.onabort = _cancel;
      reader.onerror = _cancel;
      reader.onload = (ev) => {
        if (!file) {
          return _cancel(ev);
        }

        const blobURL = URL.createObjectURL(file);

        if (file.type.startsWith('image/')) {
          const img = new Image();
          img.onload = () => {
            URL.revokeObjectURL(blobURL);
            resolve([img.width, img.height]);
          };
          img.onerror = _cancel;
          img.onabort = _cancel;
          img.src = blobURL;
        } else if (file.type.startsWith('video/')) {
          const video = document.createElement('video');
          video.onloadedmetadata = () => {
            URL.revokeObjectURL(blobURL);
            resolve([video.videoWidth, video.videoHeight]);
            video.remove();
          };
          video.onerror = _cancel;
          video.onabort = _cancel;
          video.src = blobURL;
        } else {
          // Unsupported file type
          _cancel(ev);
        }
      };
      reader.readAsDataURL(file);
    });
  }

  private async _onFilesSelected(files: File[]) {
    if (this.isLoading) return;

    this.isLoading = true;

    // Process each file
    const _outFiles: UploadFunctionOutputFileObject[] = [];
    const _files: File[] = [];
    this.picture = GALLERY_ICON_SRC;

    await Promise.all(files.map(
      async (file) => {
        const allowedMimeType = ALLOWED_FILE_MIMETYPES.find(({ mimetype }) => mimetype.includes(file?.type || ''));
        if (!allowedMimeType) {
          this.toast.error(`File type "${file?.type}" not allowed`);
          return;
        }

        const fileSize = file?.size || 0;

        if (fileSize > MAX_FILESIZE_BYTES) {
          this.toast.error('Maximum file size is 30 MB. Please split your file in multiple files.');
          return;
        }

        // Get dimensions
        const dimensions = await this.getFileDimensions(file);

        if (dimensions === null) {
          return;
        } // Skip file -- invalid

        const [widthPx, heightPx] = dimensions;

        // Validated, store file
        _files.push(file);
        _outFiles.push({ file, picture: null, widthPx, heightPx });
      }
    ));

    this.files = _files;
    this.fileInput.emit({ files: _outFiles });

    this.isLoading = false;
  }

  public onCancel(event?: Event) {
    event?.stopPropagation();
    event?.preventDefault();

    this.files = [];
    this.picture = null;

    this.fileInput.emit({ files: [] });
  }
}
