import { Component, effect, OnDestroy, OnInit } from '@angular/core';
import { PostGroup, PostStatus } from '../../shared/models/post-group';
import { PostGroupService } from '../../shared/services/post-group.service';
import { ToastService } from '../../shared/services/toast.service';
import { AuthService } from '../../shared/services/auth.service';
import { LoadingDirective } from '../../shared/directives/loading.directive';
import { MatDialog } from '@angular/material/dialog';
import { DialogData } from '../../shared/models/misc';
import { PostGroupFormDialogComponent } from './post-group-form-dialog/post-group-form-dialog.component';
import { map, Subscription, take, tap } from 'rxjs';
import { MatTab, MatTabGroup, MatTabLabel } from '@angular/material/tabs';
import { Post } from '../../shared/models/post';
import { PostCardComponent } from './post-card/post-card.component';
import { MatTooltip } from '@angular/material/tooltip';
import { NgSelectModule } from '@ng-select/ng-select';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { FormsModule } from '@angular/forms';
import { PostFormDialogComponent } from './post-form-dialog/post-form-dialog.component';
import { PostService } from '../../shared/services/post.service';
import { PostGroupScheduleDialogComponent } from './post-group-schedule-dialog/post-group-schedule-dialog.component';
import { ZeroPadPipe } from '../../shared/pipes/zero-pad.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralUtils } from '../../shared/utils/general.utils';
import { SocialProfile } from '../../shared/models/social-profile';
import { SocialProfileService } from '../../shared/services/social-profile.service';
import { SocialProfileItemComponent } from './post-group-form-dialog/components';
import { CreatePostUiService } from '../../shared/services/create-post-ui.service';
import { UpdatePostFormDialogComponent } from './post-form-dialog/update-post-form-dialog/update-post-form-dialog.component';
import { CdkDragDrop, CdkDrag, CdkDropList, moveItemInArray, DragDropModule } from '@angular/cdk/drag-drop';
import { BrandService } from '../../shared/services/brand.service';
import { ChatGPTService } from '../../shared/services/chatgpt.service';

@Component({
  selector: 'app-planner',
  standalone: true,
  imports: [
    LoadingDirective,
    MatTabGroup,
    MatTab,
    MatTabLabel,
    PostCardComponent,
    MatTooltip,
    NgSelectModule,
    PickerModule,
    FormsModule,
    SocialProfileItemComponent,
    CdkDropList,
    CdkDrag,
    DragDropModule

  ],
  templateUrl: './planner.component.html',
  styleUrl: './planner.component.scss',
})
export class PlannerComponent implements OnInit, OnDestroy {
  groups: PostGroup[] = [];
  selectedGroup: null | PostGroup = null;

  // Visible posts (by status)
  statusFilter: PostStatus = PostStatus.draft;
  readonly Status = PostStatus;

  posts: FilteredPosts = {
    [PostStatus.draft]: [],
    [PostStatus.approved]: [],
    [PostStatus.finishedSending]: [],
    [PostStatus.scheduled]: [],
  };

  dataLoaded = false;
  isLoading = false;
  subscriptions: Subscription[] = [];
  selectedSocialProfiles: SocialProfile[] = [];

  constructor(
    protected postGroupService: PostGroupService,
    protected postService: PostService,
    protected auth: AuthService,
    protected toast: ToastService,
    protected dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    protected socialProfileService: SocialProfileService,
    protected createPostUi: CreatePostUiService,
    protected brandService: BrandService,
    protected chatGPTService: ChatGPTService
  ) {
    effect(async () => {
      if (this.auth.lastSelectedBrandId()) {
        const initialGroupId = this.route.snapshot.queryParams['groupId'] as
          | string
          | undefined;
        await this.loadData(initialGroupId);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => sub.unsubscribe());
    }
  }

  public async ngOnInit() {

    this.subscriptions.push(
      this.route.queryParamMap
        .pipe(map((queryParams) => queryParams.get('groupId')))
        .subscribe(async (groupId) => {
          if (!groupId) return;
          await this.fetchPostsForSelectedGroup(groupId);
        })
    );

    this.subscriptions.push(
      this.route.queryParamMap
        .pipe(map((queryParams) => queryParams.get('statusFilter')))
        .subscribe((status) => {
          if (!status) return;
          this.statusFilter = status as PostStatus;
        })
    );

    this.subscriptions.push(
      this.createPostUi.eventCreated.subscribe(async () => {
        if (!this.selectedGroup) return;
        this.onOpenPostForm(PostStatus.draft);
      })
    );
  }

  public changeStatusFilter(status: PostStatus) {
    this.router.navigate([], {
      queryParams: { statusFilter: status },
      queryParamsHandling: 'merge',
    });
  }

  public get currentPosts() {
    return this.posts[this.statusFilter];
  }
  public get currentPostsGroupedById() {
    return this.currentPosts.reduce((acc, post) => {
      acc[post.id] = post;
      return acc;
    }, {} as Record<string, Post>);
  }

  public get isPostGroupScheduled() {
    if (!this.selectedGroup) return false;

    const hasDays = !!this.selectedGroup.scheduleDays.length;
    const hasTimes = !!this.selectedGroup.scheduleTimes.length;

    return hasDays && hasTimes;
  }

  public get hasSocialAccounts() {
    if (!this.selectedGroup) return false;
    return this.selectedGroup.selectedSocialProfileIds.length > 0;
  }

  public get scheduleDays() {
    if (!this.selectedGroup) return '';
    const groupedDAYS = GeneralUtils.groupByUnique(
      PostGroupScheduleDialogComponent.DAYS,
      'value'
    );
    return this.selectedGroup.scheduleDays
      .map((i) => groupedDAYS[i].label)
      .join(', ');
  }

  public get formattedScheduleTimes() {
    const pad = (num: string | number) => ZeroPadPipe.transform(Number(num));
    if (!this.selectedGroup) return [];

    return this.selectedGroup.scheduleTimes.map(({ hh: h, mm }) => {
      if (h === 0) return `12:${pad(mm)} AM`;
      if (h < 12) return `${pad(h)}:${pad(mm)} AM`;
      if (h === 12) return `12:${pad(mm)} PM`;

      return `${pad(Number(h) % 12)}:${pad(mm)} PM`;
    });
  }

  public isSelected(group: PostGroup) {
    return group.id === this.selectedGroup?.id;
  }

  public onAddNewGroup() {
    if (this.isLoading) return;

    this.dialog
      .open<any, DialogData.PostGroupForm>(PostGroupFormDialogComponent, {
        autoFocus: false,
        disableClose: true,
        data: { action: 'add', data: null },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (result: null | PostGroup) => {
        if (!result) return;
        return this.loadData(result.id);
      });
  }

  public onPostGroupEdit() {
    if (this.isLoading) return;
    if (!this.selectedGroup) return;

    this.dialog
      .open<any, DialogData.PostGroupForm>(PostGroupFormDialogComponent, {
        autoFocus: false,
        disableClose: true,
        data: { action: 'edit', data: GeneralUtils.deepCopy(this.selectedGroup) },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (result: null | PostGroup) => {
        if (!result) {
          this.fetchAllPostGroups();
          return;

        };
        return this.loadData(result.id);
      });
  }

  public onOpenPostForm(status: PostStatus) {
    if (this.isLoading) return;

    this.dialog
      .open<any, DialogData.PostFormDialog>(PostFormDialogComponent, {
        autoFocus: false,
        disableClose: true,
        panelClass: 'post-form-overlay-dialog',
        data: {
          groups: this.groups,
          postGroupId: this.selectedGroup?.id,
          status,
          type: 'postGroup',
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (result: null | Post) => {
        if (!result) return;
        if (!this.selectedGroup) return;
        await this.fetchSelectedPostGroup();
        await this.fetchAllPostGroups();

        return this.fetchPostsForSelectedGroup(this.selectedGroup.id);

      });
  }

  async onUpdatePosts() {
    if (!this.selectedGroup) return;
    await this.fetchPostsForSelectedGroup(this.selectedGroup.id);
    await this.fetchSelectedPostGroup();
    await this.fetchAllPostGroups();
  }

  async onEditPost(postId: string) {
    if (this.isLoading) return;
    this.dialog
      .open<any, string>(UpdatePostFormDialogComponent, {
        autoFocus: false,
        disableClose: true,
        panelClass: 'post-form-overlay-dialog',
        data: postId,
      }).afterClosed()
      .pipe(take(1))
      .subscribe(async (result: null | Post) => {
        if (!result) return;
        if (!this.selectedGroup) return;
        await this.fetchSelectedPostGroup();
        await this.fetchAllPostGroups();

        return this.fetchPostsForSelectedGroup(this.selectedGroup.id);
      });
  }

  async onCopyPost(postId: string) {
    if (this.isLoading) return;
    try {
      this.isLoading = true;
      await this.postService.copyOne(postId);
      await this.onUpdatePosts();
      this.toast.success('Post copied successfully');
    } catch (err) {
      console.error(err);
      this.toast.error(err);
    } finally {
      this.isLoading = false;
    }
  }
  async onReschedulePostUsingAI(postId: string) {
    if (this.isLoading) return;
    const isChatGPTConnected = await this.chatGPTService.checkUserChatGPTConnection(this.auth.lastSelectedBrandId());
    if (!isChatGPTConnected) {
      this.toast.warning('Please add your ChatGPT key in your account settings');
      return;
    }
    try {
      this.isLoading = true;
      await this.chatGPTService.reschedulePost(postId);
      await this.onUpdatePosts();
      this.toast.success('Post rescheduled using AI successfully');
    } catch (err) {
      console.error(err);
      this.toast.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  async fetchSelectedPostGroup() {
    if (!this.selectedGroup) return;
    try {
      this.isLoading = true;
      this.selectedGroup = await this.postGroupService.findOne(
        this.selectedGroup.id
      );
    } catch (err) {
      console.error(err);
      this.toast.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  async fetchPostsForSelectedGroup(selectedGroupId: string) {
    try {
      this.isLoading = true;
      this.posts = await this.postService.findAll(
        this.auth.lastSelectedBrandId(),
        selectedGroupId
      );
    } catch (err) {
      console.error(err);
      this.toast.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  public async onSelectedPostGroup(group: PostGroup) {
    await this.updateSelectedGroup(group);
  }

  public async onPostGroupScheduleEdit() {
    if (this.isLoading) return;
    if (!this.selectedGroup) return;

    this.dialog
      .open<any, DialogData.PostGroupScheduleDialog>(
        PostGroupScheduleDialogComponent,
        {
          autoFocus: false,
          disableClose: true,
          data: { postGroup: GeneralUtils.deepCopy(this.selectedGroup) }
        }
      )
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (result: null | PostGroup) => {
        if (!result) {
          return;

        };

        return this.loadData(result.id);
      });
  }

  private async updateSelectedGroup(selectedGroup: PostGroup) {
    await this.router.navigate([], {
      queryParams: { groupId: selectedGroup.id },
      queryParamsHandling: 'merge',
    });
    this.selectedGroup = selectedGroup;
    this.loadSocialProfiles();
  }

  private async fetchAllPostGroups() {
    try {
      this.isLoading = true;
      this.groups = await this.postGroupService.findAll(this.auth.lastSelectedBrandId());
    } catch (err) {
      console.error(err);
      this.toast.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  private async loadData(preSelectedGroupId?: string) {
    try {
      this.isLoading = true;

      await this.fetchAllPostGroups();

      // Default to the first group if no group is selected
      const selectedGroup = (() => {
        if (!preSelectedGroupId) return this.groups[0];

        const preSelectedGroup = this.groups.find(
          ({ id }) => id === preSelectedGroupId
        );
        if (!preSelectedGroup) return this.groups[0];

        return preSelectedGroup;
      })();
      await this.updateSelectedGroup(selectedGroup);

      this.dataLoaded = true;
    } catch (err) {
      console.error(err);
      this.toast.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  private async loadSocialProfiles() {
    if (!this.selectedGroup) return;
    if (!this.selectedGroup.selectedSocialProfileIds?.length) {
      this.selectedSocialProfiles = [];
      return;
    }

    try {
      this.isLoading = true;

      const socialProfiles = await this.socialProfileService.findAll(
        this.auth.lastSelectedBrandId()
      );
      this.selectedSocialProfiles = socialProfiles.filter(({ id }) =>
        this.selectedGroup!.selectedSocialProfileIds.includes(id)
      );

    } catch (err) {
      console.error(err);
      this.toast.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  public async changeOrderOfPosts(event: CdkDragDrop<Post>) {

    if (event.currentIndex === event.previousIndex) return;

    this.isLoading = true;
    try {
      await this.postService.updateApprovedPostOrder(event.item.data.id, event.currentIndex);
      await this.fetchSelectedPostGroup();
      this.toast.success('Post order updated successfully');
    }
    catch (err) {
      console.error(err);
      this.toast.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }
}

type FilteredPosts = { [key in PostStatus]: Post[] };
