import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GeneralUtils } from '../../../shared/utils/general.utils';
import { ToastService } from '../../../shared/services/toast.service';
import { AuthService, authToken as authTokenService } from '../../../shared/services/auth.service';
import { Router } from '@angular/router';
import { LoadingDirective } from '../../../shared/directives/loading.directive';
import { DateUtils } from '../../../shared/utils/date.utils';

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [
    FormsModule,
    LoadingDirective,
  ],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss'
})
export class RegisterComponent implements OnInit {

  serviceTermsURL = 'https://www.squirrly.co/terms-of-use/';
  privacyPolicyURL = 'https://www.squirrly.co/privacy-policy/';

  userData = {
    brandName: '',
    timezone: '',
    name: '',
    email: '',
    password: '',
  }
  confirmPassword = '';
  timezones: string[] = [];

  isLoading = false;

  constructor(
    protected authService: AuthService,
    protected toast: ToastService,
    protected router: Router,
  ) { }

  public ngOnInit(): void {
    const authToken = authTokenService();
    if (!!authToken && authToken !== 'null') {
      void this.router.navigate(['/home']);
    }

    this.timezones = DateUtils.AllTimezones;

    const currentTz = DateUtils.timezone;
    if (this.timezones.includes(currentTz)) this.userData.timezone = currentTz;
  }

  public async onSubmit() {
    if (this.isLoading) return;

    if (!GeneralUtils.isEmail(this.userData.email)) {
      this.toast.error('Email address is not valid');
      return;
    }

    if (this.userData.password.length < 8) {
      this.toast.error('Password must be at least 8 characters long.')
      return;
    }

    if (this.userData.password !== this.confirmPassword) {
      this.toast.error('Password does not match confirmation.');
      return;
    }

    try {
      this.isLoading = true;

      await this.authService.register(this.userData);
      this.toast.success(`Successfully registered. Redirecting...`);

      void this.router.navigate(['/home']);
    } catch (err) {
      this.toast.error(err);
    } finally {
      this.isLoading = false;
    }
  }
}
