{
  "name": "@squirrly-social/webapp",
  "version": "1.0.0",
  "scripts": {
    "ng": "ng",
    "dev": "ng serve",
    "prebuild": "cp src/app/shared/services/trpc/types.build.ts src/app/shared/services/trpc/types.ts",
    "dev:prod": "ng serve --configuration dev",
    "build:dev": "npm run prebuild && ng build --configuration dev",
    "build": "npm run prebuild && ng build --configuration production"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.0.4",
    "@angular/cdk": "^18.1.0",
    "@angular/common": "^18.0.4",
    "@angular/compiler": "^18.0.4",
    "@angular/core": "^18.0.4",
    "@angular/forms": "^18.0.4",
    "@angular/material": "^18.1.0",
    "@angular/platform-browser": "^18.0.4",
    "@angular/platform-browser-dynamic": "^18.0.4",
    "@angular/router": "^18.0.4",
    "@ctrl/ngx-emoji-mart": "^9.2.0",
    "@ng-select/ng-select": "^13.4.1",
    "@trpc/client": "^11.0.0-rc.354",
    "dayjs": "^1.11.10",
    "ng-multiselect-dropdown": "^1.0.0",
    "ngx-color-picker": "^16.0.0",
    "ngx-toastr": "^18.0.0",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3",
    "@squirrly-social/api-models": "workspace:*"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.0.5",
    "@angular/cli": "^18.0.5",
    "@angular/compiler-cli": "^18.0.4",
    "@types/jasmine": "~5.1.0",
    "@types/node": "^20.11.20",
    "autoprefixer": "^10.4.19",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.38",
    "prettier": "^3.3.2",
    "prettier-plugin-tailwindcss": "^0.6.5",
    "tailwindcss": "^3.4.3",
    "typescript": "~5.4.5"
  }
}
