import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoadingDirective } from '../../../../shared/directives/loading.directive';
import { CommonModule } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';

@Component({
  selector: 'app-add-key-dialog',
  standalone: true,
  imports: [
    FormsModule,
    LoadingDirective,
    CommonModule,
    MatFormFieldModule,
    MatOptionModule,
    ReactiveFormsModule
  ],
  templateUrl: './add-key-dialog.component.html',
  styleUrl: './add-key-dialog.component.scss'
})
export class AddKeyDialogComponent {
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddKeyDialogComponent, { name: string }>
  ) {
    this.form = this.fb.group({
      name: ['', Validators.required],
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }
}
