import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { User } from '../models/user';
import { injectTrpcClient } from './trpc/client';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  protected endpoint = 'users';
  protected trpcClient = injectTrpcClient();
  constructor(protected http: HttpClient) { }

  public async updateOne(data: { name: string, email: string }) {
    const { user } = await lastValueFrom(
      this.http.put<{ user: User }>(`${this.endpoint}/me`, data)
    );

    return user;
  }

  public async getCurrentUser() {
    return this.trpcClient.user.getCurrentUser.query();
  }

  public async updateUserSelectedBrand(brandId: string) {
    return this.trpcClient.user.updateLastSelectedBrand.mutate({ brandId });
  }
}
