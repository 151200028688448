import { Component } from '@angular/core';
import { LoadingDirective } from '../../../shared/directives/loading.directive';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [
    LoadingDirective,
  ],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent {

  serviceTermsURL = 'https://www.squirrly.co/terms-of-use/';
  privacyPolicyURL = 'https://www.squirrly.co/privacy-policy/';

  isLoading = false;
}
