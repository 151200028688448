export const PostRepetition = {
  once: 'once', // Send once
} as const;
export type PostRepetition = typeof PostRepetition[keyof typeof PostRepetition];

export const PostStatus = {
  approved:'approved',
  draft: 'draft',
  finishedSending: 'finishedSending',
  scheduled: 'scheduled',
} as const;
export type PostStatus = typeof PostStatus[keyof typeof PostStatus];

export type ScheduleTimeEntry = { hh: number; mm: number };

export interface PostGroup {
  id: string;
  name: string;

  brand: string;
  repetition: PostRepetition;
  defaultStatus: PostStatus;

  postCount: number;

  scheduleDays: ScheduleWeekDay[];
  scheduleTimes: ScheduleTimeEntry[];

  selectedSocialProfileIds: string[];

  approvedPostOrder: PostOrder[];
}

export interface PostOrder {
  postId: string;
}

export enum ScheduleWeekDay {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}
