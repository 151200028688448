export class GeneralUtils {

  // ---------- Used in UploadComponent ---------- //
  public static isEvent = (event: any): event is Event => 'target' in event;

  public static isFileList = (fl: any): fl is FileList => 'length' in fl && '0' in fl;
  // --------------------------------------------- //

  // ---------- Encode/Decode of post text content ---------- //
  public static get base64() {
    return {
      to: (content: any) => btoa(content),
      from: (content: any) => atob(content),
    }
  }

  public static encodeContent(content: string) {
    return GeneralUtils.base64.to(encodeURIComponent(content));
  }

  public static decodeContent(content: string) {
    return decodeURIComponent(GeneralUtils.base64.from(content));
  }
  // -------------------------------------------------------- //

    /**
   * Convert an array of objects to an object with a unique key
   * @param array array of objects
   * @param key unique key, the value of this key will be used as the key of the returned object
   * @returns a map of objects with the key as the unique key
   */
    public static groupByUnique<T extends Record<string, any>>(array: T[], key: keyof T): { [key: string]: T } {
      return array.reduce((acc, item) => {
        const group = item[key];
        acc[group] = item
        return acc;
      }, {} as { [key: string]: T });
    }

  // ---------- General / misc ---------- //
  public static isEmail(value: any): value is string {
    return [
      !!value,
      typeof value === 'string',
      !!value.length,
      /^[a-zA-Z0-9\-\+\.\_\/]{1,63}@[a-zA-Z0-9\-\.]+$/gmi.test(value)
    ].every((cond) => cond);
  }

  public static parseJson<T>(content: string | null): T | null {
    try {
      if (content == null) return null;
      return <T>JSON.parse(content);
    } catch {
      return null;
    }
  }

  public static deepCopy<T>(value: T): T {
    return JSON.parse(JSON.stringify(value));
  }
}
