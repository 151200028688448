import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatRipple } from '@angular/material/core';

@Component({
  selector: 'app-search-bar',
  standalone: true,
  imports: [
    FormsModule,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    MatRipple,
  ],
  templateUrl: './search-bar.component.html',
  styleUrl: './search-bar.component.scss'
})
export class SearchBarComponent {

  @Input()
  search: string = '';

  @Output()
  searchChanged = new EventEmitter<{ value: string, type: 'all' | 'crt' }>();

  searchType: 'all' | 'crt' = 'crt';

  public get searchTypeText() {
    return {
      'all': 'All folders',
      'crt': 'Current folder',
    }[this.searchType];
  }

  public onSearch() {
    this.searchChanged.emit({
      value: this.search,
      type: this.searchType
    });
  }

  public onSearchTypeChanged(type: 'all' | 'crt') {
    this.searchType = type;

    if (this.search !== '') return this.onSearch();
  }
}
