import { Component, OnInit } from '@angular/core';
import { AuthService, authToken as authTokenService } from '../../../shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ToastService } from '../../../shared/services/toast.service';
import { LoadingDirective } from '../../../shared/directives/loading.directive';


@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    FormsModule,
    LoadingDirective,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {

  serviceTermsURL = 'https://www.squirrly.co/terms-of-use/';
  privacyPolicyURL = 'https://www.squirrly.co/privacy-policy/';

  email = '';
  password = '';
  persistentSession = true;

  isLoading = false;

  constructor(
    protected authService: AuthService,
    protected toast: ToastService,
    protected router: Router,
    protected route: ActivatedRoute,
  ) { }

  public ngOnInit(): void {
    const authToken = authTokenService();
    if (!!authToken && authToken !== 'null') {
      void this.router.navigate(['/home']);
    }
  }

  public async onLogin() {
    if (this.isLoading) return;

    if (!this.email.length) {
      this.toast.error('Invalid email');
      return;
    }

    if (!this.password.length) {
      this.toast.error('Invalid password');
      return;
    }

    try {
      this.isLoading = true;

      await this.authService.login(this.email, this.password, this.persistentSession);
      const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/home';

      this.toast.success('Successfully logged in.');
      await this.router.navigate([returnUrl]);
    } catch (err) {
      this.toast.error(err);
    } finally {
      this.isLoading = false;
    }
  }
}
