<div class="flex flex-col px-4 pt-4" [appLoading]="isLoading">
  <div class="pb-4">
    <div class="flex items-center justify-between">
      <span class="text-xl font-normal">TikTok Preview</span>
      <mat-icon aria-label="tiktok preview" fontIcon="info"></mat-icon>
    </div>
    <app-tiktok-policy></app-tiktok-policy>
  </div>
  @if (data.postFileUpload.type === UploadType.video) {
    <video
      class="h-[400px] w-[300px] object-cover"
      controls
      [src]="data.postFileUpload.url"
    ></video>
  }
  @if (data.postFileUpload.type === UploadType.image) {
    <img
      class="h-[400px] w-[300px] object-cover"
      [src]="data.postFileUpload.url"
      [alt]="data.postFileUpload.filename"
    />
  }
  <div class="flex w-full flex-col space-y-1 pt-4 text-sm text-gray-600">
    <span class="overflow-hidden text-ellipsis"
      >Title: {{ data.postContent }}
    </span>
    @for (
      creatorInformations of accountsCreatorInformations;
      track creatorInformations.creator_username
    ) {
      <span
        >Creator's nickname: {{ creatorInformations.creator_nickname }}</span
      >
      <span>Privacy Status: SELF_ONLY</span>
      <span>Allow Comment: {{ !creatorInformations.comment_disabled }}</span>
      @if (data.postFileUpload.type === UploadType.video) {
        <span
          >Duet status:
          {{
            !creatorInformations.duet_disabled ? "Enabled" : "Disabled"
          }}</span
        >
        <span
          >Stitch status:
          {{
            !creatorInformations.stitch_disabled ? "Enabled" : "Disabled"
          }}</span
        >
      }
      <hr />
    }
  </div>
</div>
