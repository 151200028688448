import { Component, Inject } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../../../shared/models/misc';
import { ToastService } from '../../../shared/services/toast.service';
import { AuthService } from '../../../shared/services/auth.service';
import { LoadingDirective } from '../../../shared/directives/loading.directive';
import { ZeroPadPipe } from '../../../shared/pipes/zero-pad.pipe';
import { PostGroupService } from '../../../shared/services/post-group.service';
import { ScheduleWeekDay } from '../../../shared/models/post-group';
import { BrandService } from '../../../shared/services/brand.service';

@Component({
  selector: 'app-post-group-schedule-dialog',
  standalone: true,
  imports: [
    MatTooltip,
    FormsModule,
    LoadingDirective,
    ZeroPadPipe,
  ],
  templateUrl: './post-group-schedule-dialog.component.html',
  styleUrl: './post-group-schedule-dialog.component.scss'
})
export class PostGroupScheduleDialogComponent {

  public static readonly DAYS: { label: string, value: ScheduleWeekDay }[] = [
    { label: 'Mon', value: ScheduleWeekDay.MONDAY },
    { label: 'Tue', value: ScheduleWeekDay.TUESDAY },
    { label: 'Wed', value: ScheduleWeekDay.WEDNESDAY },
    { label: 'Thu', value: ScheduleWeekDay.THURSDAY },
    { label: 'Fri', value: ScheduleWeekDay.FRIDAY },
    { label: 'Sat', value: ScheduleWeekDay.SATURDAY },
    { label: 'Sun', value: ScheduleWeekDay.SUNDAY }
  ]

  public readonly DAYS_OF_WEEK = PostGroupScheduleDialogComponent.DAYS;

  public readonly HOURS = [...new Array(12).keys()].map((key) => key + 1);
  public readonly MINUTES = [...new Array(60).keys()].map((key) => key);
  public readonly PERIOD = ['AM', 'PM']

  isLoading = false;

  scheduleTimes: TimeStamp_12h[] = [];

  constructor(
    public dialogRef: MatDialogRef<PostGroupScheduleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData.PostGroupScheduleDialog,
    protected toast: ToastService,
    protected auth: AuthService,
    protected postGroupService: PostGroupService,
    protected brandService: BrandService,
  ) { this.parseScheduleTimes(); }


  public isDayIncluded(dayIdx: ScheduleWeekDay) {
    return this.data.postGroup.scheduleDays.includes(dayIdx);
  }

  public onDaySelected(dayIdx: ScheduleWeekDay) {
    if (this.isLoading) return;

    this.isLoading = true;

    const postGroupDayIdx = this.data.postGroup.scheduleDays.findIndex((val) => val === dayIdx);

    // Doesn't exist --> push to array
    if (postGroupDayIdx === -1) {
      this.data.postGroup.scheduleDays.push(dayIdx);
    }

    // Exists --> must be extracted
    else {
      this.data.postGroup.scheduleDays.splice(postGroupDayIdx, 1);
    }

    // Sort days array (ASC)
    this.data.postGroup.scheduleDays.sort();

    this.isLoading = false;
  }

  public onNewScheduleTime() {
    let hh = 10;
    let mm = 0;
    let period: 'AM' | 'PM' = 'AM';

    let isTaken = this.scheduleTimes.find(({ hh: h, mm: m, period: p }) => (period === p && hh === h && mm === m));
    while (isTaken) {
      mm += 1; // Next minute

      // If past the hour, adjust
      if (mm === 60) {
        hh++; // Increment hour
        mm = 0; // Reset minutes
      }

      // If past noon, adjust
      if (hh === 12) {
        period = 'PM'
      } else if (hh === 13) {
        hh = 1;
      }

      isTaken = this.scheduleTimes.find(({ hh: h, mm: m, period: p }) => (period === p && hh === h && mm === m));
    }

    this.scheduleTimes.push({ hh, mm, period });
  }

  public onRemoveScheduleTime(entry: TimeStamp_12h) {
    const idx = this.scheduleTimes.findIndex(({ hh, mm, period }) => hh === entry.hh && mm === entry.mm && period === entry.period);
    if (idx !== -1) this.scheduleTimes.splice(idx, 1);
  }

  public async onSubmit() {
    if (this.isLoading) return;

    try {
      this.isLoading = true;

      const payload = this.validateData();

      const postGroup = await this.postGroupService.updateOne(this.auth.lastSelectedBrandId(), this.data.postGroup.id, payload);

      this.toast.success('Schedule successfully updated.')
      return this.dialogRef.close(postGroup);
    } catch (err) {
      console.error(err);
      this.toast.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  private parseScheduleTimes() {
    // Convert 24h to 12h
    this.scheduleTimes = this.data.postGroup.scheduleTimes.map(({ hh: h, mm }) => {
      if (h === 0) return { hh: 12, mm, period: 'AM' };
      if (h < 12) return { hh: h, mm, period: 'AM' };
      if (h === 12) return { hh: 12, mm, period: 'PM' };
      return { hh: Number(h) % 12, mm, period: 'PM' };
    })
  }

  private validateData() {
    [
      {
        condition: this.data.postGroup.scheduleDays.every((num) => Object.values(ScheduleWeekDay).includes(num)),
        err: 'Invalid day code',
      },
      {
        condition: this.scheduleTimes.every(({ hh, mm }) => (hh >= 0 && hh <= 12) && (mm >= 0 && mm <= 59)),
        err: 'Invalid hours for schedule times',
      }
    ].forEach(({ condition, err }) => { if (!condition) throw new Error(err); })

    // Convert 12h to 24h
    const scheduleTimes = this.scheduleTimes.map(({ hh, mm, period }) => {
      if (period === 'AM') {
        if (hh === 12) return { hh: 0, mm: Number(mm) };
        return { hh: Number(hh), mm: Number(mm) };
      } else {
        if (hh === 12) return { hh: 12, mm: Number(mm) };
        return { hh: 12 + Number(hh), mm: Number(mm) };
      }
    }).sort((e1, e2) => `${e1.hh}:${e1.mm}`.localeCompare(`${e2.hh}:${e2.mm}`));


    // Ensure there are no duplicates
    const uniqueScheduleTimes = [...new Set(scheduleTimes.map(({ hh, mm }) => `${hh}:${mm}`))];
    if (uniqueScheduleTimes.length !== scheduleTimes.length) {
      throw new Error(`Duplicated schedule times are not allowed.`)
    }

    return {
      name: this.data.postGroup.name,
      repetition: this.data.postGroup.repetition,
      defaultStatus: this.data.postGroup.defaultStatus,
      scheduleDays: this.data.postGroup.scheduleDays,
      scheduleTimes,
    }
  }
}

type TimeStamp_12h = { hh: number, mm: number, period: 'AM' | 'PM' };
