import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { LoadingDirective } from '../../shared/directives/loading.directive';
import { ToastService } from '../../shared/services/toast.service';
import { BrandService } from '../../shared/services/brand.service';
import { UserService } from '../../shared/services/user.service';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-account-settings',
  standalone: true,
  imports: [
    FormsModule,
    LoadingDirective,
    RouterModule,
    MatIconModule
  ],
  templateUrl: './account-settings.component.html',
  styleUrl: './account-settings.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class AccountSettingsComponent implements OnInit {


  constructor(
    protected toast: ToastService,
    protected brandService: BrandService,
    protected router: Router,
    protected userService: UserService,
  ) {
  }

  public ngOnInit(): void {
  }

}


