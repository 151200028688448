<div class="card" [appLoading]="isLoading">
  <div class="flex justify-between items-center">
    <h2 class="text-xl">Generated API keys ({{apiKeys.length}})</h2>
    <button class="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
      (click)="onOpenDialogCreateNewApiKey()">Create new key</button>
  </div>
  <div class="max-w-6xl mx-auto bg-white shadow-md rounded-lg overflow-hidden">
    <table class="min-w-full bg-white">
      <thead>
        <tr class="w-full bg-gray-100 text-left text-gray-600 uppercase text-sm leading-normal">
          <th class="py-3 px-6">#</th>
          <th class="py-3 px-6">Name</th>
          <th class="py-3 px-6">Key</th>
          <th class="py-3 px-6 text-right">Actions</th>
        </tr>
      </thead>
      <tbody class="text-gray-600 text-sm font-light">
        <tr class="border-b border-gray-200 hover:bg-gray-100" *ngFor="let key of apiKeys; let i = index">
          <td class="py-3 px-6">{{i + 1}}</td>
          <td class="py-3 px-6 flex items-center gap-2">
            <span class="icon">
              {{getIcon(key.status)}}
            </span>
            <span>{{key.name}}</span>
          </td>
          <td class="py-3 px-6">
            <div class="flex items-center gap-2">
              <span *ngIf="key.key">
                <span [class.hidden]="!key.visible" style="word-break: break-word;">{{ key.key }}</span>
                <span [class.hidden]="key.visible" style="word-break: break-word;">{{ maskKey(key.key) }}</span>
              </span>
              <span *ngIf="!key.key">Secret Key — Preview not available</span>
              <button *ngIf="key.key" (click)="toggleVisibility(key)" class="ml-2 text-gray-400 hover:text-gray-600">
                <span class="material-icons">{{ key.visible ? 'visibility_off' : 'visibility' }}</span>
              </button>
            </div>
          </td>
          <td class="py-3 px-6 text-right flex items-center gap-2">
            <button (click)="copyToClipboard(key.key)" class="text-gray-400 hover:text-gray-600">
              <span class="material-icons">content_copy</span>
            </button>
            <button (click)="deleteKey(key.id)" class="text-gray-400 hover:text-gray-600">
              <span class="material-icons">delete</span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</div>