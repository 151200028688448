import { Component, effect, signal } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { LoadingDirective } from '../../../shared/directives/loading.directive';
import { CommonModule } from '@angular/common';
import { injectTrpcClient } from '../../../shared/services/trpc/client';
import { AuthService } from '../../../shared/services/auth.service';
import { ToastService } from '../../../shared/services/toast.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { OPENAI_MODELS } from '@squirrly-social/api-models';

@Component({
    selector: 'app-chatgpt-settings',
    standalone: true,
    imports: [FormsModule, LoadingDirective, CommonModule, ReactiveFormsModule, NgSelectModule],
    templateUrl: './chatgpt-settings.component.html',
    styleUrl: './chatgpt-settings.component.scss',
})
export class ChatGPTSettingsComponent {
    form: FormGroup;
    isLoading = false;

    ChatGPTStatus = ChatGPTStatus;
    chatGPTConnectionStatus = signal<ChatGPTStatus>(
        ChatGPTStatus.DONTEXIST,
    );
    chatGPTModels = Object.values(OPENAI_MODELS).map((model) => ({
        label: model,
    }));
    selectedChatGPTModel = this.chatGPTModels[1];

    trpcClient = injectTrpcClient();
    constructor(
        private fb: FormBuilder,
        private authService: AuthService,
        private toast: ToastService,
    ) {
        this.form = this.fb.group({
            connectionKey: ['', Validators.required],
        });

        effect(async () => {
            await this.updateStatus(this.authService.lastSelectedBrandId());
        });
    }

    async updateStatus(brandId: string) {
        const { status, model } =
            await this.trpcClient.chatGPTSettings.getConnectionForCurrentUser.query(
                {
                    brandId: brandId,
                },
            );
        if (status == null) {
            this.chatGPTConnectionStatus.set(ChatGPTStatus.DONTEXIST);
            return;
        }
        this.chatGPTConnectionStatus.set(status as any);
        this.selectedChatGPTModel = this.chatGPTModels.find(item => item.label === model) ?? this.selectedChatGPTModel;
    }

    async onSubmit() {
        if (!this.form.valid) return;
        const apiKey = this.form.value.connectionKey;
        this.isLoading = true;
        try {
            await this.trpcClient.chatGPTSettings.saveApiKey.mutate({
                brandId: this.authService.lastSelectedBrandId(),
                apiKey,
                model: this.selectedChatGPTModel.label,
            });
            await this.updateStatus(this.authService.lastSelectedBrandId());
            this.toast.success('ChatGPT key saved successfully');
        } catch (err) {
            this.toast.error(err);
        } finally {
            this.isLoading = false;
            this.form.reset();
        }
    }

    getStatusText(status: ChatGPTStatus) {
        switch (status) {
            case ChatGPTStatus.DONTEXIST:
            case ChatGPTStatus.DISCONNECTED:
                return 'NOT CONNECTED';
            default:
                return status;
        }
    }
}

const ChatGPTStatus = {
    CONNECTED: 'CONNECTED',
    DISCONNECTED: 'DISCONNECTED',
    DONTEXIST: 'DONTEXIST',
} as const;

type ChatGPTStatus =
    (typeof ChatGPTStatus)[keyof typeof ChatGPTStatus];
