<div
  class="post-card"
  (mouseover)="showAdditionalInfo = true"
  (mouseleave)="showAdditionalInfo = false"
>
  <div class="header">Post</div>
  @if (showAdditionalInfo) {
    <div class="flex flex-col p-3">
      <div class="mb-2">
        {{ post.textContent }}
      </div>

      @if (
        post.sendingStatus === PostSendingStatus.PENDING ||
        post.sendingStatus === PostSendingStatus.DEV_PENDING
      ) {
        <div class="grid min-h-8 grid-cols-3 rounded-lg border">
          <button
            class="flex items-center justify-center border-r"
            matTooltip="Copy"
            matTooltipPosition="above"
            (click)="onCopy()"
          >
            <i class="icon outlined">content_copy</i>
          </button>
          <button
            class="flex items-center justify-center border-r"
            matTooltip="Edit"
            matTooltipPosition="above"
            (click)="onEdit()"
          >
            <i class="icon outlined">edit_note</i>
          </button>
          <button
            class="flex items-center justify-center"
            matTooltip="Delete"
            matTooltipPosition="above"
            (click)="onDelete()"
          >
            <i class="icon outlined">delete</i>
          </button>
        </div>
      }
    </div>
  } @else {
    @if (!post.uploadFile) {
      <div class="flex items-center justify-start p-3">
        {{ post.textContent }}
      </div>
    } @else {
      <div class="media">
        @if (post.uploadFile.type === UploadType.image) {
          <img [src]="post.uploadUrl" [alt]="post.uploadFile.filename" />
        }

        @if (post.uploadFile.type === UploadType.video) {
          <img
            class="vid-icon"
            src="/assets/icons/video.png"
            alt="Video Logo overlay"
          />
          <video preload="metadata">
            <source [src]="post.uploadUrl + '#t=0.1'" type="video/mp4" />
          </video>
        }
      </div>
    }
  }

  <div class="info">
    <span
      class="status"
      [class.success]="post.sendingStatus === PostSendingStatus.SENT"
      >{{ post.sendingStatus.toLowerCase() }}</span
    >

    <span class="time">{{
      post.calendarPost!.scheduledAt | date: "HH:mm" || "--"
    }}</span>
  </div>

  @if (post.calendarPost?.socialProfiles != null) {
    <div class="profiles">
      @for (profile of post.calendarPost?.socialProfiles; track profile.id) {
        <img
          src="/assets/icons/fa-{{ icon(profile.type) }}"
          matTooltip="{{ profile.data.name }}"
          [alt]="profile.type"
        />
      }
    </div>
  }
</div>
