import { Component, effect, input, Input, Signal, SimpleChanges } from "@angular/core";
import { TikTokPolicyComponent } from "../tiktok-policy/tiktok-policy.component";
import { Upload, UploadType } from "../../models/upload";
import { MatIconModule } from "@angular/material/icon";
import { SocialProfileService, TiktokCreatorInformation } from "../../services/social-profile.service";
import { ToastService } from "../../services/toast.service";
import { LoadingDirective } from "../../directives/loading.directive";

@Component({
    selector: 'app-tiktok-preview',
    standalone: true,
    templateUrl: './tiktok-preview.component.html',
    imports: [TikTokPolicyComponent, MatIconModule, LoadingDirective]
})
export class TikTokPreviewComponent {
    @Input() data: TikTokPreviewData;
    @Input() tiktokProfilesIds!: Signal<string[]>;
    isLoading = false;
    public readonly UploadType = UploadType;
    public accountsCreatorInformations: TiktokCreatorInformation[] = [];
    public allAccountsCreatorInformations: TiktokCreatorInformation[] = [];
    constructor(
        private socialProfileService: SocialProfileService,
        private toast: ToastService
    ) {
        effect(async () => {
            await this.loadAccountsCreatorInformations();
            this.accountsCreatorInformations = this.tiktokProfilesIds().map((profileId) => this.allAccountsCreatorInformations.find((account) => account.profileId === profileId)!);
        });
    }

    async loadAccountsCreatorInformations() {
        this.isLoading = true;
        try {
            await Promise.all(this.tiktokProfilesIds().map(async (profileId) => {
                if (this.allAccountsCreatorInformations.find((account) => account.profileId === profileId)) {
                    return;
                }
                const creatorInformation = await this.socialProfileService.getTikTokCreatorInformation(profileId);
                this.allAccountsCreatorInformations.push({ ...creatorInformation, profileId });
            }));
        }
        catch (error) {
            this.toast.error('Failed to load accounts creator informations');
            console.error(error);
        }
        finally {
            this.isLoading = false;
        }
    }
}

export type TikTokPreviewData = {
    postFileUpload: Upload;
    postContent: string;
}