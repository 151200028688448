<div class="p-4">
  <h2 class="text-lg font-medium text-gray-800 mb-4">Add New API Key</h2>
  <div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="mb-4">
        <label class="block text-gray-700">Name</label>
        <input type="text" formControlName="name"
          class="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          required />
      </div>

      <div class="flex justify-end">
        <button type="button" (click)="onCancel()"
          class="mr-2 px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400">
          Cancel
        </button>
        <button type="submit" [disabled]="form.invalid"
          class="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
          Add
        </button>
      </div>
    </form>
  </div>
</div>