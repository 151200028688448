import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CalendarHint } from '../models/calendar';
import { lastValueFrom } from 'rxjs';
import { Post } from '../models/post';
import { GeneralUtils } from '../utils/general.utils';
import { injectTrpcClient } from './trpc/client';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  private endpoint = 'calendar';
  protected trpcClient = injectTrpcClient();
  constructor(protected http: HttpClient) {}

  public async createHint(brandId: string, data: CreateEntryData) {
    const { hint } = await lastValueFrom(
      this.http.post<{ hint: CalendarHint }>(`${this.endpoint}/hints`, data, {
        params: { brandId },
      })
    );

    return hint;
  }

  public async updateHint(
    brandId: string,
    hintId: string,
    data: UpdateEntryData
  ) {
    const { hint } = await lastValueFrom(
      this.http.put<{ hint: CalendarHint }>(
        `${this.endpoint}/hints/${hintId}`,
        data,
        {
          params: { brandId },
        }
      )
    );

    return hint;
  }

  public async deleteHint(brandId: string, hintId: string) {
    const { hint } = await lastValueFrom(
      this.http.delete<{ hint: CalendarHint }>(
        `${this.endpoint}/hints/${hintId}`,
        {
          params: { brandId },
        }
      )
    );

    return hint;
  }

  public async findAllHints(brandId: string, date: string) {
    const { hints } = await lastValueFrom(
      this.http.get<{ hints: CalendarHint[] }>(
        `${this.endpoint}/hints/${date}`,
        {
          params: { brandId },
        }
      )
    );

    return hints;
  }

  public async findAllPosts(brandId: string, date: string): Promise<Post[]> {
    const { posts } = await this.trpcClient.calendar.findPosts.query({
      brandId,
      date,
    });

    return posts.map(
      (post: any) =>
        ({
          ...post,
          ...(post.textContent.length && {
            textContent: GeneralUtils.decodeContent(post.textContent),
          }),
        } as Post)
    );
  }
}

type CreateEntryData = {
  text: string;
  color: string;
  startDate: Date;
  endDate: Date;
};

type UpdateEntryData = CreateEntryData;
