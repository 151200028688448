import { Component, effect, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { LoadingDirective } from '../../shared/directives/loading.directive';
import { AuthService } from '../../shared/services/auth.service';
import { Router } from '@angular/router';
import { ToastService } from '../../shared/services/toast.service';
import { ConfirmationService } from '../../shared/services/confirmation.service';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatCheckbox } from '@angular/material/checkbox';
import { PostGroupService } from '../../shared/services/post-group.service';
import { PostGroup, PostStatus } from '../../shared/models/post-group';
import { SocialProfileService } from '../../shared/services/social-profile.service';
import {
  SocialProfile,
  SocialProfileType,
} from '../../shared/models/social-profile';
import { CommonModule, DatePipe, JsonPipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { DialogData } from '../../shared/models/misc';
import { HintFormComponent } from './hint-form/hint-form.component';
import { Subscription, take } from 'rxjs';
import { DateUtils } from '../../shared/utils/date.utils';
import { CalendarService } from '../../shared/services/calendar.service';
import { CalendarHint } from '../../shared/models/calendar';
import { Post } from '../../shared/models/post';
import { PostFormDialogComponent } from '../planner/post-form-dialog/post-form-dialog.component';
import { UploadType } from '../../shared/models/upload';
import { MatTooltip } from '@angular/material/tooltip';
import { CreatePostUiService } from '../../shared/services/create-post-ui.service';
import { FormBuilder } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { PostCardComponent } from "./post-card/post-card.component";
import { PostService } from '../../shared/services/post.service';
import { UpdatePostFormDialogComponent } from '../planner/post-form-dialog/update-post-form-dialog/update-post-form-dialog.component';
import { BrandService } from '../../shared/services/brand.service';

enum TabType {
  day = 'day',
  week = 'week',
}

@Component({
  selector: 'app-calendar',
  standalone: true,
  imports: [
    LoadingDirective,
    MatMenu,
    MatMenuItem,
    MatCheckbox,
    MatMenuTrigger,
    DatePipe,
    JsonPipe,
    MatTooltip,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    CommonModule,
    PostCardComponent
  ],
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.scss',
})
export class CalendarComponent implements OnInit, OnDestroy {
  currentTab = TabType.week;
  isLoading = false;

  selectedSocialProfiles: SocialProfile[];

  readonly Options = {
    groups: <PostGroup[]>[],
    socialProfiles: <SocialProfile[]>[],
  };
  readonly TabType = TabType;
  readonly UploadType = UploadType;

  currentDate: Date = new Date(); // Date (of which week) is tracked
  entries: WeekDayEntry[] = [];

  subscriptions: Subscription[] = [];

  constructor(
    protected auth: AuthService,
    protected postService: PostService,
    protected router: Router,
    protected toast: ToastService,
    protected confirm: ConfirmationService,
    protected postGroupService: PostGroupService,
    protected socialProfileService: SocialProfileService,
    protected dialog: MatDialog,
    protected calendarService: CalendarService,
    protected createPostUi: CreatePostUiService,
    protected formBuilder: FormBuilder,
    protected brandService: BrandService,
  ) {
    effect(async () => {
      if (this.auth.lastSelectedBrandId()) {
        [this.Options.groups, this.Options.socialProfiles] = await Promise.all([
          // Fetching all available post groups
          this.postGroupService.findAll(this.auth.lastSelectedBrandId()),

          // Fetching all available social profiles
          this.socialProfileService.findAll(this.auth.lastSelectedBrandId()),
        ]);

        this.selectedSocialProfiles = this.Options.socialProfiles;
        await this.loadData();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public async ngOnInit() {
    this.subscriptions.push(
      this.createPostUi.eventCreated.subscribe(() => {
        this.onAddNewPost();
      })
    );
  }

  public get today() {
    return new Date().toISOString().slice(0, 10);
  }

  public disabledButton(date: Date) {
    return DateUtils.isAfter(this.today, date);
  }

  public icon(type: SocialProfileType) {
    switch (type) {
      case SocialProfileType.facebookPage:
        return 'facebook.svg';
      case SocialProfileType.facebookGroup:
        return 'facebook.svg';
      case SocialProfileType.googleMyBusiness:
        return 'google.svg';
      case SocialProfileType.instagramBusinessPage:
        return 'instagram.svg';
      case SocialProfileType.linkedinPage:
        return 'linkedin.svg';
      case SocialProfileType.linkedinPerson:
        return 'linkedin.svg';
      case SocialProfileType.tiktok:
        return 'tiktok.svg';
      case SocialProfileType.twitter:
        return 'twitter.svg';
      case SocialProfileType.youtube:
        return 'youtube.svg';
      default:
        return 'UNDEF';
    }
  }

  public onSwitchTabs(tab: TabType) {
    if (tab === TabType.day) {
      this.toast.warning('Not available at the moment.');
      return;
    } else {
      // Nothing to do, by default the 'week' tab is selected
    }
  }

  public async onAddNewPost() {
    if (this.isLoading) return;

    this.dialog
      .open<any, DialogData.PostFormDialog>(PostFormDialogComponent, {
        autoFocus: false,
        disableClose: true,
        panelClass: 'post-form-overlay-dialog',
        data: {
          status: PostStatus.scheduled,
          type: 'calendar',
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (result: null | Post) => {
        if (!result) return;

        return this.loadData();
      });
  }

  public onAddNewHint(entry: WeekDayEntry) {
    if (this.isLoading) return;

    this.dialog
      .open<any, DialogData.CalendarHintForm>(HintFormComponent, {
        disableClose: true,
        autoFocus: false,
        data: {
          date: entry.date.toISOString().slice(0, 10),
          action: 'add',
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (result?: null | CalendarHint) => {
        if (!result) return;
        return this.loadData();
      });
  }

  public onEditCalendarHint({ data: hint }: WeekDayHintEntry) {
    if (this.isLoading) return;

    this.dialog
      .open<any, DialogData.CalendarHintForm>(HintFormComponent, {
        disableClose: true,
        autoFocus: false,
        data: { action: 'edit', date: '', hint },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (result?: null | CalendarHint) => {
        if (!result) return;
        return this.loadData();
      });
  }

  async onCopyPost(postId: string) {
    if (this.isLoading) return;
    try {
      this.isLoading = true;
      await this.postService.copyOne(postId);
      await this.loadData();
      this.toast.success('Post copied successfully');
    } catch (err) {
      console.error(err);
      this.toast.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  async onEditPost(postId: string) {
    if (this.isLoading) return;
    this.dialog
      .open<any, string>(UpdatePostFormDialogComponent, {
        autoFocus: false,
        disableClose: true,
        panelClass: 'post-form-overlay-dialog',
        data: postId,
      }).afterClosed()
      .pipe(take(1))
      .subscribe(async (result: null | Post) => {
        if (!result) return;
        await this.loadData();
      });
  }

  // ----- Week change functions ----- //
  public get currentlyTrackedDate() {
    return this.currentDate.toISOString().slice(0, 10);
  }

  public onChangeWeek(change: 1 | -1) {
    if (this.isLoading) return;

    console.log('start', { currentDate: this.currentDate });
    this.isLoading = true;

    const now = new Date(this.currentDate);
    now.setDate(now.getDate() + change * 7);

    this.currentDate = now;

    return this.loadData();
  }
  // \\ ----- Week change functions ----- //

  private async loadData() {
    try {
      this.isLoading = true;

      await this.getEntriesForCurrentWeek();

    } catch (err) {
      console.error(err);
      this.toast.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  private async getEntriesForCurrentWeek() {
    const [hints, posts] = await Promise.all([
      this.calendarService.findAllHints(
        this.auth.lastSelectedBrandId(),
        this.currentlyTrackedDate
      ),
      this.calendarService.findAllPosts(
        this.auth.lastSelectedBrandId(),
        this.currentlyTrackedDate
      ),
    ]);

    const selectedSocialProfilesIds = this.selectedSocialProfiles.map(sp => sp.id);
    const filtredPosts = posts.filter(post => {
      return post.calendarPost?.socialProfileIds?.some((id) => selectedSocialProfilesIds.includes(id));
    }
    );

    this.entries = extractWeekEntries(this.currentDate, hints, filtredPosts as any);
  }

  public toggleSelectAll(event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    if (isChecked) {
      this.selectedSocialProfiles = [...this.Options.socialProfiles];
    } else {
      this.selectedSocialProfiles = [];
    }
  }

  public async updateDisplayedData() {
    await this.loadData();
  }

  tba() { }
}

type WeekDayHintEntry = {
  idx: number;
  data: CalendarHint;
  hover: boolean;
};
type WeekDayEntry = {
  date: Date;
  dateString: string;
  data: (Post & { idx: number })[];
  hints: WeekDayHintEntry[];
};

function extractWeekEntries(
  date: Date,
  hints: CalendarHint[],
  posts: Post[],
): WeekDayEntry[] {

  return DateUtils.getWeekDayDateStrings(date).map((dateString) => {
    const _date = new Date(dateString);
    const { start: todayStart, end: todayEnd } =
      DateUtils.getStartEndOfDate(_date);

    const dayHints = hints
      .filter((hint) => {
        const _start = new Date(hint.startDate);
        const _end = new Date(hint.endDate);

        return _start <= _date && _date <= _end;
      })
      .map((hint, idx) => ({ idx, data: hint, hover: false }));

    const dayPosts = posts
      .filter((post) => {
        const scheduledAt = new Date(post.calendarPost!.scheduledAt!);
        const startsToday = DateUtils.isAfter(scheduledAt, todayStart);
        const endsToday = !DateUtils.isAfter(scheduledAt, todayEnd);

        return startsToday && endsToday;
      })
      .map((post, idx) => ({ idx, ...post }));

    return { date: _date, dateString, data: dayPosts, hints: dayHints };
  });
}
