import { InjectionToken, type Provider, inject } from '@angular/core';
import { createTRPCClient, httpBatchLink } from '@trpc/client';
import { environment } from '../../../../environments/environment';
import { authToken, injectAuthService } from '../auth.service';
import type { AppRouter, InjectAppRouterType } from './types';

const TRPC_PROVIDER = new InjectionToken<InjectAppRouterType>('__TRPC_PROVIDER__');
export const injectTrpcClient = () => inject(TRPC_PROVIDER);
export const provideTrpcClient = (): Provider => ({
  provide: TRPC_PROVIDER,
  useFactory: () => {
    return createTRPCClient<AppRouter>({
      links: [
        httpBatchLink({
          url: `${environment.serverBaseUrl}/trpc`,
          async headers() {
            return {
              Authorization: `Bearer ${authToken()}`,
            };
          },
        }),
      ],
    });
  },
});