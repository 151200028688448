import { Component, Input } from '@angular/core';
import { Upload, UploadType } from '../../../shared/models/upload';
import { UpperCasePipe } from '@angular/common';

@Component({
  selector: 'app-media-tile',
  standalone: true,
  imports: [
    UpperCasePipe,
  ],
  templateUrl: './media-tile.component.html',
  styleUrl: './media-tile.component.scss'
})
export class MediaTileComponent {

  @Input({ required: true })
  upload: Upload;

  @Input()
  selected: boolean = false;

  UploadType = UploadType;
}
