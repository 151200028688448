import { Component, Inject, OnInit, signal } from '@angular/core';
import { PostRepetition, PostStatus } from '../../../shared/models/post-group';
import { FormsModule } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { NgSelectModule } from '@ng-select/ng-select';
import { PickerComponent } from '@ctrl/ngx-emoji-mart';
import { PostGroupService } from '../../../shared/services/post-group.service';
import { PostService } from '../../../shared/services/post.service';
import { AuthService } from '../../../shared/services/auth.service';
import { ToastService } from '../../../shared/services/toast.service';
import { EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji/emoji.component';
import { GeneralUtils } from '../../../shared/utils/general.utils';
import { Post } from '../../../shared/models/post';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../../../shared/models/misc';
import { LoadingDirective } from '../../../shared/directives/loading.directive';
import { GalleryDialogComponent } from './gallery-dialog/gallery-dialog.component';
import { take } from 'rxjs';
import { Upload, UploadType } from '../../../shared/models/upload';
import { SocialProfile, SocialProfileType } from '../../../shared/models/social-profile';
import { SocialProfileService } from '../../../shared/services/social-profile.service';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { DateUtils } from '../../../shared/utils/date.utils';
import { BrandService } from '../../../shared/services/brand.service';
import { TikTokPreviewComponent } from "../../../shared/components/tiktok-preview/tiktok-preview.component";

@Component({
  selector: 'app-post-form-dialog',
  standalone: true,
  imports: [
    FormsModule,
    MatTooltip,
    NgSelectModule,
    PickerComponent,
    LoadingDirective,
    MatSlideToggle,
    TikTokPreviewComponent
  ],
  templateUrl: './post-form-dialog.component.html',
  styleUrl: './post-form-dialog.component.scss'
})
export class PostFormDialogComponent implements OnInit {

  public readonly Status = PostStatus;
  public readonly Repetition = PostRepetition;
  public readonly UploadType = UploadType;

  isLoading = false;

  isEmojiPickerVisible = false;

  post = EmptyPost();
  selectedUpload: null | Upload = null;

  pageType: 'calendar' | 'postGroup';
  selectedSocialProfiles: SocialProfile[] = [];
  allSocialProfiles: SocialProfile[] = [];
  sendLater = false; // "Send (post) now" vs "Send (post) later (on schedule)"
  scheduledAt: Date = new Date();
  tiktokProfilesSelectedIds = signal<string[]>([]);

  constructor(
    protected dialogRef: MatDialogRef<PostFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData.PostFormDialog,
    protected postService: PostService,
    protected auth: AuthService,
    protected toast: ToastService,
    protected dialog: MatDialog,
    protected socialProfileService: SocialProfileService,
    protected postGroupService: PostGroupService,
    protected brandService: BrandService,
  ) {
    this.pageType = data.type;

    // Overwrite some data (if provided)
    this.post.status = data.status;

    if (this.data.postGroupId) this.post.postGroup = this.data.postGroupId!;

    // If upload is provided, set it
    if (this.data.uploadFile) {
      this.selectedUpload = this.data.uploadFile;
      this.post.upload = this.selectedUpload._id;
    }
  }

  public ngOnInit(): void {
    const arePostGroupsProvided = this.data.groups != null && this.data.groups.length > 0;
    this.loadData(!arePostGroupsProvided).then();
  }

  public exceedsLimit(app: 'twitter' | '' = '') {
    switch (app) {
      case 'twitter': return this.post.textContent.length > 280;
      default: return this.post.textContent.length > 280; // Min length of all required max lengths
    }
  }

  public onAddEmoji({ emoji }: EmojiEvent) {
    this.post.textContent += emoji.native;
  }

  // ---------- Form submit ---------- //
  public async onSubmit() {
    if (this.isLoading) return;

    try {
      this.isLoading = true;

      const payload = this.validateData(this.pageType);

      const post = await this.postService.createOne(this.auth.lastSelectedBrandId(), payload);
      this.toast.success('Post successfully added.');

      return this.dialogRef.close(post);
    } catch (err) {
      console.error(err);
      this.toast.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  // ---------- \\ Form submit ---------- //

  textContentUrls: { url: string, tinyUrl: string }[] = [];
  public async onShortenLinks() {
    if (this.isLoading) return;

    try {
      this.isLoading = true;

      const urls = this.post.textContent.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,63}\b([-a-zA-Z0-9()'@:%_\+.~#?!&//=]*)/gi)

      const items = !urls?.length
        ? []
        : urls.map((item) => item.replace(/[()]|\.$/g, ''));

      this.textContentUrls = items.map(
        // If the url is already shortened, set the url as "tinyUrl" as well
        (url) => url.startsWith('https://tinyurl.com')
          ? ({ url, tinyUrl: url })
          : ({ url, tinyUrl: '' })
      );

      // Replace URLs with shortened ones
      let newTextContent = this.post.textContent;
      for (const entry of this.textContentUrls) {
        if (!entry.tinyUrl.length) {
          entry.tinyUrl = await this.postService.createTinyUrl(this.auth.lastSelectedBrandId(), entry.url);
        }

        newTextContent = newTextContent.replaceAll(entry.url, entry.tinyUrl);
      }
      this.post.textContent = newTextContent;
    } catch (err) {
      console.error(err);
      this.toast.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  public onSelectMedia() {
    if (this.isLoading) return;

    this.dialog.open<any, DialogData.SelectMedia>(GalleryDialogComponent, {
      autoFocus: false,
      disableClose: true,
      data: {},
      panelClass: 'gallery-dialog-panel',
    })
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (result?: null | Upload) => {
        if (!result) return;
        if (result._id === this.selectedUpload?._id) return;

        try {
          this.isLoading = true;

          this.selectedUpload = result;
          this.post.upload = this.selectedUpload._id;
        } catch (err) {
          console.error(err);
          this.toast.error(err)
        } finally {
          this.isLoading = false;
        }
      })
  }

  public onSwitchTo(pageType: 'calendar' | 'postGroup') {
    this.pageType = pageType;

    switch (pageType) {
      case 'calendar':
        this.post.status = PostStatus.scheduled;
        this.tiktokProfilesSelectedIds.set(this.getTikTokSelectedProfilesIds());
        break;
      case 'postGroup':
        this.post.status = PostStatus.draft;
        this.tiktokProfilesSelectedIds.set([]);
        break;
      default:
        throw new Error('Unhandled page type');
    }
  }

  public icon(type: SocialProfileType) {
    return '/assets/icons/fa-' +
      {
        [SocialProfileType.twitter]: 'twitter.svg',
        [SocialProfileType.tiktok]: 'tiktok.svg',
        [SocialProfileType.linkedinPerson]: 'linkedin.svg',
        [SocialProfileType.linkedinPage]: 'linkedin.svg',
        [SocialProfileType.instagramBusinessPage]: 'instagram.svg',
        [SocialProfileType.googleMyBusiness]: 'google.svg',
        [SocialProfileType.facebookGroup]: 'facebook.svg',
        [SocialProfileType.facebookPage]: 'facebook.svg',
        [SocialProfileType.youtube]: 'youtube.svg',
      }[type];
  }

  public isSelected(socialProfile: SocialProfile) {
    return !!this.selectedSocialProfiles.find(({ id }) => id === socialProfile.id);
  }

  public onSocialProfileClick(socialProfile: SocialProfile) {
    const idx = this.selectedSocialProfiles.findIndex(({ id }) => id === socialProfile.id);
    if (idx === -1) {
      this.selectedSocialProfiles.push(socialProfile)
    } else {
      this.selectedSocialProfiles.splice(idx, 1);
    }
    if (socialProfile.type === SocialProfileType.tiktok) {
      this.tiktokProfilesSelectedIds.set(this.getTikTokSelectedProfilesIds());
    }
  }

  public getTikTokSelectedProfilesIds() {
    return this.selectedSocialProfiles.filter(({ type }) => type === SocialProfileType.tiktok).map(({ id }) => id);
  }

  public get socialProfileCheckbox() {
    return {
      get: (() => {
        for (const socialProfile of this.allSocialProfiles) {
          const isSelected = !!this.selectedSocialProfiles.find(({ id }) => id === socialProfile.id);
          if (!isSelected) return false;
        }
        return true;
      })(),

      set: async (selectAll: boolean) => {
        this.isLoading = true;
        const tiktokProfilesIds = [];

        if (selectAll) {
          for (const socialProfile of this.allSocialProfiles) {
            if (socialProfile.type === SocialProfileType.tiktok) {
              tiktokProfilesIds.push(socialProfile.id);
            }
            const isSelected = this.selectedSocialProfiles.find(({ id }) => id === socialProfile.id);
            if (!isSelected) {
              this.selectedSocialProfiles.push(socialProfile);
              await new Promise<void>((r) => setTimeout(r, 90));
            }
          }
        } else {
          while (this.selectedSocialProfiles.length) {
            this.selectedSocialProfiles.splice(0, 1);
            await new Promise<void>((r) => setTimeout(r, 90));
          }
        }
        this.tiktokProfilesSelectedIds.set(tiktokProfilesIds);
        this.isLoading = false;
      }
    }
  }

  private async loadData(loadPostGroups: boolean = false) {
    try {
      this.isLoading = true;

      this.allSocialProfiles = await this.socialProfileService.findAll(this.auth.lastSelectedBrandId());

      if (loadPostGroups) {
        this.data.groups = await this.postGroupService.findAll(this.auth.lastSelectedBrandId());
      }
    } catch (err) {
      console.error(err);
      this.toast.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  // ---------- Data validation ---------- //
  private validateData(validation: 'postGroup' | 'calendar') {
    return (validation === 'postGroup'
      ? this._validatePostGroupsPayload()
      : this._validateCalendarPostPayload()
    );
  }
  private _validatePostGroupsPayload() {
    [
      { condition: !!this.post.postGroup?.toString().length, err: 'The post must be assigned to a post group.' },
      { condition: !!this.post.status?.toString().length, err: 'The status must be selected.' },
      { condition: !!this.post.repetition?.toString().length, err: 'The repetition must be selected.' },
    ].forEach(({ condition, err }) => { if (!condition) throw new Error(err); })

    // Text content must be encoded
    const textContent = GeneralUtils.encodeContent(this.post.textContent || '')

    return {
      type: this.pageType,
      repetition: this.post.repetition!,
      status: this.post.status!,
      ...(this.selectedUpload && { upload: this.selectedUpload._id }),
      textContent,

      postGroup: this.post.postGroup!,
      socialProfiles: null,
      scheduledAt: null,
      createdUserId: this.auth.user!._id,
    };
  }
  private _validateCalendarPostPayload() {
    [
      { condition: !!this.selectedSocialProfiles.length, err: 'At least one social profile must be selected.' },

      // Validate "scheduledAt" only if set (in the future). For "Send now" it will be provided as "null"
      ...(!this.sendLater ? [] : [
        { condition: !isNaN(new Date(this.scheduledAt).getTime()), err: 'Invalid date' },
        { condition: !DateUtils.isAfter(new Date(), new Date(this.scheduledAt)), err: 'Cannot schedule a post in the past' },
      ]),
    ].forEach((({ condition, err }) => { if (!condition) throw new Error(err); }));

    // Text content must be encoded
    const textContent = GeneralUtils.encodeContent(this.post.textContent || '')

    return {
      type: this.pageType,
      repetition: PostRepetition.once,
      status: PostStatus.scheduled,
      ...(this.selectedUpload && { upload: this.selectedUpload._id }),
      textContent,

      postGroup: null,
      socialProfiles: this.selectedSocialProfiles.map(({ id }) => id),
      createdUserId: this.auth.user!._id,
      scheduledAt: (
        (this.sendLater
          ? new Date(this.scheduledAt).toISOString()
          : null)
      ),
    };
  }
  // ---------- \\ Data validation ---------- //
}

type _PostKeys = 'postGroup' | 'repetition' | 'status' | 'upload' | 'textContent';
type FormPostModel = Omit<
  Pick<Post, _PostKeys>,
  'postGroup' | 'repetition' | 'status' | 'upload' | 'textContent'
> & {
  postGroup: string | null;
  repetition: PostRepetition | null;
  status: PostStatus | null;
  upload: string | null;
  textContent: string;
};

function EmptyPost(): FormPostModel {
  return JSON.parse(JSON.stringify({
    postGroup: null,
    status: PostStatus.draft,
    repetition: PostRepetition.once,
    upload: null,
    textContent: '',
  }));
}
