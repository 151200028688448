import { Component } from "@angular/core";

@Component({
    selector: 'app-tiktok-policy',
    standalone: true,
    template: `
    <div class="pt-2 text-gray-400 text-xs">
        <span>By posting, you agree to TikTok's </span>
        <a
            href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
            target="_blank"
            class="text-gray-500"
            >Music Usage Confirmation</a
        >.
    </div>
    `
})
export class TikTokPolicyComponent { }