<div matRipple class="container"
     [appLoading]="isLoading"
     (click)="!files.length ? fileInput.click() : null"
     appDragDrop
     (fileDropped)="onFilesSelected($event)"
>

    @if (files.length > 1) {
      <div class="img-wrapper center">
        <img src="/assets/icons/gallery.png" class="picture" alt="Upload">
      </div>
    } @else if (files.length === 1) {
      <div class="img-wrapper center">
        <img [src]="picture" class="picture" alt="Upload">
      </div>
  } @else {
    <div class="center">
      <i class="icon outlined">file_upload</i>
      <p class="accent">Drop files or tap to upload</p>
    </div>
  }

  <input type="file" #fileInput
         multiple
         [accept]="accept"
         (change)="onFilesSelected($event)"
         style="display: none"
  >

  @if (message) {
    <p class="message">{{ message }}</p>
  }

  @if (files.length || (picture && allowUploadCancel)) {
    <div class="file-info">

      <p class="message filename">
        @if (files.length) {
          <ng-container>{{ message || fileNames }}</ng-container>
        }

        @if (!files.length && (picture && allowUploadCancel)) {
          <ng-container>Remove file</ng-container>
        }
      </p>

      <i class="icon"
         matTooltip="Anulare"
         matTooltipPosition="below"
         (click)="onCancel($event)"
      >close</i>
    </div>
  }
</div>
