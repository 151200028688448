<div class="search-bar-container">
  <button class="drop-down-btn" [matMenuTriggerFor]="options">
    <span>{{ searchTypeText }}</span>
    <i class="icon">expand_more</i>
  </button>

  <input type="text" [(ngModel)]="search"
         placeholder="Search items..."
         (keyup.enter)="onSearch()"
  >

  <button class="drop-down-btn" matRipple (click)="onSearch()">
    <i class="icon">search</i>
  </button>

  <mat-menu #options="matMenu">
    <button class="topNavMenuOptionBtn"
            mat-menu-item
            (click)="onSearchTypeChanged('crt')"
    >
      <i class="icon outlined" [class.hidden]="searchType === 'all'">done</i>
      <span class="text">Current folder</span>
    </button>

    <button class="topNavMenuOptionBtn"
            mat-menu-item
            (click)="onSearchTypeChanged('all')"
    >
      <i class="icon outlined" [class.hidden]="searchType === 'crt'">done</i>
      <span class="text">All folders</span>
    </button>
  </mat-menu>
</div>
