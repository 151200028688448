import { Component, effect, EventEmitter, Input, Output, signal } from '@angular/core';
import { LoadingDirective } from '../../shared/directives/loading.directive';
import { UploadComponent } from '../../shared/components/upload/upload.component';
import { Upload, UploadResult, UploadScope, UploadType } from '../../shared/models/upload';
import { DialogData, TreeNode, UploadFunctionOutputEvent } from '../../shared/models/misc';
import { ToastService } from '../../shared/services/toast.service';
import { UploadService } from '../../shared/services/upload.service';
import { AuthService } from '../../shared/services/auth.service';
import { TreeNodeComponent } from './tree-node/tree-node.component';
import { DatePipe, JsonPipe } from '@angular/common';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { MediaTileComponent } from './media-tile/media-tile.component';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FolderFormDialogComponent } from './folder-form-dialog/folder-form-dialog.component';
import { take } from 'rxjs';
import { MatIconButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { ConfirmationService } from '../../shared/services/confirmation.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrandService } from '../../shared/services/brand.service';
import { PostFormDialogComponent } from '../planner/post-form-dialog/post-form-dialog.component';
import { PostStatus } from '../../shared/models/post-group';
import { Post } from '../../shared/models/post';
import { Router } from '@angular/router';

const _100MB = 100 * 1024 * 1024

@Component({
  selector: 'app-media-library',
  standalone: true,
  imports: [
    LoadingDirective,
    UploadComponent,
    TreeNodeComponent,
    JsonPipe,
    SearchBarComponent,
    MediaTileComponent,
    DatePipe,
    FormsModule,
    MatIconButton,
    MatTooltip,
    NgSelectModule,
  ],
  templateUrl: './media-library.component.html',
  styleUrl: './media-library.component.scss'
})
export class MediaLibraryComponent {

  @Input() isUsedInDialog: boolean = false;
  @Output() uploadSelected = new EventEmitter<Upload>();

  // Path in "tree view" directory
  currentPath: string = '/';
  currentPathParts: { idx: number, value: string }[] = [];
  tree: TreeNode | null = null;
  allFolderPaths: string[] = []; // "My Media" | "My Media/dir1" | "My Media/dir1/dir2"
  newFolderPath: string | null = null;

  _activeNode = signal<TreeNode | null>(this.tree);
  public set activeNode(node: TreeNode | null) {
    this._activeNode.set(node);
    this.selectedUpload = null;
    this.onActiveNodeChanged(node, true);
  }
  public get activeNode() { return this._activeNode(); }

  search = { value: '', type: 'crt' };

  // ----- Media library ----- //
  currentUploadStatus: string;
  uploadFormFiles: File[] = [];
  files: { file: File, dimensions: { widthPx: number, heightPx: number } }[] = [];
  uploads: Upload[] = []; // Content

  // Upload selected from a dir
  selectedUpload: Upload | null = null;

  // UI Utils
  isLoading = false;
  isFileUpdating = false;

  constructor(
    protected auth: AuthService,
    protected toast: ToastService,
    protected uploadService: UploadService,
    protected dialog: MatDialog,
    protected confirmation: ConfirmationService,
    protected brandService: BrandService,
    private router: Router
  ) {
    effect(async () => {
      if (this.auth.lastSelectedBrandId()) {
        await this.loadData();
      }
    });
  }

  public get loading() {
    return this.isLoading || this.isFileUpdating;
  }

  public get savedIn() {
    if (!this.selectedUpload) return '';

    const key = this.selectedUpload.key!;

    const parts = key
      .split('/')
      .filter((val) => !!val.length && val !== UploadScope.media);
    parts.shift(); // Removes the "[brandID]" from the beginning of key ("[brandID]/My Media/...")

    // Is in root directory
    if (parts.length < 2) return UploadScope.media;

    parts.pop(); // Pop "file name/key"
    return parts.pop(); // Dir name
  }

  public onFileInput(event?: UploadFunctionOutputEvent) {
    this.files = (event?.files || []).map((entry) => ({
      file: entry.file!,
      dimensions: { widthPx: entry.widthPx, heightPx: entry.heightPx }
    }));
  }

  public async onSubmitUpload() {
    if (this.loading) return;

    if (!this.files.length) {
      this.toast.error('No file to upload.');
      return;
    }

    const largeFileExists = this.files.some((file) => file.file.size >= _100MB);
    if (largeFileExists) {
      // File size larger than 100 MB
      this.toast.show('Large file detected -- upload might take longer...');
    }

    try {
      this.isLoading = true;

      const totalFilesCount = this.files.length;
      let errorFilesCount = 0;
      const uploadResults: UploadResult[] = [];

      for (const file of this.files) {
        try {
          // Extract file data
          const { name: filename, type: mimetype, size: sizeBytes } = file.file;
          const { widthPx, heightPx } = file.dimensions;

          // Compute upload path ==> "/dir1/dir2/" , "/"
          const prePath = '/' + this.currentPath.split('/').filter((val) => !!val.length && val !== UploadScope.media).join('/');
          const path = prePath.length === 1 ? prePath : `${prePath}/`;

          const result = await this.uploadService.uploadFile(this.auth.lastSelectedBrandId(), file.file, {
            scope: UploadScope.media,
            filename, mimetype,
            sizeBytes, widthPx, heightPx,
            path,
          });

          uploadResults.push(result)
        } catch (err) {
          console.error(err);
          errorFilesCount++;
        }

        // After each upload, update UI
        const _suffix = errorFilesCount !== 1 ? 's' : '';
        this.currentUploadStatus = [
          `Uploaded ${uploadResults.length} / ${totalFilesCount}`,
          ...(!errorFilesCount ? [] : [
            `${errorFilesCount} file${_suffix} failed to upload`,
          ]),
        ].join('; ');
      }


      this.uploadFormFiles = [];
      this.files = [];

      this.toast.success(`Successfully uploaded ${uploadResults.length} files.`);
      return this.loadData();
    } catch (err) {
      this.toast.error(err);
      console.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  public onActiveNodeChanged(node: TreeNode | null, skipSetNode = false) {
    // To avoid infinite loop upon initial load
    if (!skipSetNode) this.activeNode = node;

    if (node) {

      this.currentPath = findNodePath(this.tree!, node.name) || '/';

      this.currentPathParts = this.currentPath
        .split('/')
        .filter((val) => !!val.length && val !== UploadScope.media)
        .map((value, idx) => ({ idx, value }));

      this.loadData(true).then();
    }
  }

  public async onSearch({ value, type }: { value: string, type: 'all' | 'crt' }) {
    if (this.loading) return;

    try {
      this.isLoading = true;

      // Compute search path ==> "/dir1/dir2/" , "/"
      this.search = { value, type };
      const path = (() => {
        if (type === 'all') return '*';

        const parts = this.currentPath
          .split('/')
          .filter((val) => !!val.length && val !== UploadScope.media);

        // If path === "/" return as it is
        if (!parts.length) return '/';

        // Else, add a slash to the end ==> "/dir1/", "/dir1/dir2/"
        return '/' + parts.join('/') + '/';
      })();

      this.uploads = await this.uploadService.searchMedia(this.auth.lastSelectedBrandId(), value, path);

      // Disable "selected file" if it's not included in search results
      if (this.selectedUpload) {
        const idx = this.uploads.findIndex(({ _id }) => _id === this.selectedUpload!._id);
        if (idx === -1) this.selectedUpload = null;
      }

    } catch (err) {
      console.error(err);
      this.toast.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  public onSelectedTile(upload: Upload) {
    this.selectedUpload = (
      this.selectedUpload?._id === upload._id
        ? null
        : upload
    );
  }

  public size(sizeBytes: number) {
    if (sizeBytes <= 1024) return `${sizeBytes} B`;

    const sizeKb = sizeBytes / 1024;
    if (sizeKb <= 1000) return `${sizeKb.toFixed(2)} kB`;

    const sizeMb = sizeKb / 1000;
    return `${sizeMb.toFixed(2)} MB`;
  }

  public async onUpdateFileData() {
    if (this.loading) return;
    if (!this.selectedUpload) return;

    try {
      this.isFileUpdating = true;

      this.selectedUpload = await this.uploadService.updateOne(this.auth.lastSelectedBrandId(), this.selectedUpload!._id, {
        postSuggestedText: this.selectedUpload.postSuggestedText,
        notes: this.selectedUpload.notes
      });

      const uploadArrayRef = this.uploads.find(({ _id }) => _id === this.selectedUpload!._id)!;
      uploadArrayRef.postSuggestedText = this.selectedUpload.postSuggestedText;
      uploadArrayRef.notes = this.selectedUpload.notes;

      this.toast.success('Successfully updated.');
    } catch (err) {
      console.error(err);
      this.toast.error(err);
    } finally {
      this.isFileUpdating = false;
    }
  }

  public onFolderActions() {
    if (!this.activeNode) return;

    // Check if the current "node" is the root directory node
    const isRootDir = this.activeNode === this.tree;

    // Compose current directory path (excluding "root dir" -- aka replacing with "/")
    let currentDirPath = this.currentPath
      .split('/')
      .filter((val) => !!val.length && val !== UploadScope.media)
      .join('/');
    if (currentDirPath.charAt(0) !== '/') currentDirPath = `/${currentDirPath}`;

    this.dialog
      .open<any, DialogData.FolderFormDialog>(FolderFormDialogComponent, {
        autoFocus: false,
        disableClose: true,
        data: { currentDirPath, isRootDir }
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((result: null | true) => {
        if (!result) return;

        this.isLoading = true;
        return this.loadData();
      })
  }

  public onMoveFile() {
    if (!this.selectedUpload) return;
    if (this.loading) return;

    if (!this.newFolderPath?.length) return;

    this.confirmation.confirm({
      title: 'Move file',
      message: `You are about to permanently move file <b>"${this.selectedUpload.filename}"</b> to directory <b>"${this.newFolderPath}"</b>. Are you sure ?`,
      isRawMessage: true,
      noBtnText: 'Cancel',
      yesBtnText: 'Yes, Move',
      onDismiss: () => this.newFolderPath = null,
      onSuccess: async () => {
        try {
          this.isLoading = true;

          const parts = this.newFolderPath!
            .split('/')
            .filter((val) => !!val.length && val !== UploadScope.media);
          const destinationPath = !parts.length ? '/' : `/${parts.join('/')}/`;

          const upload = await this.uploadService.moveFile(this.auth.lastSelectedBrandId(), this.selectedUpload!._id, destinationPath)

          const idx = this.uploads.findIndex(({ _id }) => _id === upload._id);
          if (idx !== -1) this.uploads.splice(idx, 1);

          this.toast.success('File successfully moved.');
          return this.loadData();
        } catch (err) {
          console.error(err);
          this.toast.error(err);
        } finally {
          this.isLoading = false;
        }
      }
    })
  }

  public onDeleteFile() {
    if (!this.selectedUpload) return;
    if (this.loading) return;

    this.confirmation.confirm({
      title: 'Delete File',
      message: 'You are about to delete file <b>"' + this.selectedUpload.filename + '"</b>. Are you sure ?',
      noBtnText: 'Cancel',
      yesBtnText: 'Yes, Delete',
      isRawMessage: true,
      onSuccess: async () => {
        try {
          this.isLoading = true;

          const upload = await this.uploadService.deleteFile(this.auth.lastSelectedBrandId(), this.selectedUpload!._id);

          const idx = this.uploads.findIndex(({ _id }) => _id === upload._id);
          if (idx !== -1) this.uploads.splice(idx, 1);

          this.toast.success('File successfully deleted.');
          return this.loadData();
        } catch (err) {
          console.error(err);
          this.toast.error(err);
        } finally {
          this.isLoading = false;
        }
      }
    });
  }

  public onAddNewPost() {
    if (this.isLoading) return;

    this.dialog
      .open<any, DialogData.PostFormDialog>(PostFormDialogComponent, {
        autoFocus: false,
        disableClose: true,
        panelClass: 'post-form-overlay-dialog',
        data: {
          status: PostStatus.scheduled,
          type: 'calendar',
          uploadFile: this.selectedUpload!,
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (result: null | Post) => {
        if (!result) return;

        if (result.calendarPost) {
          // redirect to calendar
          await this.router.navigate(['/calendar']);
          return;

        }
        // redirect to post groups
        await this.router.navigate(['/post-groups'], { queryParams: { groupId: result.postGroup } });
      });
  }

  public onUseInPost() {
    if (!this.isUsedInDialog) {
      this.onAddNewPost();
      return;
    }

    // Is used in dialog, must emit selected upload
    this.uploadSelected.emit(this.selectedUpload!);
  }

  private async loadDirTree() {
    try {
      const { media: tree, dirs } = await this.uploadService.getMediaDirTree(this.auth.lastSelectedBrandId());
      this.tree = tree;

      this.activeNode = this.tree;
      this.allFolderPaths = dirs;

    } catch (err) {
      console.error(err);
      this.toast.error(err);
    }
  }

  private async loadContent() {
    try {
      const path = (() => {
        if (!this.activeNode) return '/';

        if (!this.currentPathParts.length) return '/';
        return '/' + this.currentPathParts.map(({ value }) => value).join('/') + '/';
      })();

      this.uploads = await this.uploadService.searchMedia(this.auth.lastSelectedBrandId(), this.search.value, path);
    } catch (err) {
      console.error(err);
      this.toast.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  private async loadData(contentOnly: boolean = false) {
    this.isLoading = true;

    this.currentUploadStatus = '';
    this.newFolderPath = null;

    if (contentOnly) return this.loadContent();

    await this.loadDirTree();
    await this.loadContent();
  }

  protected readonly UploadType = UploadType;
}

function findNodePath(tree: TreeNode, name: string, currentPath: string = ''): string | null {
  // Check if the current node is the one we're looking for
  if (tree.name === name) {
    return currentPath + '/' + name;
  }

  // If the current node has children, recursively search through them
  if (tree.children) {

    for (const child of tree.children) {
      // Construct the path for the current child node
      const path = currentPath === ''
        ? tree.name
        : currentPath + '/' + tree.name;

      const foundPath = findNodePath(child, name, path);

      // If the node is found in the subtree, return its path
      if (foundPath !== null) return foundPath;
    }
  }

  // If the node is not found in this subtree, return null
  return null;
}
