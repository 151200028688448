import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { injectTrpcClient } from './trpc/client';
import { BrandTeamMemberDTO } from '@squirrly-social/api-models';

@Injectable({
    providedIn: 'root'
})
export class BrandMemberService {
    constructor(
        protected http: HttpClient,
    ) { }

    protected trpcClient = injectTrpcClient();

    public async removeMember(brandId: string, memberId: string): Promise<any> {
        return this.trpcClient.brandMember.deleteMember.mutate({ brandId, memberId });
    }

    public async getMembersOfBrand(brandId: string): Promise<BrandTeamMemberDTO[]> {
        return await this.trpcClient.brandMember.findAllByBrandId.query({ id: brandId });
    }
}