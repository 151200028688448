<div class="flex w-full flex-col gap-6 p-6">
  <div class="flex items-center justify-between">
    <div class="text-xl">Add Brand</div>
    <mat-icon
      aria-label="close dialog icon"
      fontIcon="close"
      (click)="dialogRef.close(null)"
      matTooltip="Close"
    ></mat-icon>
  </div>

  <div class="grid grid-cols-[160px_1fr] gap-2">
    <label class="whitespace-nowrap pr-4 text-left text-lg">Brand Name:</label>
    <input
      class="rounded border p-2"
      type="text"
      autocomplete="off"
      placeholder="Brand Name"
      [(ngModel)]="brandName"
    />
    <label for="tz" class="pr-4 text-left text-lg">Timezone:</label>
    <select id="tz" class="rounded border p-2" [(ngModel)]="brandTimezone">
      <option value="" disabled selected>Select a timezone</option>
      @for (tz of timezones; track tz) {
        <option [value]="tz">{{ tz }}</option>
      }
    </select>
  </div>

  <div class="mt-4 flex justify-end">
    <button
      class="btn-primary min-w-[96px]"
      mat-flat-button
      (click)="onAddNewBrand()"
    >
      Add
    </button>
  </div>
</div>
