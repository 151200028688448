import { HttpInterceptorFn } from '@angular/common/http';
import { injectAuthService } from '../services/auth.service';
import { catchError, throwError } from 'rxjs';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {

  const authService = injectAuthService()

  return next(req)
    .pipe(
      catchError((err) => {
        const { status } = err;

        if ([401, 403].includes(status)) {
          // Clear auth data & logout
          authService.clearAuthData();
        }

        return throwError(() => err);
      })
    )
};
