import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PostRepetition, PostStatus } from '../models/post-group';
import { lastValueFrom } from 'rxjs';
import { Post } from '../models/post';
import { GeneralUtils } from '../utils/general.utils';
import { injectTrpcClient } from './trpc/client';

@Injectable({
  providedIn: 'root',
})
export class PostService {


  protected endpoint = 'posts';
  protected trpcClient = injectTrpcClient();

  constructor(protected http: HttpClient) { }

  public async findAll(brandId: string, postGroupId: string) {
    const { posts } = await this.trpcClient.post.findAll.query({
      postGroupId,
    });

    // Decode ONCE the text content, so it can be used as it is throughout the app
    return {
      [PostStatus.draft]: posts[PostStatus.draft].map(_decode),
      [PostStatus.approved]: posts[PostStatus.approved].map(_decode),
      [PostStatus.finishedSending]:
        posts[PostStatus.finishedSending].map(_decode),
      [PostStatus.scheduled]: posts[PostStatus.scheduled].map(_decode),
    };
  }

  public async findOne(postId: string) {
    const post = await this.trpcClient.post.findOne.query({
      id: postId,
    });
    const decodedPost = { ...post, textContent: GeneralUtils.decodeContent(post.textContent) };
    return decodedPost;
  }

  public async updateOne(postId: string, data: UpdateOneData) {
    return this.trpcClient.post.updateOne.mutate({
      id: postId,
      status: data.status,
      postGroupId: data.postGroupId,
      repetition: data.repetition,
      uploadId: data.uploadId,
      textContent: data.textContent,
      socialProfileIds: data.socialProfiles,
      sendNow: data.sendNow,
      scheduledAt: data.scheduledAt,
    });

  }

  public async updateApprovedPostOrder(postId: string, order: number) {
    return this.trpcClient.post.updateOrderInTheGroup.mutate({
      postId,
      order,
    });
  }

  public async copyOne(postId: string) {
    return this.trpcClient.post.copy.mutate({ id: postId });
  }

  public async createOne(brandId: string, data: CreateOneData) {
    const post = await this.trpcClient.post.create.mutate({
      brandId,
      type: data.type,
      repetition: data.repetition,
      status: data.status,
      uploadId: data.upload ?? null,
      textContent: data.textContent,
      postGroupId: data.postGroup,
      socialProfileIds: data.socialProfiles,
      scheduledAt: data.scheduledAt,
      createdUserId: data.createdUserId,
    });

    return post;
  }
  async movePostToApprove(id: string) {
    return this.trpcClient.post.movePostToApproved.mutate({ postId: id });
  }
  async movePostToDraft(id: string) {
    return this.trpcClient.post.movePostToDraft.mutate({ postId: id });
  }

  public async deleteOne(postId: string) {
    return this.trpcClient.post.delete.mutate({ id: postId });
  }

  public async createTinyUrl(brandId: string, rawUrl: string) {
    const result = await lastValueFrom(
      this.http.post<{ url: string }>(
        `${this.endpoint}/tinyurl`,
        { url: rawUrl },
        {
          params: { brandId },
        }
      )
    );

    return result.url;
  }

  public async sendTwitterPost(postId: string) {
    return this.trpcClient.postSocial.sendToTwitter.mutate({ postId });
  }
}

function _decode(post: Post): Post {
  return { ...post, textContent: GeneralUtils.decodeContent(post.textContent) };
}

type CreateOneData = {
  type: 'calendar' | 'postGroup';
  repetition: PostRepetition;
  status: PostStatus;
  upload?: string | null;
  textContent: string;

  postGroup: string | null;
  socialProfiles: string[] | null;
  scheduledAt: string | null;
  createdUserId: string;
};

type UpdateOneData = {
  status: PostStatus | null;
  postGroupId: string | null;
  repetition: PostRepetition | null;
  uploadId: string | null;
  textContent: string;

  socialProfiles: string[] | null;
  sendNow: boolean | null;
  scheduledAt: string | null;

};

type FindAllPostsResult = { posts: { [key in PostStatus]: Post[] } };
