import { Component, effect, signal } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { LoadingDirective } from '../../../shared/directives/loading.directive';
import { CommonModule } from '@angular/common';
import { injectTrpcClient } from '../../../shared/services/trpc/client';
import { AuthService } from '../../../shared/services/auth.service';
import { ToastService } from '../../../shared/services/toast.service';

@Component({
  selector: 'app-next-level-marketing-settings',
  standalone: true,
  imports: [FormsModule, LoadingDirective, CommonModule, ReactiveFormsModule],
  templateUrl: './next-level-marketing-settings.component.html',
  styleUrl: './next-level-marketing-settings.component.scss',
})
export class NextLevelMarketingSettingsComponent {
  form: FormGroup;
  isLoading = false;

  NextLevelMarketingStatus = NextLevelMarketingStatus;
  statusApp = signal<NextLevelMarketingStatus>(
    NextLevelMarketingStatus.DONTEXIST,
  );

  trpcClient = injectTrpcClient();
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private toast: ToastService,
  ) {
    this.form = this.fb.group({
      connectionKey: ['', Validators.required],
    });

    effect(async () => {
      await this.updateStatus(this.authService.lastSelectedBrandId());
    });
  }

  async updateStatus(brandId: string) {
    const { status } =
      await this.trpcClient.nextLevelMarketing.getConnectionForCurrentUser.query(
        {
          brandId: brandId,
        },
      );
    if (status == null) {
      this.statusApp.set(NextLevelMarketingStatus.DONTEXIST);
      return;
    }
    this.statusApp.set(status as any);
  }

  async onSubmit() {
    if (!this.form.valid) return;
    const token = this.form.value.connectionKey;
    this.isLoading = true;
    try {
      await this.trpcClient.nextLevelMarketing.connectingByToken.mutate({
        brandId: this.authService.lastSelectedBrandId(),
        token: token,
      });
      await this.updateStatus(this.authService.lastSelectedBrandId());
    } catch (err) {
      this.toast.error(err);
    } finally {
      this.isLoading = false;
      this.form.reset();
    }
  }

  getStatusText(status: NextLevelMarketingStatus) {
    switch (status) {
      case NextLevelMarketingStatus.DONTEXIST:
      case NextLevelMarketingStatus.DISCONNECTED:
        return 'NOT CONNECTED';
      default:
        return status;
    }
  }
}

const NextLevelMarketingStatus = {
  CONNECTED: 'CONNECTED',
  DISCONNECTED: 'DISCONNECTED',
  DONTEXIST: 'DONTEXIST',
} as const;

type NextLevelMarketingStatus =
  (typeof NextLevelMarketingStatus)[keyof typeof NextLevelMarketingStatus];
