<section class="sqs-page">
  <div class="menu max-w-64">
    <div class="menu-item header">Settings</div>
    <div
      class="menu-item group"
      routerLink="profile"
      routerLinkActive="selected"
    >
      <mat-icon
        class="opacity-40 group-[.selected]:opacity-100"
        aria-label="person icon"
        fontIcon="person"
      ></mat-icon>
      <span>Person</span>
    </div>
    <div
      class="menu-item group"
      routerLink="brands"
      routerLinkActive="selected"
    >
      <mat-icon
        class="opacity-40 group-[.selected]:opacity-100"
        aria-label="brands icon"
        fontIcon="store"
      ></mat-icon>
      <span>Brands</span>
    </div>
    <div
      class="menu-item group"
      routerLink="brand-members"
      routerLinkActive="selected"
    >
      <mat-icon
        class="opacity-40 group-[.selected]:opacity-100"
        aria-label="team members icon"
        fontIcon="groups"
      ></mat-icon>
      <span>Team members</span>
    </div>
    <div
      class="menu-item group"
      routerLink="brand-invitations"
      routerLinkActive="selected"
    >
      <mat-icon
        class="opacity-40 group-[.selected]:opacity-100"
        aria-label="brand invitations icon"
        fontIcon="mail"
      ></mat-icon>
      <span>Brand invitations</span>
    </div>
    <div
      class="menu-item group"
      routerLink="api-keys"
      routerLinkActive="selected"
    >
      <mat-icon
        class="opacity-40 group-[.selected]:opacity-100"
        aria-label="api key icon"
        fontIcon="key"
      ></mat-icon>
      <span>API Keys</span>
    </div>

    <div
      class="menu-item group"
      routerLink="next-level-marketing-settings"
      routerLinkActive="selected"
    >
      <mat-icon
        class="opacity-40 group-[.selected]:opacity-100"
        aria-label="next level marketing connect icon"
        fontIcon="linked_services"
      ></mat-icon>
      <span>Next Level Marketing</span>
    </div>
    <div
      class="menu-item group"
      routerLink="chatgpt-settings"
      routerLinkActive="selected"
    >
      <mat-icon
        class="opacity-40 group-[.selected]:opacity-100"
        aria-label="chatgpt connect icon"
      >
        <img src="assets/icons/openai-logomark.png" alt="chatgpt icon" />
      </mat-icon>
      <span>ChatGPT</span>
    </div>
  </div>

  <div class="content">
    <router-outlet />
  </div>
</section>
