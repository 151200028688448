import { PostRepetition, PostStatus } from './post-group';
import { SocialProfileType } from './social-profile';


export const PostSendingStatus = {
  PENDING: 'PENDING',
  DEV_PENDING: 'DEV_PENDING',
  SENDING: 'SENDING',
  SENT: 'SENT',
  FAILED: 'FAILED',
} as const;
export type PostSendingStatus = typeof PostSendingStatus[keyof typeof PostSendingStatus];
export interface Post {
  id: string;

  brand: string;
  postGroup: null | string; // Will be null for 'calendar' posts

  // Will be null/empty for 'post group' posts
  calendarPost: null | {
    scheduledAt: null | string;
    socialProfileIds: null | string[];
    socialProfiles:
    | null
    | {
      id: string;
      externalId: string;
      type: SocialProfileType;
      data: {
        name: string;
      }
    }[];
  };

  status: PostStatus;
  sendingStatus: PostSendingStatus;

  repetition: PostRepetition;

  upload: null | string;
  uploadFile: null | {
    type: string;
    filename: string;
  };
  textContent: string;
  uploadUrl: null | string;

  createdUserId: string;
  createdUser: null | {
    id: string;
    name: string;
  };
}
