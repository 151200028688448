<div class="m-8 flex flex-col justify-center gap-4" [appLoading]="isLoading">
  <h2 class="text-2xl font-bold">Select LinkedIn Pages</h2>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? toggleAllRows() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
          [aria-label]="checkboxLabel(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Profile Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Profile Name</th>
      <td mat-cell *matCellDef="let element">
        <div class="flex items-center gap-2">
          @if (element.profilePictureUrl && element.profilePictureUrl.length > 0) {
            <img
              [src]="element.profilePictureUrl"
              [alt]="element.username"
              class="h-10 w-10 rounded-full"
            />
          } @else {
            <img
              src="/assets/img/user-placeholder.png"
              [alt]="element.username"
              class="h-10 w-10 rounded-full"
            />
          }
          <span class="text-sm">{{ element.name }}</span>
        </div>
      </td>
    </ng-container>

    <!-- Profile Type Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Profile Type</th>
      <td mat-cell *matCellDef="let element">
        <div class="flex items-center gap-2">
          <img
            [src]="'/assets/icons/' + linkedinProfile.icon"
            [alt]="linkedinProfile.name + 'Logo'"
            class="w-5"
          />
          <span class="text-sm">{{ linkedinProfile.label }}</span>
        </div>
      </td>
    </ng-container>

    <tr
      class="!text-lg !font-semibold"
      mat-header-row
      *matHeaderRowDef="displayedColumns"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="selection.toggle(row)"
    ></tr>
  </table>
  <div class="flex items-center justify-end">
    <button class="btn-primary !w-24" mat-flat-button (click)="handleSaveButton()">Save</button>
  </div>
</div>
