<section
  class="flex flex-col gap-6 px-5 py-10"
  [appLoading]="isAddingNewsToPostGroup"
>
  <div class="flex-1">
    <h1 class="text-3xl font-bold">Social Media Assistant</h1>
    <p class="text-gray-500">
      Browser media content to share with your audience
    </p>
  </div>

  <form class="flex items-center gap-2" (submit)="onSearch($event)">
    <input
      type="text"
      class="input max-w-lg"
      name="search"
      placeholder="Search for news"
    />
    <div
      matTooltip="Select an interval for the results:"
      matTooltipPosition="above"
    >
      <ng-select
        [items]="sortNewsIntervalOptions"
        [searchable]="false"
        [clearable]="false"
        [multiple]="false"
        [(ngModel)]="selectedNewsIntervalOption"
        [ngModelOptions]="{ standalone: true }"
        class="min-w-[150px]"
      >
      </ng-select>
    </div>
    <div
      matTooltip="Select a country for the results:"
      matTooltipPosition="above"
    >
      <ng-select
        [items]="sortNewsCountryOptions"
        [searchable]="false"
        [clearable]="false"
        [multiple]="false"
        [(ngModel)]="selectedNewsCountryOption"
        [ngModelOptions]="{ standalone: true }"
        class="min-w-[150px]"
      >
      </ng-select>
    </div>
    <div
      matTooltip="Choose the languages you'd like the results in:"
      matTooltipPosition="above"
    >
      <ng-select
        [items]="sortNewsLanguageOptions"
        [searchable]="false"
        [multiple]="true"
        [(ngModel)]="selectedNewsLanguageOptions"
        [ngModelOptions]="{ standalone: true }"
        placeholder="Choose at least one language"
        class="min-w-[250px] !text-sm"
      >
      </ng-select>
    </div>
    <button class="btn btn-primary ml-6 !w-28" type="submit">Search</button>
  </form>
  @if (currentNumberOfNews === 0 && loading === false) {
    <div class="flex items-center justify-center">
      <div
        class="rounded-lg bg-stone-300 p-8 text-center text-gray-500 shadow-lg"
      >
        @if (searchData === "") {
          <p class="text-lg font-semibold">
            Please enter a keyword to search for news!
          </p>
        } @else {
          <p class="text-lg font-semibold">
            No more news found for the keyword: {{ searchData }}
          </p>
        }
      </div>
    </div>
  }
  @if (loading === true) {
    <app-social-media-skeleton-loading
      [itemsCount]="pageSize"
    ></app-social-media-skeleton-loading>
  }
  <div class="flex flex-col gap-2">
    <!-- news post -->
    @for (news of newsResponse.items; track news) {
      <div class="flex flex-col gap-2 rounded bg-white p-4">
        <div class="grid grid-cols-2 gap-2">
          <div class="flex flex-col gap-2">
            <a [href]="news.url" target="_blank">
              <h2 class="text-xl font-bold">
                {{ news.title }}
              </h2>
            </a>
            <p class="text-gray-500">
              <span class="font-bold">Elab. Date: </span
              >{{ news.publishedOn | date: "short" }}
            </p>
            <hr />
            <div class="ml-2 flex items-center gap-2">
              <img
                [src]="news.sourceIconUrl || getFavicon(news.source)"
                alt="favicon"
                [appImgFallback]="defaultNewsFavicon"
                class="h-[30px] w-[30px] rounded-full"
              />
              <p class="text-gray-500">
                {{ news.source }}
              </p>
            </div>
            <hr />
          </div>
          <img
            [src]="news.image || defaultNewsImage"
            alt="News Image"
            class="h-[200px] w-[400px] justify-self-end"
          />
        </div>
        <div class="flex items-center gap-2">
          <button
            class="btn btn-primary whitespace-nowrap"
            (click)="handleAddNewsToPostGroup(news)"
          >
            Add to
          </button>
          <div class="ng-select-material">
            <ng-select
              [items]="groups"
              [searchable]="false"
              [clearable]="false"
              [multiple]="false"
              bindValue="id"
              [(ngModel)]="selectedGroupId"
              (ngModelChange)="onPostGroupChange($event)"
              class="min-w-[200px]"
            >
              <ng-template ng-label-tmp let-item="item">
                {{ item.name }}
              </ng-template>
              <ng-template ng-option-tmp let-item="item">
                {{ item.name }}
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>
    }
  </div>
  <div class="flex w-full items-center justify-between">
    <button
      class="btn btn-primary disabled:cursor-not-allowed disabled:opacity-50"
      (click)="handlePreviousPage()"
      [disabled]="currentNumberOfNews === 0"
    >
      Previous Page
    </button>
    @if (
      !thereAreMoreNews && currentPage === lastPage && currentNumberOfNews > 0
    ) {
      <div
        class="rounded-lg bg-stone-300 p-8 text-center text-gray-500 shadow-lg"
      >
        <p class="text-lg font-semibold">There are no more news to show</p>
      </div>
    }
    <button
      class="btn btn-primary disabled:cursor-not-allowed disabled:opacity-50"
      (click)="handleNextPage()"
      [disabled]="currentNumberOfNews === 0 || currentPage === lastPage"
    >
      Next Page
    </button>
  </div>
</section>
