<section class="login-page">
  <!-- Left side -- Form -->
  <div class="left" [appLoading]="isLoading">
    <a class="logo" href="https://social.squirrly.co">
      <img src="/assets/img/logo-login.png" alt="Squirrly Social Logo" />
    </a>

    <h1>Register</h1>

    <div class="form">
      <input
        type="text"
        autocomplete="off"
        placeholder="Brand Name"
        [(ngModel)]="userData.brandName"
      />

      <span class="timezone">
        <label for="tz">Timezone</label>
        <select id="tz" [(ngModel)]="userData.timezone">
          @for (tz of timezones; track tz) {
            <option [value]="tz">{{ tz }}</option>
          }
        </select>
      </span>

      <input type="text" placeholder="Your Name" [(ngModel)]="userData.name" />

      <input type="email" placeholder="E-mail" [(ngModel)]="userData.email" />

      <input
        type="password"
        placeholder="Password"
        [(ngModel)]="userData.password"
      />
      <input
        type="password"
        placeholder="Confirm Password"
        [(ngModel)]="confirmPassword"
      />

      <div class="terms">
        <span>By registering you accept our</span>
        <a [href]="serviceTermsURL" target="_blank">Terms of Service</a>
      </div>

      <button class="btn-primary" (click)="onSubmit()">
        <div>
          <i class="icon sharp">check_circle</i>
          <span>Register</span>
        </div>
      </button>
    </div>
  </div>

  <!-- Right side -- Banner -->
  <div class="right">
    <div class="links">
      <a [href]="serviceTermsURL" target="_blank">Terms of Service</a>
      <a [href]="privacyPolicyURL" target="_blank">Privacy Policy</a>
    </div>
  </div>
</section>
