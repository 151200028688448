<div class="container" [class.selected]="selected">
  <div class="img-container">
    @if (upload.type === UploadType.image) {
      <img [src]="upload.url" [alt]="upload.filename">
    }

    @if (upload.type === UploadType.video) {
      <img class="vid-icon" src="/assets/icons/video.png" alt="Video Logo overlay">
      <video preload="metadata">
        <source [src]="upload.url + '#t=0.1'" type="video/mp4">
      </video>
    }
  </div>

  <div class="bottom">
    <div class="file-type">
      <span>{{ upload.type | uppercase }}</span>
    </div>

    @if (selected) {
      <i class="icon filled">check_circle</i>
    } @else {
      <i class="icon outlined">check_circle</i>
    }
  </div>
</div>
