import { Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { injectTrpcClient } from './trpc/client';
import { BrandDTO, BrandInvitationDTO, BrandTeamMemberDTO, BrandWithUserNameDTO } from '@squirrly-social/api-models';

@Injectable({
  providedIn: 'root'
})
export class BrandService {

  protected endpoint = 'brands';
  protected trpcClient = injectTrpcClient();

  _brandsOfCurrentUser = signal<BrandWithUserNameDTO[]>([]);
  public get brandsOfCurrentUser() {
    return this._brandsOfCurrentUser();
  }
  public set brandsOfCurrentUser(brands: BrandWithUserNameDTO[]) {
    this._brandsOfCurrentUser.set(brands);
  }

  addNewDialogOpen = signal<boolean>(false);

  constructor(
    protected http: HttpClient,
  ) { }

  public async loadBrandsOfCurrentUser() {
    this.brandsOfCurrentUser = await this.findAll();
  }

  public async findAll(): Promise<BrandWithUserNameDTO[]> {
    return this.trpcClient.brand.findAllAccessible.query();
  }

  public async findOne(brandId: string): Promise<BrandWithUserNameDTO> {
    return this.trpcClient.brand.findOne.query({ id: brandId });
  }

  public async updateOne(brandId: string, data: { name: string, timezone: string }): Promise<BrandDTO> {
    return this.trpcClient.brand.update.mutate({
      id: brandId,
      data
    });
  }

  public async createOne(data: { name: string, timezone: string }): Promise<BrandDTO> {
    return this.trpcClient.brand.create.mutate(data);
  }

  public async deleteOne(brandId: string): Promise<BrandDTO> {
    return this.trpcClient.brand.delete.mutate({ id: brandId });
  }
}
