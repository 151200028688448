import {
  Component,
  type QueryList,
  ViewChildren,
} from '@angular/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { Router, RouterOutlet } from '@angular/router';
import { AuthService, authToken } from './shared/services/auth.service';
import { ConfirmationService } from './shared/services/confirmation.service';
import { CreatePostUiService } from './shared/services/create-post-ui.service';
import { ToastService } from './shared/services/toast.service';
import { SidenavComponent } from "./shared/components/sidenav/sidenav.component";
import { BrandService } from './shared/services/brand.service';
import { BrandWithUserNameDTO } from '@squirrly-social/api-models';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, MatMenuModule, MatIconButton, MatButton, SidenavComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {

  @ViewChildren(MatMenuTrigger) menuTriggers?: QueryList<MatMenuTrigger>;
  // @ViewChild(MatMenuTrigger) brandMenuTrigger?: MatMenuTrigger;
  // @ViewChild(MatMenuTrigger) userMenuTrigger?: MatMenuTrigger;

  constructor(
    protected auth: AuthService,
    protected toast: ToastService,
    protected router: Router,
    protected confirmation: ConfirmationService,
    protected createPostUi: CreatePostUiService,
    protected brandService: BrandService
  ) { }

  public get isUserAuthenticated() {
    return this.auth.user != null && authToken() != null;
  }

  showCreatePostButton() {
    return (
      ['/calendar', '/post-groups'].findIndex((item) =>
        this.router.url.startsWith(item)
      ) !== -1
    );
  }

  public onNewPost() {
    this.createPostUi.createPostFromRoot();
  }

  public onLogout() {
    this.confirmation.confirm({
      title: 'Log out',
      message:
        'You are about to be logged out. Are you sure you want to take this action ?',
      noBtnText: 'Cancel',
      yesBtnText: 'Yes, Logout',
      onSuccess: async () => {
        await this.auth.logout();
        this.toast.success('Successfully logged out.');
      },
    });
  }

  public onToggleMenu(type: 'user' | 'brand') {
    const idx = ['brand', 'user'].indexOf(type);

    if (idx === -1) {
      this.toast.error(`Unhandled Menu "${type}"`);
      return;
    }

    const trigger = this.menuTriggers!.get(idx)!;

    return trigger.menuOpen ? trigger.closeMenu() : trigger.openMenu();
  }

  public onAccountSettings() {
    return this.router.navigate(['/account-settings']);
  }

  public async onChangeBrand(brandId: string) {
    await this.brandService.loadBrandsOfCurrentUser();
    if (!this.brandService.brandsOfCurrentUser.find((brand: BrandWithUserNameDTO) => brand.id === brandId)) {
      this.toast.error('You are not anymore a member of this brand');
      return;
    }
    await this.auth.updateUserSelectedBrand(brandId);
  }

  public handleAddNewBrand() {
    this.brandService.addNewDialogOpen.set(true);
    return this.router.navigate(['/account-settings/brands']);
  }
}
