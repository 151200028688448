import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TreeNode } from '../../../shared/models/misc';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-tree-node',
  standalone: true,
  imports: [
    MatTooltip,
  ],
  templateUrl: './tree-node.component.html',
  styleUrl: './tree-node.component.scss'
})
export class TreeNodeComponent implements OnChanges {
  @Input({ required: true })
  node: TreeNode;

  @Input({ required: true })
  activeNode: TreeNode | null;

  @Output()
  activeNodeChange = new EventEmitter<TreeNode | null>();

  @Output()
  onFolderSettings = new EventEmitter<void>();

  isExpanded = false;

  public ngOnChanges(changes: SimpleChanges): void {
    const activeNode = changes['activeNode'].currentValue || null;

    this.activeNode = activeNode;
    if (activeNode && activeNode.name === this.node.name) this.isExpanded = true;
  }

  public get folderIcon() {
    return this.isExpanded ? 'drive_file_move' : 'folder';
  }

  public get name() {
    const { name } = this.node;

    if (name.length <= 23) return name;
    return name.slice(0, 20) + '...';
  }

  public get directoryChildren() {
    return this.node.children!.filter((elem) => elem.children != null);
  }

  public get isSelected() {
    if (!this.activeNode?.name.length) return false;
    return this.activeNode.name === this.node.name;
  }

  public onSelectDirectory() {
    if (!this.isExpanded) this.isExpanded = true;
    // this.isExpanded = !this.isExpanded;

    this.activeNode = this.isExpanded ? this.node : null;
    this.activeNodeChange.emit(this.activeNode);
  }

  public onActiveNodeChanged(node: TreeNode | null) {
    this.activeNode = node;
    this.activeNodeChange.emit(this.activeNode);
  }
}
