<section class="post-group-schedule-dialog" [appLoading]="isLoading">
  <div class="header">
    <h4>Schedule</h4>

    <i class="icon outlined" matTooltip="Close" (click)="dialogRef.close(null)"
      >close</i
    >
  </div>

  <hr />

  <div class="content">
    <div class="days">
      @for (day of DAYS_OF_WEEK; track day.value) {
        <button
          class="sqs-button"
          [class.success]="isDayIncluded(day.value)"
          (click)="onDaySelected(day.value)"
        >
          {{ day.label }}
        </button>
      }
    </div>

    <div class="form-group">
      <div class="row">
        <label>Schedule times</label>

        <button class="sqs-button transparent" (click)="onNewScheduleTime()">
          <i class="icon">add</i>
          <span>Add time</span>
        </button>
      </div>

      <ul>
        @for (
          entry of scheduleTimes;
          track entry.hh + entry.mm + entry.period
        ) {
          <li>
            <div class="hh-mm">
              <select [(ngModel)]="entry.hh">
                @for (hour of HOURS; track hour) {
                  <option [ngValue]="hour">{{ hour | zeroPad }}</option>
                }
              </select>
              <b>&nbsp;:&nbsp;</b>

              <select [(ngModel)]="entry.mm">
                @for (min of MINUTES; track min) {
                  <option [ngValue]="min">{{ min | zeroPad }}</option>
                }
              </select>

              <b>&nbsp;&nbsp;&nbsp;</b>

              <select [(ngModel)]="entry.period">
                @for (period of PERIOD; track period) {
                  <option [ngValue]="period">{{ period }}</option>
                }
              </select>
            </div>

            <button
              class="sqs-button transparent"
              (click)="onRemoveScheduleTime(entry)"
              matTooltip="Remove time"
            >
              <i class="icon">close</i>
              <span>Remove</span>
            </button>
          </li>
        } @empty {
          <div>
            <i><u>No schedule times were set.</u></i>
          </div>
        }
      </ul>
    </div>
  </div>

  <hr />

  <div class="actions">
    <button class="sqs-button transparent" (click)="dialogRef.close(null)">
      Cancel
    </button>

    <button class="sqs-button primary" (click)="onSubmit()">Save</button>
  </div>
</section>
