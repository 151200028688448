import dayjs from 'dayjs';
import dayjsTimezone from 'dayjs/plugin/timezone';
import dayjsUtc from 'dayjs/plugin/utc';
import dayjsAdvancedFormat from 'dayjs/plugin/advancedFormat';
import dayjsCustomParseFormat from 'dayjs/plugin/customParseFormat';
import { ManipulateType, OpUnitType, QUnitType } from 'dayjs';
import { DateString } from '../models/misc';

dayjs.extend(dayjsUtc);
dayjs.extend(dayjsTimezone);
dayjs.extend(dayjsAdvancedFormat);
dayjs.extend(dayjsCustomParseFormat);

export class DateUtils {

  public static get timezone() {
    return dayjs.tz.guess();
  }

  public static get AllTimezones() {
    const supportedIntlTimezones = Intl.supportedValuesOf('timeZone');

    return supportedIntlTimezones.filter(
      (tz) => DateUtils.validateTimezoneString(tz)
    );
  }

  public static validateTimezoneString(tz: string): boolean {
    try {
      const now = dayjs().tz(tz).format();
      return !isNaN(Date.parse(now));
    } catch {
      // Error is thrown if timezone string is invalid
      return false;
    }
  }

  public static formatDate(date: Date, format = 'YYYY-MM-DD') {
    return dayjs(date).format(format);
  }

  public static add(unitsPastNow: number = 60, unit: ManipulateType = 'minutes', from: Date = new Date()): Date {
    return dayjs(from).add(unitsPastNow, unit).toDate();
  }

  public static endOfDay(date: Date = new Date()) {
    return dayjs(date).endOf('day').toDate();
  }

  public static getStartEndOfDate(date: Date = new Date()) {
    return {
      start: dayjs(date).startOf('day').toDate(),
      end: dayjs(date).endOf('day').toDate(),
    };
  }

  public static getWeekDayDateStrings(date: Date): DateString[] {
    const startOfWeek = dayjs(date)
      .startOf('week')
      .add(1, 'day') // So the first day is monday
      .add(6, 'hours');

    return [0, 1, 2, 3, 4, 5, 6].map(
      (day) => dayjs(startOfWeek)
        .add(day, 'day')
        .toISOString()
        .slice(0, 10)
    );
  }

  public static isAfter(date1: string | Date, date2: string | Date): boolean {
    if (typeof date1 === 'string') date1 = new Date(date1);
    if (typeof date2 === 'string') date2 = new Date(date2);

    return dayjs(date1).isAfter(dayjs(date2));
  }

  public static isEquals(date1: string | Date, date2: string | Date): boolean {
    if (typeof date1 === 'string') date1 = new Date(date1);
    if (typeof date2 === 'string') date2 = new Date(date2);

    return dayjs(date1).isSame(dayjs(date2));
  }

  public static convertFromLocalToGivenTimezone(date: string, timezone: string, format: string = 'YYYY-MM-DDTHH:mm') {
    return dayjs(date).tz(timezone).format(format);
  }

}
