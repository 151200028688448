import { BaseModel } from './base-model';

export enum UserStatus {
  pending = 'pending',
  active = 'active',
  suspended = 'suspended',
}

export interface User extends BaseModel {
  name: string;

  email: string;
  password?: string;

  status: UserStatus;

  lastSelectedBrandId: string;
}

interface UserResponse {
  id: string;
  name: string;
  email: string;
  status: string;
  lastSelectedBrandId: string | null;
  createdAt: string;
  updatedAt: string;

}

export function mapToUser(currentUser: UserResponse): User {
  return { ...currentUser, _id: currentUser.id, status: currentUser.status as UserStatus, lastSelectedBrandId: currentUser.lastSelectedBrandId!, createdAt: new Date(currentUser.createdAt), updatedAt: new Date(currentUser.updatedAt) };
}


