import { Component, Input } from '@angular/core';
import {
  SocialProfile,
  SocialProfileType,
} from '../../../shared/models/social-profile';

@Component({
  standalone: true,
  selector: 'social-profile-item',
  template: `
    <div class="flex flex-row gap-2 items-center">
      <img [src]="icon(profile.type)" [alt]="profile.type" class="size-6" />
      <span>{{ profile.data.name || '' }}</span>
    </div>
  `,
})
export class SocialProfileItemComponent {
  @Input({ required: true })
  profile: SocialProfile;

  public icon(type: SocialProfileType) {
    return (
      '/assets/icons/fa-' +
      {
        [SocialProfileType.twitter]: 'twitter.svg',
        [SocialProfileType.tiktok]: 'tiktok.svg',
        [SocialProfileType.linkedinPerson]: 'linkedin.svg',
        [SocialProfileType.linkedinPage]: 'linkedin.svg',
        [SocialProfileType.instagramBusinessPage]: 'instagram.svg',
        [SocialProfileType.googleMyBusiness]: 'google.svg',
        [SocialProfileType.facebookGroup]: 'facebook.svg',
        [SocialProfileType.facebookPage]: 'facebook.svg',
        [SocialProfileType.youtube]: 'youtube.svg',
      }[type]
    );
  }
}
