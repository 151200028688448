import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { SocialProfile, SocialProfileData, SocialProfileDto, SocialProfileType } from '../models/social-profile';
import { injectTrpcClient } from './trpc/client';
import { SocialProfileDialogElementDTO } from '@squirrly-social/api-models';

@Injectable({
  providedIn: 'root'
})
export class SocialProfileService {

  protected endpoint = 'social-profiles';
  protected trpcClient = injectTrpcClient();
  constructor(protected http: HttpClient) { }

  PROFILES: SocialProfileEntry[] = this._getProfiles();
  PROFILE_MAP = this.PROFILES.reduce((acc, profile) => {
    acc[profile.id] = profile;
    return acc;
  }, {} as Record<SocialProfileType, SocialProfileEntry>);


  public async findAll(brandId: string, includeNameOfUserWhoAdded = false): Promise<SocialProfile[]> {
    const response = await this.trpcClient.socialProfileRouter.findAll.query({ brandId, includeNameOfUserWhoAdded });
    const socialProfiles: SocialProfile[] = response.map((profile: SocialProfileApi) => ({
      ...profile,
      expires: profile.expires ? new Date(profile.expires) : null, createdAt: new Date(profile.createdAt),
      updatedAt: new Date(profile.updatedAt), nameOfUserWhoAdded: profile.addedByObject?.name
    }));
    return socialProfiles;
  }

  public async deleteOne(brandId: string, socialProfileId: string) {
    const { socialProfile } = await lastValueFrom(
      this.http.delete<{ socialProfile: SocialProfileDto }>(`${this.endpoint}/${socialProfileId}`, {
        params: { brandId }
      })
    );

    return socialProfile;
  }

  public async deleteMany(brandId: string, socialProfileIds: string[]) {
    const result = await this.trpcClient.socialProfileRouter.deleteMany.mutate({ brandId, socialProfileIds });
    return result;
  }

  public async getTikTokCreatorInformation(socialProfileId: string) {
    const response = await this.trpcClient.socialProfileRouter.getTiktokCreatorInformation.query({ socialProfileId });
    return response;
  }

  public async getLastLinkedinPagesAdded(brandId: string): Promise<SocialProfileDialogElementDTO[]> {
    const response = await this.trpcClient.socialProfileRouter.getLastLinkedinPagesAdded.query({ brandId });
    return response;
  }

  private _getProfiles() {
    return [
      {
        // link: "https://go.cosendconnect.com/social.squirrly.co/571/678/connect/facebook/page",
        id: SocialProfileType.facebookPage,
        icon: 'fa-facebook.svg',
        label: 'Facebook',
        name: 'Facebook', // alt attribute
      },
      // {
      //   // link: "https://go.cosendconnect.com/social.squirrly.co/571/678/connect/facebook/group",
      //   id: 2,
      //   icon: 'fa-facebook.svg',
      //   label: 'Facebook Group',
      //   name: 'Facebook', // alt attribute
      // },
      // {
      //   // link: "https://go.cosendconnect.com/social.squirrly.co/571/678/connect/google/mybusiness",
      //   id: 3,
      //   icon: 'fa-google.svg',
      //   label: 'Google My Business',
      //   name: 'Google', // alt attribute
      // },
      {
        // link: "https://go.cosendconnect.com/social.squirrly.co/571/678/connect/instagram/business-page",
        id: SocialProfileType.instagramBusinessPage,
        icon: 'fa-instagram.svg',
        label: 'Instagram Business Page',
        name: 'Instagram', // alt attribute
      },
      {
        // link: "https://go.cosendconnect.com/social.squirrly.co/571/678/connect/linkedin-v2/page",
        id: SocialProfileType.linkedinPage,
        icon: 'fa-linkedin.svg',
        label: 'LinkedIn Page',
        name: 'LinkedIn', // alt attribute
      },
      {
        // link: "https://go.cosendconnect.com/social.squirrly.co/571/678/connect/linkedin-v2/profile",
        id: SocialProfileType.linkedinPerson,
        icon: 'fa-linkedin.svg',
        label: 'LinkedIn Person',
        name: 'LinkedIn', // alt attribute
      },
      {
        id: SocialProfileType.tiktok,
        icon: 'fa-tiktok.svg',
        label: 'Tiktok',
        name: 'TikTok', // alt attribute
      },
      {
        id: SocialProfileType.twitter,
        icon: 'fa-twitter.svg',
        label: 'Twitter',
        name: 'Twitter', // alt attribute
      },
      {
        id: SocialProfileType.googleMyBusiness,
        icon: 'fa-google.svg',
        label: 'Google My Business',
        name: 'Google', // alt attribute
      },
      {
        id: SocialProfileType.youtube,
        icon: 'fa-youtube.svg',
        label: 'YouTube',
        name: 'YouTube', // alt attribute
      },
    ];
  }
}
export type SocialProfileEntry = {
  id: SocialProfileType;
  icon: string;
  label: string;
  name: string;
};

type SocialProfileApi = {
  id: string;
  brand: string;
  type: SocialProfileType;
  data: SocialProfileData;
  externalId: string;
  expires: string | null;
  addedBy: string;
  createdAt: string;
  updatedAt: string;
  addedByObject?: {
    name: string;
  }
}

export type TiktokCreatorInformation = {
  creator_avatar_url: string;
  creator_username: string;
  creator_nickname: string;
  privacy_level_options: string[];
  comment_disabled: boolean;
  duet_disabled: boolean;
  stitch_disabled: boolean;
  max_video_post_duration_sec: number;
  profileId?: string;
}