import { Component, Inject } from '@angular/core';
import { MediaLibraryComponent } from '../../../media-library/media-library.component';
import { MatTooltip } from '@angular/material/tooltip';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../../../../shared/models/misc';
import { AuthService } from '../../../../shared/services/auth.service';
import { ToastService } from '../../../../shared/services/toast.service';

@Component({
  selector: 'app-gallery-dialog',
  standalone: true,
  imports: [
    MediaLibraryComponent,
    MatTooltip,
  ],
  templateUrl: './gallery-dialog.component.html',
  styleUrl: './gallery-dialog.component.scss'
})
export class GalleryDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<GalleryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData.SelectMedia,
    protected auth: AuthService,
    protected toast: ToastService,
  ) { }
}
