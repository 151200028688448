import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Upload, UploadResult, UploadScope } from '../models/upload';
import { lastValueFrom } from 'rxjs';
import { TreeNode } from '../models/misc';

type UploadFileData = {
  scope: UploadScope;
  filename: string;
  mimetype: string;
  sizeBytes: number;
  widthPx: number;
  heightPx: number;
  path: string;
};

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  protected endpoint = 'uploads';

  constructor(protected http: HttpClient) { }

  public async uploadFile(brandId: string, file: File, data: UploadFileData): Promise<UploadResult> {
    // 1. Get Pre-Signed URL for upload
    const { data: uploadData } = await lastValueFrom(
      this.http.post<{ data: { url: string, fields: Record<string, string> } }>(
        this.endpoint,
        data,
        { params: { brandId } }
      )
    );
    const { url: uploadUrl, fields } = uploadData;

    // 2. Upload file
    const formData = new FormData();
    Object.entries(fields).forEach(([key, value]) => formData.set(key, value));
    formData.set('file', file);

    return lastValueFrom(
      this.http.post<{ url: string; key: string; upload: Upload }>(
        uploadUrl,
        formData
      )
    );
  }

  public async getMediaDirTree(brandId: string) {
    return lastValueFrom(
      this.http.get<{ media: TreeNode, dirs: string[] }>(`${this.endpoint}/media/tree`, {
        params: { brandId }
      })
    );
  }

  public async searchMedia(brandId: string, search: string, path: string = '/') {
    const { uploads } = await lastValueFrom(
      this.http.get<{ uploads: Upload[] }>(`${this.endpoint}/media/search`, {
        params: { brandId, search, ...(!!path?.length && { path }) }
      })
    );

    return uploads;
  }

  public async updateOne(brandId: string, uploadId: string, data: Partial<Pick<Upload, 'postSuggestedText' | 'notes'>>) {
    const { upload } = await lastValueFrom(
      this.http.put<{ upload: Upload }>(`${this.endpoint}/${uploadId}`, data, {
        params: { brandId }
      })
    );

    return upload;
  }

  public async createDirectory(brandId: string, path: string, dirName: string) {
    const { success } = await lastValueFrom(
      this.http.post<{ success: boolean }>(`${this.endpoint}/media/tree`, { path, dirName }, {
        params: { brandId }
      })
    );

    return success;
  }

  public async renameDirectory(brandId: string, path: string, dirName: string, newDirName: string) {
    const { success } = await lastValueFrom(
      this.http.put<{ success: boolean }>(`${this.endpoint}/media/tree`, { path, dirName, newDirName }, {
        params: { brandId }
      })
    );

    return success;
  }

  public async deleteDirectory(brandId: string, path: string, dirName: string) {
    const { success } = await lastValueFrom(
      this.http.delete<{ success: boolean }>(`${this.endpoint}/media/tree`, {
        body: { path, dirName },
        params: { brandId },
      })
    );

    return success;
  }

  public async moveFile(brandId: string, uploadId: string, destinationPath: string) {
    const { upload } = await lastValueFrom(
      this.http.post<{ upload: Upload }>(`${this.endpoint}/${uploadId}/move`, { destinationPath }, {
        params: { brandId }
      })
    );

    return upload;
  }

  public async deleteFile(brandId: string, uploadId: string) {
    const { upload } = await lastValueFrom(
      this.http.delete<{ upload: Upload }>(`${this.endpoint}/${uploadId}`, {
        params: { brandId }
      })
    );

    return upload;
  }

  public async uploadNewsImage(brandId: string, newsImageUrl: string) {
    const { uploadResult } = await lastValueFrom(
      this.http.post<{ uploadResult: UploadResult }>(`${this.endpoint}/news/uploadImage`, { newsImageUrl }, {
        params: { brandId }
      })
    );
    return uploadResult;
  }

  public async getUpload(uploadId: string) {
    const { upload } = await lastValueFrom(
      this.http.get<{ upload: Upload }>(`${this.endpoint}/${uploadId}`)
    );
    return upload;
  }
}
