<section class="folder-form-dialog" [appLoading]="isLoading">
  <div class="header">
    <i class="icon outlined back"
       matTooltip="Back"
       [class.visible]="mode != null"
       (click)="mode = null"
    >arrow_back</i>

    <i class="icon outlined"
       matTooltip="Close"
       (click)="dialogRef.close(null)"
    >close</i>
  </div>

  @if (!mode) {
    <div class="menu">
      <button class="btn" (click)="mode = 'add'">
        <i class="icon outlined">folder</i>
        <span>Add subfolder</span>
      </button>

      @if (!data.isRootDir) {
        <button class="btn" (click)="mode = 'rename'">
          <i class="icon outlined">edit</i>
          <span>Rename folder</span>
        </button>

        <button class="btn" (click)="onDelete()">
          <i class="icon outlined">delete</i>
          <span>Delete</span>
        </button>
      }

      <span class="cancel-btn" (click)="dialogRef.close(null)">
        <u>Cancel</u>
      </span>
    </div>
  }

  @if (mode === 'add') {
    <div class="form">
      <input type="text" placeholder="Name of sub-directory..." [(ngModel)]="name">

      <button class="btn" (click)="onAdd()">
        <i class="icon outlined">folder</i>
        <span>Add</span>
      </button>
    </div>
  }

  @if (mode === 'rename') {
    <div class="form">
      <input type="text" placeholder="New directory name" [(ngModel)]="name">

      <button class="btn" (click)="onRename()">
        <i class="icon outlined">edit</i>
        <span>Rename</span>
      </button>
    </div>
  }
</section>
