import { Component, effect } from '@angular/core';
import { injectTrpcClient } from '../../shared/services/trpc/client';
import { CommonModule } from '@angular/common';
import { ImgFallbackDirective } from '../../shared/directives/img-fallback.directive';
import { ToastService } from '../../shared/services/toast.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { PostGroupService } from '../../shared/services/post-group.service';
import { PostGroup, PostRepetition, PostStatus } from '../../shared/models/post-group';
import { AuthService } from '../../shared/services/auth.service';
import { FormsModule } from '@angular/forms';
import { PostService } from '../../shared/services/post.service';
import { GeneralUtils } from '../../shared/utils/general.utils';
import { UploadService } from '../../shared/services/upload.service';
import { TRPCClientError } from '@trpc/client';
import { SocialMediaSkeletonLoading } from "./components/social-media-skeleton-loading.component";
import { LoadingDirective } from '../../shared/directives/loading.directive';
import { COUNTRY_CODES, LANGUAGE_CODES } from '@squirrly-social/api-models';
import { MatTooltip } from '@angular/material/tooltip';
@Component({
  selector: 'app-social-media-assistant',
  standalone: true,
  templateUrl: './social-media-assistant.component.html',
  styleUrl: './social-media-assistant.component.scss',
  imports: [CommonModule, ImgFallbackDirective, NgSelectModule, FormsModule, SocialMediaSkeletonLoading, LoadingDirective, MatTooltip]
})
export class SocialMediaAssistantComponent {

  trpcClient = injectTrpcClient();

  constructor(
    private toast: ToastService,
    private postGroupService: PostGroupService,
    private auth: AuthService,
    private postService: PostService,
    private uploadService: UploadService
  ) {
    effect(async () => {
      if (this.auth.lastSelectedBrandId()) {
        this.groups = await this.postGroupService.findAll(this.auth.lastSelectedBrandId());
        const storedSelectedGroupId = this.auth.storage.getItem('selectedGroupId');
        if (!this.groups.find(group => group.id === storedSelectedGroupId)) {
          this.auth.storage.removeItem('selectedGroupId');
        }
        this.selectedGroupId = this.getSelectedGroupId();
      }
    });

  }

  newsResponse: NewsResponseDTO = {
    totalEstimatedMatchesItems: 0,
    items: [],
  };
  currentNumberOfNews: number = 0;
  thereAreMoreNews: boolean = true;
  lastPage: number | null = null;

  pageSize: number = 5;
  currentPage: number = 1;
  searchData: string = '';

  groups: PostGroup[] = [];
  selectedGroupId: string = '';
  loading: boolean = false;
  isAddingNewsToPostGroup: boolean = false;
  defaultNewsImage: string = '/assets/img/news-image-placeholder.png';
  defaultNewsFavicon: string = '/assets/img/news-favicon-placeholder.svg';

  sortNewsIntervalOptions = [{
    label: 'Last month',
    value: getOldMonthTimestampFromNow(1) // 1 month ago
  }, {
    label: 'Last 3 months',
    value: getOldMonthTimestampFromNow(3) // 3 months ago
  }];
  selectedNewsIntervalOption: { label: string, value: number } = this.sortNewsIntervalOptions[0];

  regionNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });
  sortNewsCountryOptions = Object.values(COUNTRY_CODES).map(countryCode => ({
    label: this.regionNamesInEnglish.of(countryCode),
    value: countryCode
  }));
  selectedNewsCountryOption: { label: string | undefined, value: COUNTRY_CODES } = this.sortNewsCountryOptions.find(option => option.value === COUNTRY_CODES.US)!;

  languageNamesInEnglish = new Intl.DisplayNames(['en'], {
    type: 'language',
    languageDisplay: 'standard'
  });
  sortNewsLanguageOptions = Object.values(LANGUAGE_CODES).map(languageCode => ({
    label: this.languageNamesInEnglish.of(languageCode),
    value: languageCode
  }));
  selectedNewsLanguageOptions: { label: string | undefined, value: LANGUAGE_CODES }[] = [this.sortNewsLanguageOptions.find(option => option.value === LANGUAGE_CODES.en)!];

  async handlePreviousPage() {

    if (this.currentPage === 1) {
      this.toast.warning('You are already on the first page');
      return;
    }

    const newCurrentPage = this.currentPage - 1;
    const skip = (newCurrentPage - 1) * this.pageSize;
    this.loading = true;
    const response = await this.getNews(this.searchData, this.pageSize, skip,
      this.selectedNewsIntervalOption.value, this.selectedNewsCountryOption.value, this.selectedNewsLanguageOptions.map(option => option.value));
    this.loading = false;
    if (response.error === null) {
      this.currentPage = newCurrentPage;
    }
  }

  async handleNextPage() {
    const newCurrentPage = this.currentPage + 1;
    const skip = (newCurrentPage - 1) * this.pageSize;
    this.loading = true;
    const response = await this.getNews(this.searchData, this.pageSize, skip,
      this.selectedNewsIntervalOption.value, this.selectedNewsCountryOption.value, this.selectedNewsLanguageOptions.map(option => option.value));
    this.loading = false;
    if (response.error === null) {
      this.currentPage = newCurrentPage;
      if (!this.thereAreMoreNews) {
        this.lastPage = this.currentPage;
      }
    }
  }

  getFavicon(source: string) {
    switch (source) {
      case 'theglobeandmail.com':
        return 'https://www.theglobeandmail.com/pf/resources/assets/meta/favicon-32x32.png?d=573';
      case 'nypost.com':
        return 'https://nypost.com/wp-content/themes/nypost-2016/static/images/favicons/favicon.ico';
      default:
        return `https://www.${source}/favicon.ico`;
    }

  }

  onPostGroupChange(selectedGroupId: string) {
    this.auth.storage.setItem('selectedGroupId', selectedGroupId);
  }

  getSelectedGroupId() {
    return this.auth.storage.getItem('selectedGroupId') || (this.groups.find(group => group.name === 'General')?.id ?? this.groups[0].id);
  }


  async getNews(search: string, limit: number, skip: number,
    since: number, newsCountry: COUNTRY_CODES, newsLanguages: LANGUAGE_CODES[], sortBy: NEWS_SORT_BY = 'date'): Promise<FetchNewsResponse> {
    try {
      const data = await this.trpcClient.socialMediaAssistent.list.query({
        search,
        limit,
        skip,
        sortBy,
        since,
        newsCountry,
        newsLanguages
      });
      this.newsResponse = data;
      this.currentNumberOfNews = data.items.length;
      this.thereAreMoreNews = this.currentNumberOfNews === this.pageSize;
      return {
        error: null,
        result: data
      }
    }
    catch (e: unknown) {
      const error = e as TRPCClientError<any>;
      this.toast.error(error.message);
      return {
        error,
        result: null
      }
    }
  }

  async onSearch(event: SubmitEvent) {
    event.preventDefault();
    const data = new FormData(event.target as HTMLFormElement);
    const search = data.get('search') as string;
    this.thereAreMoreNews = true;
    this.lastPage = null;
    if (search === '') {
      this.toast.warning('Please enter a search term');
      return;
    }
    if (this.selectedNewsLanguageOptions.length === 0) {
      this.toast.warning('Please select at least one language');
      return;
    }
    this.searchData = search;
    this.loading = true;
    const response = await this.getNews(this.searchData, this.pageSize, 0,
      this.selectedNewsIntervalOption.value, this.selectedNewsCountryOption.value, this.selectedNewsLanguageOptions.map(option => option.value));
    this.loading = false;
    if (response.error === null) {
      this.currentPage = 1;
      if (!this.thereAreMoreNews) {
        this.lastPage = this.currentPage;
      }
    }

  }

  async handleAddNewsToPostGroup(news: NewsItemDTO) {
    this.isAddingNewsToPostGroup = true;

    try {
      let uploadResult = null;
      if (news.image) {
        uploadResult = await this.uploadService.uploadNewsImage(this.auth.lastSelectedBrandId(), news.image);
      }
      const postContent = news.title + '\n' + news.url;
      const data = {
        type: 'postGroup' as 'postGroup',
        repetition: PostRepetition.once,
        status: PostStatus.draft,
        upload: uploadResult?.upload._id,
        textContent: GeneralUtils.encodeContent(postContent),
        postGroup: this.selectedGroupId,
        socialProfiles: null,
        scheduledAt: null,
        createdUserId: this.auth.user!._id
      }
      await this.postService.createOne(this.auth.lastSelectedBrandId(), data)
      this.toast.success('Added successfully to post group');
    }
    catch (e: unknown) {
      this.toast.error("Something went wrong... Please try again later!");
    }
    finally {
      this.isAddingNewsToPostGroup = false;
    }
  }
}

function getOldMonthTimestampFromNow(monthsAgo: number): number {
  const hoursInMonth = 30 * 24;
  const milisecondInHour = 60 * 60 * 1000;
  return Date.now() - monthsAgo * hoursInMonth * milisecondInHour;
}

export const NEWS_SORT_BY = {
  RELEVANCE: "relevance",
  PUBLICATION: "date",
} as const;
type EnumValues<T> = T[keyof T];
export type NEWS_SORT_BY = EnumValues<typeof NEWS_SORT_BY>;
export interface NewsResponseDTO {
  totalEstimatedMatchesItems: number;
  items: NewsItemDTO[];
}

export interface FetchNewsResponse {
  error: any;
  result: any;
}

export interface NewsItemDTO {
  title: string;
  source: string;
  url: string;
  image?: string;
  summary: string;
  publishedOn: string;
  sourceIconUrl?: string;
}