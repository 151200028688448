import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { PostGroup, PostRepetition, PostStatus, ScheduleTimeEntry, ScheduleWeekDay } from '../models/post-group';
import { injectTrpcClient } from './trpc/client';

@Injectable({
  providedIn: 'root'
})
export class PostGroupService {

  protected endpoint = 'post-groups';
  protected trpcClient = injectTrpcClient();

  constructor(protected http: HttpClient) { }

  public async findAll(brandId: string) {
    const { postGroups } = await lastValueFrom(
      this.http.get<{ postGroups: PostGroup[] }>(this.endpoint, {
        params: { brandId }
      })
    );

    return postGroups;
  }

  public async findOne(postGroupId: string): Promise<PostGroup> {
    const postGroup = await this.trpcClient.postGroup.byId.query({
      id: postGroupId
    });

    return {
      ...postGroup,
      scheduleDays: postGroup.scheduleDays as ScheduleWeekDay[],
      repetition: postGroup.repetition as PostRepetition,
      defaultStatus: postGroup.defaultStatus as PostStatus
    } 
  }

  public async addOne(brandId: string, payload: AddOnePayload) {
    const { postGroup } = await lastValueFrom(
      this.http.post<{ postGroup: PostGroup }>(this.endpoint, payload, {
        params: { brandId }
      })
    );

    return postGroup
  }

  public async updateOne(brandId: string, postGroupId: string, payload: UpdateOnePayload) {
    const { postGroup } = await lastValueFrom(
      this.http.put<{ postGroup: PostGroup }>(`${this.endpoint}/${postGroupId}`, payload, {
        params: { brandId }
      })
    );

    return postGroup
  }

  public async deleteOne(brandId: string, postGroupId: string) {
    const { postGroup } = await lastValueFrom(
      this.http.delete<{ postGroup: PostGroup }>(`${this.endpoint}/${postGroupId}`, {
        params: { brandId }
      })
    );

    return postGroup;
  }
}

type AddOnePayload = {
  name: string;
  repetition: PostRepetition;
  defaultStatus: PostStatus;
};

type UpdateOnePayload = {
  name: string;
  repetition: PostRepetition;
  defaultStatus: PostStatus;
  scheduleDays: ScheduleWeekDay[];

  scheduleTimes: ScheduleTimeEntry[];
}
