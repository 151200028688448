import type { Routes } from '@angular/router';
import { AccountSettingsApiKeysComponent } from './pages/account-settings/account-settings-api-keys/account-settings-api-keys.component';
import { AccountSettingsProfileComponent } from './pages/account-settings/account-settings-profile/account-settings-profile.component';
import { AccountSettingsComponent } from './pages/account-settings/account-settings.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { RegisterComponent } from './pages/auth/register/register.component';
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { HistoryComponent } from './pages/history/history.component';
import { HomeComponent } from './pages/home/home.component';
import { MediaLibraryComponent } from './pages/media-library/media-library.component';
import { PlannerComponent } from './pages/planner/planner.component';
import { SocialMediaAssistantComponent } from './pages/social-media-assistant/social-media-assistant.component';
import { SocialProfilesComponent } from './pages/social-profiles/social-profiles.component';
import { authGuard } from './shared/guards/auth.guard';
import { BrandsComponent } from './pages/brands/brands.component';
import { NextLevelMarketingSettingsComponent } from './pages/account-settings/next-level-marketing-settings/next-level-marketing-settings.component';
import { ChatGPTSettingsComponent } from './pages/account-settings/chatgpt-settings/chatgpt-settings.component';
import { BrandMembersComponent } from './pages/brands/brand-members/brand-members.component';
import { BrandInvitationsComponent } from './pages/brands/brand-invitations/brand-invitations.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'home',
    canActivate: [authGuard],
    component: HomeComponent
  },
  {
    path: 'media-library',
    canActivate: [authGuard],
    component: MediaLibraryComponent,
  },
  {
    path: 'calendar',
    canActivate: [authGuard],
    component: CalendarComponent,
  },
  {
    path: 'account-settings',
    canActivate: [authGuard],
    component: AccountSettingsComponent,
    children: [
      {
        path: 'profile',
        component: AccountSettingsProfileComponent,
      },
      {
        path: 'brands',
        component: BrandsComponent,
      },
      {
        path: 'brand-members',
        component: BrandMembersComponent,
      },
      {
        path: 'brand-invitations',
        component: BrandInvitationsComponent,
      },
      {
        path: 'api-keys',
        component: AccountSettingsApiKeysComponent,
      },
      {
        path: 'next-level-marketing-settings',
        component: NextLevelMarketingSettingsComponent,
      },
      {
        path: 'chatgpt-settings',
        component: ChatGPTSettingsComponent,
      },
      {
        path: '**',
        redirectTo: 'profile',
      }
    ],
  },
  {
    path: 'post-groups',
    canActivate: [authGuard],
    children: [
      {
        path: '',
        component: PlannerComponent,
      },
    ]
  },
  {
    path: 'history',
    canActivate: [authGuard],
    component: HistoryComponent,
  },
  {
    path: 'social-profiles',
    canActivate: [authGuard],
    component: SocialProfilesComponent
  },
  {
    path: 'social-media-assistant',
    canActivate: [authGuard],
    component: SocialMediaAssistantComponent
  }
];
