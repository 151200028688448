import { HttpInterceptorFn } from '@angular/common/http';
import { authToken, injectAuthService } from '../services/auth.service';
import { environment } from '../../../environments/environment';
import { DateUtils } from '../utils/date.utils';

export const urlInterceptor: HttpInterceptorFn = (req, next) => {

  // Add headers
  let headers = req.headers
    .append('SQS-User-Agent', `web-${environment.version}`)
    .append('SQS-Timezone', DateUtils.timezone)

  const needsToken = !req.url.includes('amazonaws');
  const token = authToken();

  if (token && needsToken) {
    headers = headers.append('Authorization', `Bearer ${token}`);
  }

  const url = _needsPrefix(req.url)
    ? `${environment.serverBaseUrl}/web/${req.url}`
    : req.url;

  return next(req.clone({ url, headers }));

  function _needsPrefix(url: string) {
    return !url.startsWith('/assets')
      && !url.startsWith('https://')
      && !url.startsWith('http://');
  }
};

