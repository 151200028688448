<section class="dialog-component">
  <!-- Title -->
  <h2>{{ title }}</h2>

  <!-- Content -->
  <div class="dialog-content">
    @if (isRawMessage) {
      <p [innerHTML]="message | safeHtml"></p>
    } @else {
      <p>{{ message }}</p>
    }
  </div>

  <!-- Actions -->
  <div class="dialog-actions">
    <button mat-flat-button (click)="dialogRef.close(false)">{{ noBtnText }}</button>
    <button mat-flat-button
            class="btn-primary {{ yesBtnColor }}"
            (click)="dialogRef.close(true)"
    >{{ yesBtnText }}</button>
  </div>
</section>
