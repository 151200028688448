import { EventEmitter, Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class CreatePostUiService {

  public eventCreated = new EventEmitter<void>();
  constructor() { }

  public createPostFromRoot() {
    console.log('Creating a post');
    this.eventCreated.emit();
  }
}