export interface Storage {
    setItem(key: string, value: string): void;
    getItem(key: string): string | null;
    removeItem(key: string): void;
    clear(): void;
}

export class LocalStorage implements Storage {
    setItem(key: string, value: string): void {
        localStorage.setItem(key, value);
    }
    getItem(key: string): string | null {
        return localStorage.getItem(key);
    }
    removeItem(key: string): void {
        localStorage.removeItem(key);
    }
    clear(): void {
        localStorage.clear();
    }
}

export class SessionStorage implements Storage {
    setItem(key: string, value: string): void {
        sessionStorage.setItem(key, value);
    }
    getItem(key: string): string | null {
        return sessionStorage.getItem(key);
    }
    removeItem(key: string): void {
        sessionStorage.removeItem(key);
    }
    clear(): void {
        sessionStorage.clear();
    }
}

