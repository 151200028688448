import { MatTooltip } from "@angular/material/tooltip";
import { LoadingDirective } from "../../../shared/directives/loading.directive";
import { Component, OnInit } from "@angular/core";
import { BrandInvitationDTO, BrandInvitationStatus } from "@squirrly-social/api-models";
import { ToastService } from "../../../shared/services/toast.service";
import { AuthService } from "../../../shared/services/auth.service";
import { CommonModule } from "@angular/common";
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from "@angular/material/icon";
import { BrandInvitationService } from "../../../shared/services/brand-invitation.service";
import { BrandService } from "../../../shared/services/brand.service";
@Component({
    selector: 'app-brand-invitations',
    standalone: true,
    imports: [LoadingDirective, MatTooltip, CommonModule, MatTableModule, MatIconModule],
    templateUrl: './brand-invitations.component.html',
    styleUrl: './brand-invitations.component.scss'
})
export class BrandInvitationsComponent implements OnInit {
    constructor(
        private brandInvitationService: BrandInvitationService,
        private brandService: BrandService,
        private toast: ToastService,
        private auth: AuthService
    ) { }
    async ngOnInit() {
        await this.loadData();
    }
    async loadData() {
        this.isLoading = true;
        try {
            this.userBrandInvitations = await this.brandInvitationService.getAllUserBrandInvitations(this.auth.userEmail());
        } catch (error) {
            console.error(error);
            this.toast.error(error);
        } finally {
            this.isLoading = false;
        }
    }
    isLoading: boolean = false;
    userBrandInvitations: BrandInvitationDTO[] = [];
    BrandInvitationStatus = BrandInvitationStatus;
    displayedColumns: string[] = ['brandName', 'status', 'createdAt', 'actions'];

    async handleRejectInvitation(brandId: string, invitationId: string) {
        this.isLoading = true;
        try {
            await this.brandInvitationService.rejectInvitation(brandId, invitationId);
            await this.loadData();
            this.toast.success('Invitation rejected successfully');
        } catch (error) {
            console.error(error);
            this.toast.error(error);
        } finally {
            this.isLoading = false;
        }
    }

    async handleAcceptInvitation(brandId: string, invitationId: string) {
        this.isLoading = true;
        try {
            await this.brandInvitationService.acceptInvitation(brandId, invitationId);
            await this.loadData();
            await this.brandService.loadBrandsOfCurrentUser();
            this.toast.success('Invitation accepted successfully');
        } catch (error) {
            console.error(error);
            this.toast.error(error);
        } finally {
            this.isLoading = false;
        }
    }

}